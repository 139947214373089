/* eslint-disable import/extensions */
import enUS from "./en-US";
import heIL from "./he-IL";
import jaJP from "./ja-JP";
import esES from "./es-ES";
import frFR from "./fr-FR";

interface ResourceKeysBaseType {
  AppErrorTitle: string;
  Back: string;
  BackToHome: string;
  CampusParkingQuestion: string;
  Cancel: string;
  ChangeAvatar: string;
  Chat: string;
  CheckedIn: string;
  CheckIn: string;
  CheckInCompleted: string;
  Processing: string;
  ChooseAvatar: string;
  CollectGuestBadge: string;
  Company: string;
  CompanyPlaceholder: string;
  Country: string;
  DataProtection: string;
  Directions: string;
  DisplayNameText: string;
  // DrivingLicense: string;
  ErrorSavingData: string;
  ExpandedInfoHeader: string;
  FieldInvalid: string;
  FieldRequired: string;
  FirstName: string;
  FirstNamePlaceholder: string;
  GuestFormHeader: string;
  GuestFormSubHeader: string;
  GuestImageAriaLabel: string;
  HasOnlyUpcomingVisitsTitle: string;
  HasUpcomingAndPastVisitsTitle: string;
  InfoHeader: string;
  KnowLess: string;
  KnowMore: string;
  LastName: string;
  LastNamePlaceholder: string;
  LicensePlate: string;
  LicensePlatePlaceholder: string;
  LobbyHost: string;
  LobbyQRErrorMessage: string;
  LobbyQRScanningMessage: string;
  LocatingLobbyQRcode: string;
  LocatingLobbyQRcodeMessage2: string;
  LocatingLobbyQRcodeMessage: string;
  Make: string;
  MakePlaceholder: string;
  meetingDetails: string;
  MiddleName: string;
  MiddleNamePlaceholder: string;
  MeetingPurposeBusiness: string;
  MeetingPurposeEvent: string;
  MeetingPurposeInterview: string;
  MeetingPurposePersonal: string;
  MeetingPurposeUserResearch: string;
  // MilitaryId: string;
  Model: string;
  ModelPlaceholder: string;
  Next: string;
  No: string;
  NoPastVisitsErrorMessage: string;
  NoUpcomingVisitsTitle: string;
  NoUpcomingVisitsWelcomeMessage: string;
  NoVehicleRequiredInfo: string;
  NoVisitsTitle: string;
  NoVisitsWelcomeMessage: string;
  Okay: string; //
  ParkingInformation: string;
  // ParmanentResidentCard: string;
  // Passport: string;
  Phone: string;
  PhonePlaceholder: string;
  Prefix: string;
  PreRegisterButtonText: string;
  PreRegisterQRHeader: string;
  PreRegisterQRSubject: string;
  PreviousVisits: string;
  PrivacyLink: string;
  PrivacyPolicy: string;
  PrivacyStatement: string;
  ProfileUpdateMessage: string;
  ProfileUpdateSubMessage: string;
  QRCodeInfo: string;
  RedirectMessage: string;
  Save: string;
  Searching: string;
  Seconds: string;
  ShowPhotoID: string;
  ContactLobbyHostToCollectGuestBadge: string;
  StateLabel: string;
  TypeLabel: string;
  StatePlaceholder: string;
  TypePlaceholder: string;
  Submit: string;
  UpcomingVisits: string;
  UpdateDisplayNameText: string;
  UpdateFailureMessage: string;
  UpdateRegisterButtonText: string;
  ValidateInfoSubText: string;
  ValidateYourInfo: string;
  VehicleInfoHeader: string;
  VehicleInfoMessage: string;
  VehicleInfoTitle: string;
  WelcomeMessage: string;
  Yes: string;
  WorldLogoImgPathAltText: string;
  InfoImgPathAltText: string;
  WarningImgPathAltText: string;
  Close: string;
  ShowAvatar: string;
  MSLogo: string;
  EditPen: string;
  LangSelector: string;
  NoVehicleSaved: string;
  Dismiss: string;
  BadgeImage: string;
  individualsTab: string;
  landmarksTab: string;
  closeAvatarModalAriaLabel: string;
  selectAvatar: string;
  logOutText: string;
}

export const languageDictionaryList: { [key: string]: ResourceKeysBaseType } = {
  "en-US": enUS,
  "ja-JP": jaJP,
  "es-ES": esES,
  "fr-FR": frFR,
  "he-IL": heIL,
};

export const languageOptions = [
  { id: "en-US", text: "English", languageType: "LTR" },
  { id: "ja-JP", text: "Japanese", languageType: "LTR" },
  { id: "es-ES", text: "Spanish", languageType: "LTR" },
  { id: "he-IL", text: "Hebrew", languageType: "RTL" },
  { id: "fr-FR", text: "French", languageType: "LTR" },
];
