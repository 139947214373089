/* eslint-disable import/extensions */
import styled from 'styled-components';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { ICSSDisplayRule, ICSSRule } from '@uifabric/merge-styles/lib/IRawStyleBase';
import { Stack, Icon } from 'office-ui-fabric-react';
import theme from '../../asset/themes/defaultTheme';

const LanguageContainer = styled(Stack)`
`;

const WorldLogoImage = styled.img`
  padding-top: 10px;
  width: 12px;
  height: 12px;
`;

const LanguageDropdown = styled(Dropdown)`
`;

const LanguageDropdownContainer = styled.div`
  display: inline-block;
  padding-bottom: 5px;
`;

const CaretDownIcon = styled(Icon)<{isRTL: boolean}>`
  color: ${theme.brandBlack} !important;
  margin-right: ${(props) => (props.isRTL ? '1rem' : '0rem')};
  margin-left: ${(props) => (props.isRTL ? '0rem' : '0px')};
  font-size: 10px;
`;

const LanguageDropdownStyles = (isLangRTL) => ({
  root: {
    alignItems: 'center' as ICSSRule,
  },
  container: {
    minWidth: '160px'
  },
  title: {
    textDecoration: 'underline',
    display: 'flex' as ICSSDisplayRule,
    border: 'none',
    paddingRight: isLangRTL ? '0.5rem' : '2rem',
    top: '0 !important',
    left: '0 !important',
    height: '30px',
    minWidth: '4rem',
    textAlign: isLangRTL ? 'right' : 'left',
    fontSize: theme.fontSizeLSmall,
    color: `${theme.brandBannerGray} !important`,
    direction: isLangRTL ? 'rtl' : 'ltr',
  },
  dropdownItem: {
    border: 'none',
    height: '40px',
    display: 'block' as ICSSDisplayRule,
    color: `${theme.brandBannerGray} !important`,
    fontSize: theme.fontSizeLSmall
  },
  dropdownItemsWrapper: {
    maxHeight: 300,
    minWidth: 155,
    background: theme.brandWhite
  },
  flexContainer: {
    marginLeft: isLangRTL ? 'auto' : 'left',
  },
  callout: {
    minWidth: 155,
    maxWidth: 200
  },
  dropdownOptionText: {
    marginLeft: isLangRTL ? 'auto' : 'left',
    fontSize: theme.fontSizeLSmall
  },
  dropdownItemSelected: {
    marginLeft: isLangRTL ? 'auto' : 'left',
    display: 'block' as ICSSDisplayRule
  },
  caretDownWrapper: {
    display: 'flex' as ICSSDisplayRule,
    alignItems: 'center' as ICSSRule,
    fontSize: theme.fontSizeXSmall,
    float: isLangRTL ? 'left' : 'right',
  },
  caretDown: {
    color: `${theme.brandBlack} !important`,
    fontWeight: theme.fontWeightBold as unknown as ICSSRule,
    fontSize: '7px',
    float: 'left',
  },
  label: {
    fontSize: theme.fontSizeXSmall,
    padding: 0
  },
});

const DropdownPlaceholderStyle = styled.span<{isRTL: boolean}>`
  float: ${(props) => (props.isRTL ? 'right' : 'left')};
`;


export default {
  LanguageContainer,
  WorldLogoImage,
  LanguageDropdown,
  LanguageDropdownStyles,
  LanguageDropdownContainer,
  DropdownPlaceholderStyle,
  CaretDownIcon
};
