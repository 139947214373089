/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions */
import React, { useState, useContext } from 'react';
import {
  FontIcon,
  Modal,
} from 'office-ui-fabric-react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import styled from './VisitsCard.styled';
import { Text, LanguageContext } from '../../providers/LanguageProvider';
import { GuestPortalConstants } from '../../config/constants';
import msalAuthenticator from '../../auth/msalAuthenticator';
import { VisitInfo } from '../../store/guest/guestType';
import { Building } from '../../store/building/buildingType';
import { getRoute } from '../../routes/routerConfig';
import Dialog from '../Dialog/Dialog';
import GuestDetail from '../../components/GuestDetail/GuestDetail';
import warningImgPath from '../../asset/images/warning.svg';
import { Stack } from 'office-ui-fabric-react';

interface Props {
  visit: VisitInfo;
  building: Building | undefined;
  isPast: boolean;
  numberOfVisits: number;
}

enum Platform {
  WindowsPhone,
  Android,
  iOS,
  Web,
}

// eslint-disable-next-line complexity
const VisitsCard: React.FC<Props> = ({
  visit, building, isPast, numberOfVisits
}) => {
  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isModalOpen, setIsModelOpen] = useState(false);

  const guestEmailId = msalAuthenticator.getUserEmail();
  const languageContext = useContext(LanguageContext);
  const isLangRTL = languageContext.language.languageType === GuestPortalConstants.RtlLanguageType;

  const parkingIcon: JSX.Element = (
    <FontIcon iconName="ParkingLocationMirrored" />
  );

  const parseDateTime = (start: string, end: string, zone: string): string => {
    moment.locale(languageContext.language.id);
    const startTime = moment.parseZone(start).format('LLL');
    const endTime = moment.parseZone(end).format('LT');
    return `${startTime} - ${endTime} ${moment(start).tz(zone).format('z')}`;
  };

  const OpenVehEditModal = () => {
    setIsModelOpen(true);
  };

  const DismissGuestEditModal = () => {
    setIsModelOpen(false);
  };

  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/windows phone/i.test(userAgent)) {
      return Platform.WindowsPhone;
    }

    if (/android/i.test(userAgent)) {
      return Platform.Android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return Platform.iOS;
    }

    return Platform.Web;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const openMap = (lat: string | undefined, long: string | undefined) => {
    // eslint-disable-next-line default-case
    switch (getPlatform()) {
      case Platform.iOS:
        window.open(`maps:?q=${lat},${long}`);
        break;
      case Platform.Android:
        window.open(`geo:?q=${lat},${long}`);
        break;
      case Platform.WindowsPhone:
      case Platform.Web:
        window.open(`https://www.bing.com/maps?rtp=~pos.${lat}_${long}`);
    }
  };

  const checkin = (): void => {
    history.push(getRoute('checkin', visit.visitId));
  };

  return (
    <styled.CardStack isRTL={isLangRTL}>

      {building?.gmsConfigs.validateID && !isPast && (
        <styled.InfoContainer>
          <Stack horizontalAlign={isLangRTL ? 'end' : 'start'}>
            <Stack
              reversed={!!isLangRTL}
              horizontal
              horizontalAlign={isLangRTL ? 'end' : 'start'}
            >
              <Stack.Item align="center">
                <img
                  src={warningImgPath}
                  alt={languageContext.dictionary.WarningImgPathAltText}
                />
              </Stack.Item>
              <styled.InfoHeader isRTL={isLangRTL}>
                <Text tid="InfoHeader" />
              </styled.InfoHeader>
            </Stack>
          </Stack>
        </styled.InfoContainer>
      )}

      <styled.CardBody isRTL={isLangRTL}>
        <styled.MeetingDetailsContainer isPast={isPast} grow={1}>
          <styled.VisitPurposeLabel visitPurpose={visit.visitPurpose}>
            {visit.visitPurpose}
          </styled.VisitPurposeLabel>

          <styled.PurposeContainer>
        
            {    
                visit.visitSubject &&  visit.visitSubject.split('/-')[1]?( 
                visit.visitSubject.split('/-')[1].length>60?
                `${visit.visitSubject.split('/-')[1].slice(0, 60)}..` :
                visit.visitSubject.split('/-')[1]) : visit.visitSubject}
          </styled.PurposeContainer>

          <styled.DateContainer isPast={isPast}>
            <span className="meeting-datetime">
              {parseDateTime(
             visit.scheduledStartTimeLocal,
                visit.scheduledEndTimeLocal,
                building
                  ? building.localization.gmtZoneShort
                  : GuestPortalConstants.baseTimeZone
              )}
            </span>
          </styled.DateContainer>

          <styled.LocationContainer
            className="location-icon"
            onClick={() =>
              openMap(building?.buildingLatitude, building?.buildingLongitude)}
            tabIndex={0}
          >
            <FontIcon iconName="POI" style={styled.LocationIconStyle} />
            {building?.buildingName.toLowerCase()}
          </styled.LocationContainer>

  
       <styled.HostContainer horizontalAlign="center" isRTL={isLangRTL}>
            <styled.AvatarCircle num={(visit.hostName + visit.hostId).length}>
              <styled.Initials>{visit.hostName ? visit.hostName.split(' ')[0]?.charAt(0) + visit.hostName.split(' ')[1]?.charAt(0) : 'NA'}</styled.Initials>
            </styled.AvatarCircle>
            <styled.HostDetails>
              <styled.HostName>
                {visit.hostName}
              </styled.HostName>
              <styled.HostEmail
                tabIndex={0}
                onClick={() => window.open(`mailto:${visit.hostId}`)}
              >
                {visit.hostId}
              </styled.HostEmail>
            </styled.HostDetails>
          </styled.HostContainer>
   
          {!isPast && (
            <styled.IconsContainer horizontal>
              <styled.ParkingDirectionContainer>
                <styled.IconContainer
                  className="building-name"
                  onClick={() =>
                    openMap(
                      building?.buildingLatitude,
                      building?.buildingLongitude
                    )}
                  tabIndex={0}
                  onKeyPress={() =>
                    openMap(
                      building?.buildingLatitude,
                      building?.buildingLongitude
                    )}
                >
                <styled.StyledIconDirections iconName="MapDirections" role="link" aria-label={languageContext.dictionary.Directions} />
                </styled.IconContainer>
                <styled.IconTextContainer>
                  <Text tid="Directions" />
                </styled.IconTextContainer>
              </styled.ParkingDirectionContainer>
              {building?.gmsConfigs.parkingAvailable && (
                <styled.ParkingDirectionContainer>
                  <styled.IconContainer
                    onClick={() => setIsDialogOpen(true)}
                    tabIndex={0}
                    onKeyPress={() => setIsDialogOpen(true)}
                  >
                    <styled.StyledIconParking iconName="ParkingLocationMirrored" role="link" aria-label={languageContext.dictionary.ParkingInformation}/>
                  </styled.IconContainer>
                  <styled.IconTextContainer>
                    {Text({ tid: 'ParkingInformation' })}
                  </styled.IconTextContainer>

                </styled.ParkingDirectionContainer>
              )}
            </styled.IconsContainer>
          )}

          <Dialog
            heading={Text({ tid: 'ParkingInformation' })}
            icon={parkingIcon}
            isOpen={isDialogOpen}
            setIsOpen={setIsDialogOpen}
          >
            <>
              {building?.gmsConfigs.parkingInfo}
            </>
          </Dialog>
        </styled.MeetingDetailsContainer>
        {!isPast && (
          <styled.QRCodeContainer>
            <styled.HeadingContainer>
              <Text tid="DrivingMeeting" />
            </styled.HeadingContainer>
            <styled.VehicleRegContainer horizontal>
              <styled.CarIconContainer isRTL={isLangRTL}>
                <styled.StyledIconParking iconName="Car" />
              </styled.CarIconContainer>

              {!building?.gmsConfigs.parkingAvailable ? (
                <styled.NoVehDefault>
                  <Text tid="GuestVehicleReg" />
                </styled.NoVehDefault>
              ) : (
                <>
                  {visit.vehicleInfo && visit.vehicleInfo?.registrationId && (
                    <>
                      <styled.VehInfo isAddVeh={false}>
                        <styled.BoldText>
                          {`${(visit.vehicleInfo?.make?.length > 7) ? `${visit.vehicleInfo?.make.slice(0, 7 - visit.vehicleInfo?.make.length)}..` : (visit.vehicleInfo?.make)}
                       ${(visit.vehicleInfo?.model?.length > 7) ? `${visit.vehicleInfo?.model.slice(0, 7 - visit.vehicleInfo?.model.length)}..` : (visit.vehicleInfo?.model)}`}
                        </styled.BoldText>
                        <br />
                        {(visit.vehicleInfo?.registrationId?.length > 16) ? `${visit.vehicleInfo?.registrationId.slice(0, 16 - visit.vehicleInfo?.registrationId.length)}..` : (visit.vehicleInfo?.registrationId)}
                      </styled.VehInfo>
                      {visit.isEditable && (
                        <styled.UpdateVeh
                          isRTL={isLangRTL}
                          onClick={() => OpenVehEditModal()}
                        >
                          <Text tid="UpdateVehicle" />
                        </styled.UpdateVeh>
                      )}

                    </>
                  )}
                  {!visit.vehicleInfo?.registrationId && (
                    <>
                      <styled.VehInfo isAddVeh>
                        <Text tid="NoVehicle" />
                      </styled.VehInfo>
                      <styled.UpdateVeh
                        isRTL={isLangRTL}
                        onClick={() => OpenVehEditModal()}
                      >
                        {!visit.checkinSource && (
                          <styled.VehInfo isAddVeh>
                            <Text tid="AddVehicle" />
                          </styled.VehInfo>
                        )}
                        {/* <Text tid="" /> */}
                      </styled.UpdateVeh>
                    </>
                  )}
                </>
              )}
            </styled.VehicleRegContainer>
            <styled.HeadingContainer>
              <Text tid="CheckingInMeeting" />
            </styled.HeadingContainer>

            <styled.DivisonContainer horizontal>
              {/* 1st con */}
              <styled.DivisionItemContainer>
                <styled.CheckInTextContainer>
                  <Text tid="WebCheckin" />
                </styled.CheckInTextContainer>
                {visit.checkinSource && (
                  <>
                    <styled.CheckInCompleted>
                      <styled.CheckIcon isRTL={isLangRTL} iconName="SkypeCircleCheck" />
                      <Text tid="CheckInCompleted" />
                    </styled.CheckInCompleted>
                  </>
                )}
                <styled.PrimaryButtonStyle
                  isdisabled={!(!visit.checkinSource && visit.isEditable && visit.isVisitToday)}
                  className="check-in"
                  onClick={checkin}
                  disabled={!(!visit.checkinSource && visit.isEditable && visit.isVisitToday)}
                >
                  <Text tid="CheckIn" />
                </styled.PrimaryButtonStyle>

              </styled.DivisionItemContainer>

              {/* OR container */}
              <styled.CenterItemContainer>
                <styled.OrTextContainer>
                  <styled.OrLabel isRTL><styled.StyledIconSwitch iconName="Switch" /></styled.OrLabel>
                  {/* <Text tid="OrText" /> */}
                </styled.OrTextContainer>
              </styled.CenterItemContainer>

              {/* 2nd con */}
              <styled.DivisionItemContainer>
                <styled.QRTextContainer>
                  <Text tid="QRCodeCheckin" />
                </styled.QRTextContainer>
                <styled.ResponsiveQRCode
                  value={visit.qrCode}
                  size={100}
                  isRTL={isLangRTL}
                />
              </styled.DivisionItemContainer>
            </styled.DivisonContainer>
            <Modal
              isOpen={isModalOpen}
              onDismiss={() => DismissGuestEditModal()}
              isBlocking={false}
              // styles = {styled.ModelContainer}
              containerClassName={styled.ModelContainer.container}
            >
              <GuestDetail
                isVehicleStateVisible={building?.gmsConfigs.vehicleStateVisible}
                setIsModalOpen={setIsModelOpen}
                isPage={false}
                vehForm
                onSave={null}
                visitId={visit.visitId}
                vehInfo={visit.vehicleInfo}
                buildingName={building?.buildingName}
                visitTiming={parseDateTime(
                  visit.scheduledStartTimeLocal,
                  visit.scheduledEndTimeLocal,
                  building
                    ? building.localization.gmtZoneShort
                    : GuestPortalConstants.baseTimeZone
                )}
                numberOfFutureVisits={numberOfVisits}
              />
            </Modal>
          </styled.QRCodeContainer>
        )}
      </styled.CardBody>

    </styled.CardStack>
  );
};

export default VisitsCard;
