/* eslint-disable import/extensions */
import {
  AppInsights, BusinessProcessEvent, ComponentType, SystemEvent, ItEventType
} from '@microsoftit/telemetry-extensions-npm';
import {
  ApplicationInsights, ITelemetryItem, IPageViewTelemetry, IDependencyTelemetry
} from '@microsoft/applicationinsights-web';
import { v1 as guid } from 'uuid';
import { Logger, CustomProperties } from './logger';
import { ErrorCodes, LogSeverityLevel, CommonLoggingConstants } from '../../config/loggingCodes';
import Config from '../../env/index';
import { BuildCommonLoggingProperties } from './logPropertiesBuilder';


class AILogger implements Logger {
  private loggerClient: ApplicationInsights;

  private msitLoggerClientAdapter: AppInsights;

  private correlationId: any;

  constructor() {
    this.msitLoggerClientAdapter = new AppInsights();
    this.msitLoggerClientAdapter.environmentData = {
      EnvironmetName: Config.TELEMETRY_CONFIG.environmentName,
      ComponentId: Config.TELEMETRY_CONFIG.componentId,
      ComponentName: Config.TELEMETRY_CONFIG.componentName,
      IctoId: Config.TELEMETRY_CONFIG.ictoId,
      Service: Config.TELEMETRY_CONFIG.service,
      ServiceOffering: Config.TELEMETRY_CONFIG.serviceOffering,
      ServiceLine: Config.TELEMETRY_CONFIG.serviceLine
    };
    this.correlationId = guid();
    this.loggerClient = new ApplicationInsights({
      config: {
        instrumentationKey: Config.TELEMETRY_CONFIG.instrumentationKey,
        autoTrackPageVisitTime: true,
        sessionRenewalMs: Config.TELEMETRY_CONFIG.sessionExpirationTimeInMS,
        sessionExpirationMs: Config.TELEMETRY_CONFIG.sessionExpirationTimeInMS,
        enableAutoRouteTracking: true
      }
    });
    this.loggerClient.loadAppInsights();
    this.loggerClient.addTelemetryInitializer((item: ITelemetryItem) => {
      const commonProperties = BuildCommonLoggingProperties();
      commonProperties[CommonLoggingConstants.CorrelationId] = this
        .getCurrentCorrelationIdAsString();
      item.data = {
        ...item.data,
        ...commonProperties
      };
    });
  }

  getCurrentCorrelationId(): any {
    return this.correlationId;
  }

  getCurrentCorrelationIdAsString(): string {
    return this.correlationId.toString();
  }

  RenewCorrelationId(): void {
    this.correlationId = guid();
  }

  logPageView(pageName: string, componentName: string, pageAbsoulteUri?: string): void{
    const pageViewData: IPageViewTelemetry = {
      name: pageName,
      uri: pageAbsoulteUri,
      refUri: pageAbsoulteUri,
      pageType: componentName
    };
    this.loggerClient.trackPageView(pageViewData);
  }

  startTrackPage(pageName: string): void {
    this.loggerClient.startTrackPage(pageName);
  }

  stopTrackPage(
    pageName?: string | undefined, url?: string | undefined, customProperties?: CustomProperties
  ): void {
    this.loggerClient.stopTrackPage(pageName, url, customProperties);
  }


  logInformation(message: string, source: string, severityLevel:
  LogSeverityLevel = LogSeverityLevel.Debug, properties?: CustomProperties| undefined): void {
    const errorProperties = (properties !== undefined) ? properties : [];
    errorProperties[CommonLoggingConstants.CodeSource] = source;
    this.msitLoggerClientAdapter.TrackTrace(this.loggerClient, message, severityLevel, properties);
  }

  logError(errorCode: ErrorCodes, errorMessage: string, source: string,
    stackTrace?: string, severityLevel: LogSeverityLevel = LogSeverityLevel.Error,
    properties?: CustomProperties|undefined): void {
    const error = new Error();
    error.message = errorMessage;
    error.name = errorCode;
    error.stack = stackTrace;
    const errorProperties = (properties !== undefined) ? properties : [];
    errorProperties[CommonLoggingConstants.CodeSource] = source;
    this.msitLoggerClientAdapter.TrackException(this.loggerClient, error,
      severityLevel, properties);
  }


  logBusinessProcessEvent(eventName: string, message: string, source: string,
    TargetEntityKey: string, TargetEntityAction?: string,
    StartDateTime?: Date,
    EndDateTime?: Date,
    properties?: CustomProperties| undefined): void {
    const event = new BusinessProcessEvent(eventName, ComponentType.Web);
    event.IsValidEvent = (isValid: boolean) => true;
    event.MessageId = message;
    event.EventName = eventName;
    event.TargetEntityKey = TargetEntityKey;
    event.AppAction = TargetEntityAction as string;
    event.SenderId = source;
    event.EventType = ItEventType.BusinessProcessEvent;
    if (StartDateTime) { event.StartDateTime = StartDateTime; }
    if (EndDateTime) { event.EndDateTime = EndDateTime; }
    this.msitLoggerClientAdapter.TrackBusinessProcessEvent(this.loggerClient, event, properties);
  }


  logSystemEvent(eventName: string, message: string, source: string,
    TargetEntityKey: string, TargetEntityAction?: string,
    StartDateTime?: Date,
    EndDateTime?: Date,
    properties?: CustomProperties| undefined): void {
    const event = new SystemEvent(eventName, ComponentType.Web, message);
    event.TargetEntityKey = TargetEntityKey;
    event.AppAction = TargetEntityAction as string;
    event.SenderId = source;
    if (StartDateTime) { event.StartDateTime = StartDateTime; }
    if (EndDateTime) { event.EndDateTime = EndDateTime; }
    event.IsValidEvent = (isValid: boolean) => true;
    this.msitLoggerClientAdapter.TrackSystemEvent(this.loggerClient, event, properties);
  }

  logEventToCaptureChanges(
    event: string, source: string, eventAttributeName: string,
    eventAttributeOldValue: string, eventAttributeChangedValue: string, properties?:
    CustomProperties| undefined
  ): void {
    const errorProperties = (properties !== undefined) ? properties : [];
    errorProperties[CommonLoggingConstants.CodeSource] = source;
    errorProperties[CommonLoggingConstants.AttributeName] = eventAttributeName;
    errorProperties[CommonLoggingConstants.AttributeOldValue] = eventAttributeOldValue;
    errorProperties[CommonLoggingConstants.AttributeNewValue] = eventAttributeChangedValue;
    this.msitLoggerClientAdapter.TrackEvent(this.loggerClient, event, properties);
  }

  logEvent(event: string, source: string, properties?: CustomProperties | undefined): void{
    const errorProperties = (properties !== undefined) ? properties : [];
    errorProperties[CommonLoggingConstants.CodeSource] = source;
    this.msitLoggerClientAdapter.TrackEvent(this.loggerClient, event, properties);
  }

  logDependency(dependencyName: string, url: string,
    responseCode: number, data?: string, source?: string,
    success?: boolean, duration?: number, properties?: CustomProperties|undefined): void {
    const dependencyProperties = (properties !== undefined) ? properties : [];
    dependencyProperties[CommonLoggingConstants.CodeSource] = source;
    const dependency: IDependencyTelemetry = {
      id: url,
      name: dependencyName,
      properties: dependencyProperties,
      responseCode
    };
    if (success) dependency.success = success;
    if (responseCode) { if (duration) dependency.duration = duration; }
    if (data && Config.TELEMETRY_CONFIG.logRequestBody) dependency.data = data;
    this.loggerClient.trackDependencyData(dependency);
  }

  overrideUserAuthContext(UserAuthenticatedId: () => string, UserAccountId: () => string): void {
    this.loggerClient.clearAuthenticatedUserContext();
    this.loggerClient.setAuthenticatedUserContext(UserAuthenticatedId(), UserAccountId());
  }
}


const logWrapper = new AILogger();
export default logWrapper;
