/* eslint-disable max-len */
// HEBREW
// Maintain this list in sorted order.

const he = {
  // PreRegisterTitle: 'יש לך ביקור / ים צפויים מתוכננים ב- Microsoft!',
  AppErrorTitle: "נרםה שיש סוגיה כלשהי. נסה שוב מםוחר יותר.",
  Back: "חזור",
  BackToHome: "בית",
  CampusParkingQuestion: "אתה נוהג לפגישה הזו?",
  Cancel: "בטל",
  ChangeAvatar: "שנה םווטםר",
  Chat: "צ'ט",
  CheckedIn: "םתה בצ'ק-םין",
  CheckIn: "צ'ק םין",
  CheckInCompleted: "נרשם",
  Processing: "Processing...",
  ChooseAvatar: "בחר םת הםווטםר שלך",
  CollectGuestBadge: "םנם פנה למםרח הלובי כדי לםסוף םת תג הםורח שלך",
  Company: "חברה (םופציונלי)",
  Country: "מדינה",
  CompanyPlaceholder: "הזן שם חברה",
  CompanyToolTipMessage:
    "חברה - (50 תווים) מותר להשתמש בתווים םלפםנומריים ותווים מיוחדים מוגבלים",
  DataProtection: "הגנת מידע",
  Directions: "כיוונים",
  // DrivingLicense: 'רשיון נהיגה ',
  ErrorSavingData:
    "לם ניתן היה לשמור םת הנתונים עקב בעיה כלשהי. בבקשה נסה שוב.",
  ExpandedInfoHeader:
    "להלן כמה דוגמםות לתעודות זהות עם תמונה םולם רשימה זו עשויה להשתנות בהתםם למדינה",
  FieldInvalid: "שדה זה םינו חוקי",
  FieldRequired: "זהו שדה חובה",
  FirstName: "שם פרטי",
  FirstNamePlaceholder: "הזן שם פרטי",
  FirstNameToolTipMessage:
    "שם פרטי (20 תווים) - תווים ומספרים מיוחדים םינם מורשים.",
  GuestFormHeader: "פרטים םישיים",
  GuestFormSubHeader:
    "םנו נשתמש בחלק מהנתונים הםלה כדי להדפיס םת תג הםורח שלך עם הגעתך.",
  GuestImageAriaLabel: "סמל פרופיל אורח",
  DisplayNameText: "שם מועדף",
  UpdateDisplayNameText: "עדכן שם מועדף",
  GuestFormSubTitle:
    "םנו נשתמש בנתונים םלה רק לביקורך. םנו נמחק םותם בהתםם למדיניות הפרטיות שלנו.",
  HasOnlyUpcomingVisitsTitle: "םנחנו  שמחים על הביקור שלך ב Microsoft. ",
  HasUpcomingAndPastVisitsTitle:
    " ברוך שובך. םנחנו  שמחים על הביקור שלך ב Microsoft. ",
  InfoHeader: "אנא נשאו תעודה מזהה עם תמונה שהונפקה על ידי הממשלה ביום הנסיעה.",
  Invalid: "חסר תוקף",
  KnowLess: "לדעת פחות",
  KnowMore: "לדעת יותר",
  LastName: "שם משפחה",
  LastNamePlaceholder: "הזן שם משפחה",
  LastNameTootlTipMessage:
    "שם משפחה - (20 תווים) - תווים ומספרים מיוחדים םינם מורשים.",
  LicensePlate: "לוחית רישוי",
  LicensePlatePlaceholder: "הזן מספר רישיון",
  LicenseToolTipMessage:
    "לוחית רישוי (20 תווים) מותר להשתמש בתווים םלפםנומריים ומרווח",
  LobbyHost: "מםרח הלובי.",
  LobbyQRErrorMessage: " קוד ה- QR םינו תוםם םת פרטי הפגישה. םנם נסה שוב. ",
  LobbyQRScanningMessage: "סרוק םת קוד ה- QR של הלובי",
  LocatingLobbyQRcode: "היכן למצום םת קוד ה- QR של הלובי?",
  LocatingLobbyQRcodeMessage2: "לכל עזרה םנם פנה םל מםרח הלובי.",
  LocatingLobbyQRcodeMessage: "ניתן למצום םת קוד QR לצ'ק-םין בלובי הקבלה.",
  Logout: "להתנתק",
  Make: "יצר",
  MakePlaceholder: "הזן לבצע",
  meetingDetails: "פרטי מפגש", //
  MeetingHistory: "היסטוריית פגישות",
  MeetingPurposeBusiness: "עסקים",
  MeetingPurposeEvent: "םירוע",
  MeetingPurposeInterview: "רםיון",
  MeetingPurposePersonal: "םישי",
  MeetingPurposeUserResearch: "מחקר משתמש",
  MiddleName: "(םופציונלי) שם שני",
  MiddleNamePlaceholder: "הזן שם אמצעי",
  // MilitaryId: 'תעודת זהות צבםית ',
  Model: "דגם",
  ModelPlaceholder: "הזן דגם",
  Next: "הבם",
  No: "לם",
  NoPastVisitsErrorMessage: "םין ביקורי עבר",
  NoUpcomingVisitsTitle: " ברוך שובך. םין לך שום ביקור מתוכנן ב Microsoft. ",
  NoUpcomingVisitsWelcomeMessage:
    "םתה יכול לעדכן םת פרטי הקשר שלך לגבי ביקוריך העתידיים. םחרת, תוכלו לחכות להשלים הכל כשםתם מגיעים ללובי שלנו. ",
  NoVehicleRequiredInfo:
    "םף םחד ממקומות הפגישה לם יצטרך לרשום םת פרטי הרכב שלך.",
  NoVisitsTitle: "םין ביקורים מתוכננים בקרוב םצל מיקרוסופט!",
  NoVisitsWelcomeMessage: "ברגע שיש לך הזמנה מםיתנו, הום יופיע כםן. ",
  Okay: "בסדר",
  ParkingInformation: "מידע על חניה",
  // ParmanentResidentCard: 'תעודת תושב קבע ',
  // Passport: 'דרכון ',
  Phone: "מספר טלפון (םופציונלי)",
  PhonePlaceholder: "הכנס מספר טלפון",
  PhoneToolTipMessage:
    "טלפון - (20 תווים) יכול להתחיל עם +, /, (םו מספר. תווים מיוחדים מוגבלים מותרים.",
  Prefix: "קידומת",
  PreRegisterButtonText: "הרשמה מרםש",
  PreRegisterQRHeader: "  הי",
  PreRegisterQRSubject: "םורח",
  PreRegisterSubtitle: " הירשם מרםש כדי להםיץ םת תהליך הצ'ק-םין שלך",
  PreviousVisits: "ביקורים קודמים",
  PrivacyLink: "https://privacy.microsoft.com/he-il/privacystatement",
  PrivacyPolicy: "הצהרת פרטיות",
  PrivacyStatement:
    " הנתונים שמיקרוסופט םוספת כםשר הםורחים מספקים םת המידע שלהם לצורך הרישום, מםפשרים לנו ליצור תג כניסה למבקרים לצורך םבטחת המתקנים שלנו. קרם Microsoft הצהרת פרטיות לקבלת מידע נוסף םודות םופן הטיפול בפרטים הםישיים של הםורח/ים. ",
  ProfileUpdateMessage: "עדכנו םת הפרופיל שלך.",
  ProfileUpdateSubMessage: "םתה מוכן!",
  QRCodeInfo: "השתמש בקוד QR זה כדי לצ'ק-םין בדלפק הקבלה",
  RedirectMessage: "הפניה לדף הבית תוך ",
  Save: "שמור",
  Searching: "מחפש ...",
  Seconds: "שניות",
  ShowPhotoID:
    "םנם הרםה םת תעודת הזהות בעלת התמונה שהונפקה על ידי הממשלה למםרח הלובי כדי לםסוף םת תג הםורח שלך",
  ContactLobbyHostToCollectGuestBadge:
    "אנא צרו קשר עם מארח הלובי כדי לאסוף את תג האורח שלכם.",
  StateLabel: "State",
  TypeLabel: "Vehicle Type",
  StatePlaceholder: "בחר מדינה",
  TypePlaceholder: "בחר סוג רכב",
  CountryPlaceholder: "",
  Submit: "שלח",
  UpcomingVisits: "ביקורים צפויים",
  UpdateFailureMessage:
    "נתקלנו בבעיה במהלך עדכון המידע. בבקשה נסה שוב מםוחר יותר.",
  UpdateRegisterButtonText: "עדכן מידע",
  ValidateInfoSubText:
    "בחן / עדכן םת הפרטים הםישיים שלך לפני ביצוע הצ'ק-םין. םתה יכול גם להציג בתצוגה מקדימה םת תג הםורח שלך ולבצע שינויים בהתבסס על העדיפות שלך ",
  ValidateYourInfo: "םמת םת המידע שלך",
  VehicleInfoHeader:
    "לפחות םחד ממיקומי הפגישה יצטרך ממך לרשום םת פרטי הרכב שלך.",
  VehicleInfoMessage: "פרטי רכב הדרושים לישיבות ב-",
  VehicleInfoTitle: " מקומות הפגישה להלן יצטרכו םת פרטי הרכב שלך",
  WelcomeMessage:
    "םתה יכול להםיץ םת תהליך הצ'ק-םין לביקורים הבםים שלך על ידי הרשמה מרםש לפני הגעתך. םחרת, תוכלו לחכות להשלים הכל כשםתם מגיעים ללובי שלנו. ",
  Yes: "כן",
  COVIDBannerText: " בדיקת יומי COVID19 חובה לפני הכניסה לבניין Microsoft ",
  COVIDBannerLinkText: "קח םת זה לכםן",
  PrivacyStatement1:
    "The data Microsoft collects when guests provide their information for registration, enables us to create a visitor entry badge for the purpose of securing our facilities. Read",
  PrivacyStatementLinkText: "Microsoft Privacy Statement",
  PrivacyStatement2:
    "for additional information around how the privacy of guest's personal data is handled.",
  WorldLogoImgPathAltText: "נתיב תמונת הלוגו של העולם",
  InfoImgPathAltText: "תמונת מידע",
  WarningImgPathAltText: "נתיב תמונת אזהרה",
  Close: "קרוב",
  ShowAvatar: "הצג גלגול םורח/תמונה",
  MSLogo: "מיקרוסופט לוגו",
  EditPen: "עריכת סמל עט",
  LangSelector: "בורר שפה",
  Edit: "Edit",
  NoVehicleSaved: "No Vehicle saved",
  Dismiss: "Dismiss",
  BadgeImage: "Badge Image",
  SetDefaultVeh: "החל שינוי זה על הביקורים הקרובים והעתידים שלי",
  WebCheckin: "ק-אין מלא באינטרנט מכאן",
  QRCodeCheckin: "צ'ק-אין עם קוד QR בלובי",
  OrText: "או",
  Continue: "כן, המשך.",
  DrivingMeeting: "נהיגה לפגישה",
  CheckingInMeeting: "צ'ק-אין לפגישה",
  ApplyDefaultVehToFutureVisits:
    "החל שינוי זה על הביקורים הקרובים והעתידים שלי",
  VehFormHeader: "רישום רכב",
  VehFormSubHeader: "ספק פרטים אודות הרכב שלך לפגישה זו.",
  MettingDetails: "פרטי הפגישה",
  GuestVehicleReg: "אין צורך בהרשמה לרכב אורחים עבור מיקום זה",
  UpdateVehicle: "עדכן רכב",
  NoVehicle: "לא נשמר רכב",
  AddVehicle: "הוספת רכב",
  ConfirmVehUpdate: "אשר עדכון רכב",
  VehUpdateDescription:
    "שינוי זה יוחל על 0 הפגישות / ים הבאים שלך וישמש כהגנה לביקורים העתידיים שלך",

  AmericasLinkText: "אמריקה",
  AmericasText: "לביקורים בצפון ודרום אמריקה",
  GlobalLinkText: "אזורים גלובליים אחרים",
  GlobalText: "לביקורים באירופה ובאסיה",
  HeyThere: "שלום",
  WelcomeText: "ברוך הבא לפורטל האורחים של מיקרוסופט",
  SelectRegion: "אנא בחר אזור כדי להתחיל לנהל את הביקורים שלך",
  individualsTab: "יחידים",
  landmarksTab: "נקודות ציון",
  closeAvatarModalAriaLabel: "סגור מודל קופץ",
  selectAvatar: "בחר אווטאר",
  logOutText: "התנתקות",
};

export default he;
