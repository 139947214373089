/* eslint-disable import/extensions */
import React, { useState, useContext } from 'react';
import { Stack } from 'office-ui-fabric-react';
import styled from './InfoBox.styled';
import { Text, LanguageContext } from '../../providers/LanguageProvider';
import infoImgPath from '../../asset/images/info.svg';
import { GuestPortalConstants } from '../../config/constants';

interface Props {
  infoHeader: string;
  infoTitle: string;
}

const InfoBox: React.FC<Props> = ({
  infoHeader, infoTitle
}) => {
  const [infoboxShow, setInfoboxShow] = useState(false);

  const languageContext = useContext(LanguageContext);
  const isLangRTL = (languageContext.language.languageType
        === GuestPortalConstants.RtlLanguageType);

  const handleInfoBoxOpen = (): void => {
    setInfoboxShow(true);
  };

  const handleInfoBoxClose = (): void => {
    setInfoboxShow(false);
  };

  const renderInfoExpandButton = (showInfo: boolean): JSX.Element => (
    <styled.InfoExpandButton
      isRTL={isLangRTL}
      onClick={showInfo ? handleInfoBoxClose : handleInfoBoxOpen}
      tabIndex={0}
      aria-label={languageContext.dictionary.KnowMore}
    >
      <styled.SizedIcon
        iconName={showInfo ? 'ChevronUp' : 'ChevronDown'}
        aria-hidden="true"
      />
    </styled.InfoExpandButton>
  );

  return (
    <Stack styles={styled.InfoContainerStack}>
      <styled.InfoStack>
        <styled.InfoContainer>
          <Stack>
            <Stack horizontalAlign={isLangRTL ? 'end' : 'start'}>
              <Stack
                reversed={!!isLangRTL}
                horizontal
                horizontalAlign={isLangRTL ? 'end' : 'start'}
              >
                <Stack.Item align="center">
                  <styled.PhotoIdImage
                    src={infoImgPath}
                    alt={languageContext.dictionary.InfoImgPathAltText}
                  />
                </Stack.Item>
                <styled.InfoHeader isRTL={isLangRTL} showInfobox={infoboxShow}>
                  <Text tid={infoHeader} />
                </styled.InfoHeader>
              </Stack>
            </Stack>
          </Stack>
          {renderInfoExpandButton(infoboxShow)}
        </styled.InfoContainer>
      </styled.InfoStack>
      {infoboxShow && (
        <styled.Infodiv isRTL={isLangRTL}>
          <styled.ExpandedInfoHeader>
            <Text tid={infoTitle} />
          </styled.ExpandedInfoHeader>
        </styled.Infodiv>
      )}
    </Stack>
  );
};

export default InfoBox;
