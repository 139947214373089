import { IStyle, Spinner } from "office-ui-fabric-react";
import styled from "styled-components";

const container = {
  root: {
    height: "100vh",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
  },
};

const spinnerStyle: { root: IStyle } = {
  root: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
};

const hostName = styled("div")`
  font-size: 20px;
  font-weight: bold;
  color: #2c3e50;
`;

const meetingTime = {
  fontSize: "16px",
  color: "black",
  marginTop: "5px",
};

// const meetingSubject= {
//   fontSize: "16px",
//   fontStyle: "italic",
//   color: "#34495E",
//   marginTop: "5px",
// };

export default {
  container,
  hostName,
  meetingTime,
  spinnerStyle,
  // meetingSubject,
};
