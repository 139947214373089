/* eslint-disable import/extensions */
import styled from 'styled-components';
import Header from './views/Header/Header';
import { device } from './config/constants';
import Footer from './views/Footer/Footer';
import theme from './asset/themes/defaultTheme';
import { Stack } from 'office-ui-fabric-react';

const Container = styled(Stack)`
  position: relative;
  min-height : 100vh;
  font-family: ${theme.baseFontFamily}
  max-width: 100%;
  overflow: hidden
`;

const StyledHeader = styled(Header)`
  margin-left: 5%;
  margin-right: 5%;
  @media ${device.laptop} {
    margin-left: 12%;
  }
  @media ${device.laptop} {
      margin-right: 12%;
    }

`;

const StyledFooter = styled(Footer)`
  position: relative;
  right: 5%;
  @media ${device.laptop} {
  position: relative;
  right: 12%;
  }
`;

const RouterOutletContainer = styled.div`
height:90vh;
overflow-y: auto;
`;

export default {
  Container,
  StyledHeader,
  StyledFooter,
  RouterOutletContainer
};
