import React, { useContext } from 'react';
import { Stack, StackItem, IIconProps, Button } from 'office-ui-fabric-react';
import styled from './ErrorBar.styled';
import { Text, LanguageContext, LanguageProvider } from '../../providers/LanguageProvider';
import { GuestPortalConstants } from '../../config/constants';

interface Props {
    onDismiss: Function;
    errorText: String;
}

const ErrorBar: React.FC<Props> = ({ onDismiss, errorText }) => {
    const languageContext = useContext(LanguageContext);
    const isLangRTL = (languageContext.language.languageType === GuestPortalConstants.RtlLanguageType);
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    return (
        <styled.ErrorBar>
                <styled.FailureMessageStack horizontal>
                  <StackItem>
                    { isLangRTL && (
                      <styled.DismissButtonStackItem isRTL={isLangRTL}>
                        <Button
                          iconProps={cancelIcon}
                          styles={styled.DismissIconStyle}
                          aria-label={languageContext.dictionary.Close}
                          onClick={() => onDismiss()}
                        />
                      </styled.DismissButtonStackItem>
                    )}
                  </StackItem>

                  <styled.FailureMessage isRTL={isLangRTL}>
                    <Stack styles={styled.FailureMessageandIcon(isLangRTL)} horizontal verticalAlign="center">
                      <styled.WarningStackItem>
                        {!isLangRTL && (
                          <styled.WarningIcon
                            iconName="warning"
                            aria-hidden="true"
                          />
                        )}
                      </styled.WarningStackItem>
                      <styled.warningText><Text tid={errorText} /></styled.warningText>
                      <styled.WarningStackItem>
                        {isLangRTL && (
                          <styled.WarningIcon
                            iconName="warning"
                            aria-hidden="true"
                          />
                        )}
                      </styled.WarningStackItem>
                    </Stack>
                  </styled.FailureMessage>

                  {!isLangRTL && (
                    <styled.DismissButtonStackItem isRTL={isLangRTL}>
                      <Button
                        iconProps={cancelIcon}
                        styles={styled.DismissIconStyle}
                        aria-label={languageContext.dictionary.Close}
                        onClick={() => onDismiss()}
                      />
                    </styled.DismissButtonStackItem>
                  )}
                </styled.FailureMessageStack>
              </styled.ErrorBar>
    )
}

export default ErrorBar;
