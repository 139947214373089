/* eslint-disable import/extensions */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App, { Chat, Root } from './App';
import * as serviceWorker from './serviceWorker';
import msalAuth from './auth/msalInitializer';
import { getCurrentRegion } from './routes/routerConfig';
import config from './env/index';
import logger from './common/logging/AILogger';
import { CommonLoggingConstants, EventCodes } from './config/loggingCodes';

const region = getCurrentRegion();
if (!region || config.REGION_CONFIG.validRegionList.indexOf(region) === -1) {
  // Call the root Page
  if (region === 'chat') {
    ReactDOM.render(<Chat />, document.getElementById('root'));
  } else {
    ReactDOM.render(<Root />, document.getElementById('root'));
  }
} else {
  msalAuth.run(() => {
    logger.logEvent(EventCodes.EV_106,
      `${CommonLoggingConstants.ComponentName}index`);
    ReactDOM.render(<App />, document.getElementById('root'));
    serviceWorker.unregister();
  });
}
