/* eslint-disable import/extensions */
import config from '../env/index';

// Endpoint generation
export default {
  GetGuestVisitDetailsConfiguration: (guestEmail: string) => `${config.API_BASE_URL}/guest/${guestEmail}`,
  getHostDetailsConfiguration: (hostEmail: string) => `${config.API_BASE_URL}/host/${hostEmail}`,
  GetVRUMasterDataConfiguration: (country: string) => `${config.API_BASE_URL}/vehicle-list?country=${country}`,
  ValidatingQRCodeConfiguration: (visitId: string) => `${config.API_BASE_URL}/visit/QRvalidation/${visitId}`,
  GetBuildingParkingConfiguration: () => `${config.API_BASE_URL}/config/`,
  UpdateGuestVisitDetailsConfiguration: () => `${config.API_BASE_URL}/guest`,
  VisitCheckinConfiguration: () => `${config.API_BASE_URL}/checkin`,
  GetVisitInfoVirtualHost: () => `${config.VH_API_BASE_URL}/virtualhost/visit`
};
