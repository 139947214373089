/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/extensions
import { GuestPortalConstants } from '../../config/constants';

export interface DeviceType {
  isMobile: boolean;
  isTablet: boolean;
}

// Hook for setting the device type based on window width
const useScreenType = (): DeviceType => {
  const [isMobile, setIsMobile] = useState(document.documentElement.clientWidth <= GuestPortalConstants.MobileMaxWidth);
  const [isTablet, setIsTablet] = useState(document.documentElement.clientWidth > GuestPortalConstants.MobileMaxWidth && window.innerWidth <= GuestPortalConstants.TabletMaxWidth);

  const deviceType: DeviceType = {
    isMobile,
    isTablet
  };
  const updateMedia = () => {
    setIsMobile(document.documentElement.clientWidth <= GuestPortalConstants.MobileMaxWidth);
    setIsTablet(document.documentElement.clientWidth > GuestPortalConstants.MobileMaxWidth && document.documentElement.clientWidth <= GuestPortalConstants.TabletMaxWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return deviceType;
};

export default useScreenType;
