/* eslint-disable import/extensions */
import { IDropdownOption } from "office-ui-fabric-react";
import {
  GuestVisitDetailsType,
} from "./guestType";
import { RootState } from "../rootReducer";

export const guestSelector = (state: RootState): GuestVisitDetailsType =>
  state.guest.guestVisitDetails;

export const hostSelector = (state: RootState) => state.guest.hostDetails;

export const checkinStatusSelector = (state: RootState) =>
  state.guest.checkinStatus;

export const guestUpdateStatusSelector = (state: RootState): string =>
  state.guest.guestUpdateStatus;

export const ValidateQRSuccessStatusSelector = (state: RootState): string =>
  state.guest.validateQRSuccessStatus;

export const CheckinVisitsSelector = (state: RootState) =>
  state.guest.validatedCheckinVisits;

export const guestDetailLoadedSelector = (state: RootState): boolean =>
  state.guest.isGuestLoaded;

export const vehicleDetailLoadedSelector = (state: RootState): boolean =>
  state.guest.isVehicleListLoaded;
