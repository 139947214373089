import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation } from "react-router-dom"; // Assuming you are using React Router
import logger from "../../common/logging/AILogger";
import { CustomProperties } from "../../common/logging/logger";
import {
  CommonLoggingConstants,
  ErrorCodes,
  EventCodes,
  LogSeverityLevel,
} from "../../config/loggingCodes";
import { options } from "./BotOptions";
import ErrorComponent from "./BotError";
import jwt_decode from "jwt-decode";
import { httpServiceWithToken } from "../../config/apiInterceptor";
import apiConfig from "../../config/apiConfig";
import moment from "moment";
import styled from "./VirtualHost.styled";
import { Spinner, Stack } from "office-ui-fabric-react";
import { en } from "./en";

// Declare the global variable WebChat to inform TypeScript
declare global {
  interface Window {
    WebChat: any;
  }
}

interface DecodedToken {
  exp: number;
  iat: number;
  [key: string]: any;
}

const decodeJWT = (token: string): DecodedToken | null => {
  try {
    return jwt_decode<DecodedToken>(token);
  } catch (error) {
    logger.logError(
      ErrorCodes.ER_120,
      `${CommonLoggingConstants.ComponentName}VirtualHost:API`,
      JSON.stringify((error as Error).stack)
    );
    return null;
  }
};

const BotChatComponent = () => {
  const customProperties: CustomProperties = [];
  const webChatRef = useRef();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [loading, setLoading] = useState<boolean>(true); // State to handle loading
  const [token, setToken] = useState(params.get("token") || ""); // Retrieve the 'token' query parameter
  const [visitInfo, setVisitInfo] = useState<any>(null); // State to hold the API response data
  const [error, setError] = useState<boolean>(false); // State to hold error messages

  const fetchVisitInfo = useCallback(
    (customProperties: CustomProperties) => {
      httpServiceWithToken(token)
        .get(apiConfig.GetVisitInfoVirtualHost())
        .then((response) => {
          setVisitInfo(response.data);
          setError(false); // Clear any previous error

          logger.logEvent(
            EventCodes.EV_108,
            `${CommonLoggingConstants.ComponentName}VirtualHost:VisitAPI`,
            customProperties
          );
        })
        .catch((error) => {
          logger.logError(
            ErrorCodes.ER_121,
            "",
            `${CommonLoggingConstants.ComponentName}VirtualHost:VisitAPI`,
            JSON.stringify((error as Error).stack),
            LogSeverityLevel.Error,
            customProperties
          );
          setError(true); // Set error flag to true
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [token]
  ); // Add token to the dependency array

  useEffect(() => {
    if (token) {
      let decodedToken = decodeJWT(token);
      customProperties["VisitId"] = decodedToken?.id;
      customProperties["Info"] = decodedToken?.username;
      fetchVisitInfo(customProperties);
      const webChatOptions = {
        directLine: window.WebChat.createDirectLine({
          token: token,
        }),
        userID: decodedToken?.id,
        username: decodedToken?.username,
        locale: "en-US",
        styleOptions: options,
      };
      webChatRef.current = window.WebChat.renderWebChat(
        webChatOptions,
        document.getElementById("webchat")
      );

      return () => {
        if (webChatRef.current) {
          // Dispose of Web Chat component
        }
      };
    }
  }, [token]);

  if (!token) {
    logger.logError(
      ErrorCodes.ER_122,
      `${CommonLoggingConstants.ComponentName}VirtualHost`,
      "Token not found in query parameters"
    );
    return <ErrorComponent responseData={en.tokenNotPresentText} />;
  }

  if (error) {
    return <ErrorComponent responseData={en.messageSendError} />;
  }

  return (
    <Stack styles={styled.container}>
      {loading && <Spinner styles={styled.spinnerStyle} />}
      <Stack.Item
        styles={{
          root: {
            padding: "15px",
            backgroundColor: "#ECF0F1",
            borderBottom: "1px solid #BDC3C7",
          },
        }}
      >
        <styled.hostName>
          Chatting with <span>{visitInfo?.hostName}</span>
        </styled.hostName>
        <div style={styled.meetingTime}>
          {moment(visitInfo?.scheduledStartTimeLocal).format("MM/DD") +
            " " +
            moment(visitInfo?.scheduledStartTimeLocal).format("LT")}
        </div>
        {/* Keep this commented code for now in case we need visit subject in next phases*/}
        {/* <div style={styled.meetingSubject}>
              {visitInfo?.visitSubject?.split("/-")[1]
                ? visitInfo?.visitSubject?.split("/-")[1]
                : `Meeting at ${visitInfo?.meetingLocation}`}
            </div> */}
        Meeting at {visitInfo?.meetingLocation}
      </Stack.Item>
      <Stack.Item>
        <div
          id="webchat"
          role="main"
          style={{
            width: "100%",
          }}
        ></div>
      </Stack.Item>
    </Stack>
  );
};

export default BotChatComponent;
