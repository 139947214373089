/* eslint-disable import/extensions */
import styled from 'styled-components';
import { Stack } from 'office-ui-fabric-react';
import theme from '../../asset/themes/defaultTheme';
import { device } from '../../config/constants';

const Container = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  height:5vh;
  background-color:${theme.brandLightGray};
  font-weight:${theme.fontWeightBold};
  font-size:${theme.fontSizeSmall};
  @media ${device.laptop} {
    font-size:${theme.fontSizeLSmall};
  }
`;

const PrivacyLink = styled.a`
  color: inherit;
`;

const PrivacyIcon = styled.img`
  height: 4.6rem;
  margin-top: 2.5rem;
`;

const StackStyle = {
  root:{
    height:'100%'
  }
};

export default {
  Container,
  PrivacyLink,
  PrivacyIcon,
  StackStyle
};
