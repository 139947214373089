/* eslint-disable import/extensions */
import styled from 'styled-components';
import {
  Stack, PrimaryButton, DefaultButton, StackItem, Pivot, IStackTokens
} from 'office-ui-fabric-react';
import QRCode from 'qrcode.react';
import theme from '../../../asset/themes/defaultTheme';
import { size } from '../../../config/constants';

const Container = styled(Stack)`
  padding-top: 2%;
  padding-bottom: 50px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  // margin-bottom: 30px;
  width: 40%;
  @media(min-width: ${size.laptopXL}) {
    width: 35%;
  };
  @media(max-width: ${size.laptop}) {
    width: 70%;
    padding-bottom: 10%;
  };
  @media(max-width: ${size.mobileXL}){
      width: 100%;
      padding-top: 0px;
      padding-bottom: 0px;
    }
`;

const Card = styled(Stack)`
  width=100%;
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.brandWhite};
  font-family: ${theme.baseFontFamily};
  height:100%
  overflow: hidden;
  @media(max-width: ${size.mobileXL}){
    padding-bottom: 30px;
  }
`;


const ResponsiveQRCode = styled(QRCode)`
    align-self: flex-end;
`;

const CardTextContainer = styled(Stack)`
  @media(max-width: ${size.mobileXL}){
    padding: 5% 5% 5% 5%;
  }
  padding: 7% 7% 7% 7%;
`;

const CardTitle = styled.div`
  font-weight: ${theme.fontWeightExtraBold};
  font-size: ${theme.fontSizeMXLarge};
`;

const CardSubTitle = styled(StackItem) <{ isRTL: boolean }>`
  color: ${theme.brandExtraDarkGray};
  text-align: ${(props) => (props.isRTL ? 'right' : 'left')};
`;

const SectionTitle = styled(Stack)`
  font-size: ${theme.fontSizeLarge};
  font-weight: ${theme.fontWeightLight};
  font-family: inherit;
  border-bottom: 2px solid ${theme.brandBackgroundGray};
  padding-top: 1rem;
  padding-bottom: 0.5rem;
`;

const BadgeSection = styled.div <{ isRTL: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isRTL ? 'row-reverse' : 'row')};
  justify-content: flex-start;
  padding-top: 5%;
  padding-bottom: 10%;
  @media(max-width: ${size.tabletS}) {
    justify-content: center;
  };
`;

const BlueButton = styled(PrimaryButton)`
  width: auto;
  font-size: ${theme.fontSizeXLSmall};
  font-weight: ${theme.fontWeightMediumBold};
`;

const WhiteButton = styled(DefaultButton)`
  width: auto;
  font-size: ${theme.fontSizeXLSmall};
  font-weight: ${theme.fontWeightMediumBold};
  color: ${theme.brandBlack};
  background-color: ${theme.brandWhite};
  border-color: ${theme.brandDarkGray};
`;

const CardFooter = styled(Stack)`
  border-top: 2px solid ${theme.brandBackgroundGray};
  @media(max-width: ${size.mobileXL}) {
    padding: 5%;
  };
  padding: 4%;
`;

const GuestBadgeContainer = styled(StackItem)<{isRTL: boolean}>`
  border: 2px dashed ${theme.brandDarkGray};
  width: 55%;
  padding: 1%;
  margin-right: ${(props) => (props.isRTL ? '0%' : 'auto')};
  margin-left: ${(props) => (props.isRTL ? 'auto' : '0%')};
  @media(max-width: ${size.tabletS}) {
    width: 80%;
  };
  @media(max-width: ${size.mobileM}) {
    width: 100%;
  };
`;

const GuestBadge = styled(Stack)`
  width: 100%;
  padding-top: 61.4%;
  position: relative;
  background-color: ${theme.brandWhite};
  box-shadow: ${theme.badgeBoxShadow};
`;

const GuestBadgeHeader = styled(Stack)`
  height: 15%;
  width: 92%;
  position: absolute;
  top: 2%;
  left: 4%;
  right: -4%;
`;

const GuestBadgeBody = styled(Stack)`
  position: absolute;
  top: 18%;
  left: 4%;
  right: -4%;
  height: 80%;
  width: 92%;
`;

const EditLink = styled.button`
  color: ${theme.BrandDarkBlue};
  font-size: ${theme.fontSizeXLSmall};
  line-height: ${theme.fontSizeMLarge};
  border-bottom: 1px solid ${theme.BrandDarkBlue};
  font-weight: ${theme.fontWeightNormal};
  border-left: none;
  border-top: none;
  border-right: none;
  background: white;
  cursor: pointer;

  img {
    padding-left: calc(${theme.fontSizeXLSmall} - ${theme.fontSizeXSmall});
  }
`;

const GuestBadgeTextContainer = styled(Stack)`
  height: 100%;
  width: 60%;
  padding-left: 5%;
  padding-top: 5%;
  white-space: nowrap;
`;

const GuestBadgeLogo = styled.img`
  height: 80%;
  width: auto;
`;

const GuestBadgeAvatarContainer = styled(Stack)`
  height: 78%;
  width: 24%;
`;

const GuestBadgeAvatar = styled.img`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 50%;
`;

const GuestBadgeName = styled(StackItem)`
  font-size: 1.3rem;
  font-weight: ${theme.fontWeightBold};
`;

const CustomGuestBadgeName = styled(StackItem) <{ firstLineText: string}>`
  font-size: ${(props) => (props.firstLineText.length < 16? '1.3rem' : (props.firstLineText.length < 18? '1.15rem': (props.firstLineText.length < 21? '1rem' : '0.85rem')))}
  font-weight: ${theme.fontWeightBold};
`;


const Guestlogo = styled.span`
  width: 32px;
  height: 16px;
  line-height: 16px;
  border-radius: 40%;
  font-size: 8px;
  color: #fff;
  text-align: center;
  background: #513487;
`;


const CompanyName = styled(StackItem)`
  font-size: 0.9rem;
  font-weight: ${theme.fontWeightNormal};
`;

const BadgeQRCode = styled(StackItem)`

`;

const GuestBadgeHighlighted = styled.span`
  font-size: 0.73rem;
  font-weight: ${theme.fontWeightMediumBold};
`;

const ErrorTextContainer = styled(StackItem)`
  text-align: center;
  padding-top: 10%;
  font-size: ${theme.fontSizeLarge};
  font-weight: ${theme.fontWeightBold};

  @media(max-width: ${size.mobileL}) {
    padding-top: 40%;
  };
`;

const Title = styled.span`          
  font-size: ${theme.fontSizeXXLarge};
`;

const Text = styled.span`
  font-weight: ${theme.fontWeightMediumBold};
  font-size: ${theme.fontSizeMedium};


  @media(max-width: ${size.mobileM}) {
    font-size: ${theme.fontSizeXLSmall};
  };
`;

const RTLResponsivePivot = styled(Pivot) <{ isRTL: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isRTL ? 'row-reverse' : 'row')};
  margin-bottom: 10px;
`;

const CardFooterTokens: IStackTokens = {
  childrenGap: '3%'
};

const BadgeTextContainerTokens: IStackTokens = {
};

const ContainerTokens: IStackTokens = {
  childrenGap: 50
};

const LoaderStyle = {
  root: {
  },
  circle: {
    marginTop: '40vh'
  }
};

export default {
  Container,
  Title,
  Text,
  ErrorTextContainer,
  CardTextContainer,
  LoaderStyle,
  RTLResponsivePivot,
  Card,
  CardFooter,
  CardTitle,
  CardSubTitle,
  BlueButton,
  WhiteButton,
  SectionTitle,
  EditLink,
  BadgeSection,
  GuestBadgeContainer,
  GuestBadge,
  GuestBadgeHeader,
  GuestBadgeLogo,
  GuestBadgeHighlighted,
  GuestBadgeAvatar,
  GuestBadgeBody,
  GuestBadgeAvatarContainer,
  GuestBadgeTextContainer,
  GuestBadgeName,
  CustomGuestBadgeName,
  CompanyName,
  BadgeQRCode,
  CardFooterTokens,
  BadgeTextContainerTokens,
  ContainerTokens,
  ResponsiveQRCode,
  Guestlogo
};
