/* eslint-disable import/extensions */
import styled from 'styled-components';
import {
  Stack, IStackItemStyles, FontIcon
} from 'office-ui-fabric-react';
import theme from '../../asset/themes/defaultTheme';
import { size } from '../../config/constants';

const BannerContainerStackItem = (isLangRTL): IStackItemStyles => ({
  root: {
    paddingLeft: isLangRTL ? '0' : '0.5rem',
    paddingRight: isLangRTL ? '0.5rem' : '0',
  },
});

const BannerContainer = styled(Stack)<{isRTL: boolean}>`
    padding: 0.5rem 12%;
    min-height: 3rem;
    direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};
    background-color: ${theme.brandBannerYellow};
    color: ${theme.brandBannerGray};
    font-size: ${theme.fontSizeMedium};
    font-weight: ${theme.fontWeightMediumBold}
    @media(max-width: ${size.mobileXL}) {
      padding: 0.5rem 0.5rem;
    };
  `;

const BannerCheckLink = styled.a`
    color: ${theme.brandBannerGray};
  `;

const BannerText = styled.span<{isRTL: boolean}>`
    font-size: ${theme.fontSizeXLSmall}!important;
    font-weight: ${theme.fontWeightNormal};
    color: ${theme.brandBannerGray}!important;
    direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};
  `;

const BannerIcon = styled(FontIcon)`
    color: ${theme.brandIconRed};
    font-size: larger;
  `;

export default {
  BannerContainer,
  BannerCheckLink,
  BannerText,
  BannerIcon,
  BannerContainerStackItem
};
