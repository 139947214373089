import {
  FETCH_BUILDING_CONFIG,
  FETCH_BUILDING_CONFIG_SUCCESS,
  FETCH_VISIT_BUILDING,
} from '../actionTypes';
import {
  BuildingAction,
  Building,
  fetchVisitBuildingAction
} from './buildingType';
import { VisitInfo } from '../guest/guestType';

export const fetchBuildingConfigs = (
  visitList: VisitInfo[]
): BuildingAction => ({
  type: FETCH_BUILDING_CONFIG,
  payload: {
    visitList
  }
});

export const fetchBuildingConfigsSuccess = (
  buildingList: Building[]
): BuildingAction => ({
  type: FETCH_BUILDING_CONFIG_SUCCESS,
  payload: {
    buildingList
  }
});

export const fetchVisitBuilding = (
  building: Building
): fetchVisitBuildingAction => ({
  type: FETCH_VISIT_BUILDING,
  payload: {
    building
  }
});
