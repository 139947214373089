/* eslint-disable import/extensions */
import React from 'react';
import { Provider } from 'react-redux';
import { initializeIcons } from '@uifabric/icons';
import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router, Switch, Route, Redirect, BrowserRouter
} from 'react-router-dom';
import styled from './App.styled';
import configureStore from './store/configureStore';
import { LanguageProvider } from './providers/LanguageProvider';
import theme from './asset/themes/defaultTheme';
import RouterOutlet from './routes/AppRouterOutlet';
import routesList from './routes/routerConfig';
import config from './env/index';
import RootPageWorldMap from './views/RootPage/RootPage';
import BotChatComponent from './views/VirtualHost/VirtualHost';

// Register icons and pull the fonts from the default SharePoint cdn.
initializeIcons();

// Configure store object
const store = configureStore();


export const PageWrapper: React.FC<{MiddleComponent: React.ComponentType}> = ({MiddleComponent}) => (
  <BrowserRouter>
    <Provider store={store}>
      <LanguageProvider>
      {MiddleComponent != BotChatComponent &&  <styled.StyledHeader />}
        <MiddleComponent />
        {MiddleComponent === RootPageWorldMap && <styled.StyledFooter />}
      </LanguageProvider>
    </Provider>
  </BrowserRouter>
);

export const Root: React.FC = () => <PageWrapper MiddleComponent={RootPageWorldMap} />;
export const Chat: React.FC = () => <PageWrapper MiddleComponent={BotChatComponent} />;


const App: React.FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <Router>
          <Switch>
            {/* Redirect to default region if not specified */}
            <Redirect
              exact
              from="/"
              to={config.REGION_CONFIG.defaultRegion}
            />
            <Route path={routesList.path}>
              <styled.StyledHeader />
              <main>
                <styled.RouterOutletContainer>
                  <RouterOutlet />
                </styled.RouterOutletContainer>
              </main>
              <styled.StyledFooter />
            </Route>
          </Switch>
        </Router>

      </LanguageProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
