/* eslint-disable import/extensions */
import { RootState } from '../rootReducer';
import { Building } from './buildingType';

export const buildingParkingConfigSelector = (
  state: RootState
): boolean => {
  const buildingWithParkingList = state.building.buildingList.filter((building) =>
    building.gmsConfigs.parkingAvailable === true);
  if (buildingWithParkingList.length) {
    return true;
  }
  return false;
};

export const isBuildingConfigLoaded = (
  state: RootState
): boolean => state.building.isBuildingConfigLoaded;

export const buildingWithParkingSelector = (
  state: RootState
): string[] => state.building.buildingList.filter((building) =>
  building.gmsConfigs.parkingAvailable === true)
  .map((building) => building.buildingName);

// selector for country of the building with immediate upcoming visit.
export const buildingCountrySelector = (
  state: RootState
): string => state.building.buildingList[0]?.buildingCountry;

export const buildingsSelector = (
  state: RootState,
): Building[] => state.building.buildingList;

export const visitBuildingSelector = (
  state: RootState,
): Building | undefined => state.building.visitBuilding;
