/* eslint-disable import/extensions */
import React, { useContext } from 'react';
import { Stack, StackItem } from 'office-ui-fabric-react';
import styled from './Banner.styled';
import { Text, LanguageContext } from '../../providers/LanguageProvider';
import { GuestPortalConstants } from '../../config/constants';
import msalAuthenticator from '../../auth/msalAuthenticator';
import { getCurrentRegion } from '../../routes/routerConfig';

interface Props {
  BannerText: string;
  BannerLinkText: string | null;
  BannerLink: string | '';
}

const Banner: React.FC<Props> = ({
  BannerText, BannerLinkText, BannerLink
}) => {
  const languageContext = useContext(LanguageContext);
  const isLangRTL = (languageContext.language.languageType
          === GuestPortalConstants.RtlLanguageType);
  const regionName = getCurrentRegion();

  return (
    <styled.BannerContainer isRTL={isLangRTL} horizontal verticalAlign="center">
      <StackItem>
        <Stack horizontal verticalAlign="center">
          <StackItem>
            <styled.BannerIcon iconName="Warning" />
          </StackItem>
          <StackItem styles={styled.BannerContainerStackItem(isLangRTL)}>
            <styled.BannerText isRTL={isLangRTL}>
              <Text tid={BannerText} />
              {BannerLink && (
                <styled.BannerCheckLink
                  target="_blank"
                  href={`${BannerLink}${languageContext.language.id}?client=guestuser&region=${regionName}&idtoken=${msalAuthenticator.getIdToken()}`}
                >
                  <Text tid={BannerLinkText} />
                </styled.BannerCheckLink>
              )}
            </styled.BannerText>
          </StackItem>
        </Stack>
      </StackItem>
    </styled.BannerContainer>
  );
};

export default Banner;
