import LocalEnvironment from './env.local';
import DevEnvironment from './env.dev';
import ProdEnvironment from './env.prod';

/**
 * Picks and returns the correct config based on the current execution environment.
 * This REACT_APP_ENV env variable is set through yarn scripts and .env-cmdrc file in root
 */
const environment = (): any => {
  switch (process.env.REACT_APP_ENV) {
    case 'local': return LocalEnvironment;
    case 'development': return DevEnvironment;
    case 'production': return ProdEnvironment;
    default: return LocalEnvironment;
  }
};

export default environment();
