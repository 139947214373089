/* eslint-disable import/extensions */
import styled from 'styled-components';
import {
  Stack
} from 'office-ui-fabric-react';
import { Image } from 'office-ui-fabric-react/lib/Image';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import theme from '../../asset/themes/defaultTheme';

const Container = styled(Stack)`
  background-color: ${theme.brandWhite};
  width: 100%;
  height: 100%;
`;

const GuestImage = styled(Image)`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

const GuestImageButton = styled.button`
  background-color: ${theme.brandWhite};
  padding-top: 2px;
  border: none;
`;


const MsLogoImage = styled.img`
  height: 30px;
`;


const StyledLanguageSelector = styled(LanguageSelector)`
`;

const StackStyle = styled(Stack)`
  width: 100vw;
  height:5vh;
`;

export default {
  Container,
  GuestImage,
  GuestImageButton,
  MsLogoImage,
  StyledLanguageSelector,
  StackStyle
};
