/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable prefer-const */
import { put, takeLatest, all } from 'redux-saga/effects';
import * as guestAction from './guestAction';
import {
  FETCH_GUEST_VISIT_DETAILS, UPDATE_GUEST_DETAILS,
  FETCH_HOST_DETAILS, VALIDATE_QRCODE, VISIT_CHECKIN
} from '../actionTypes';
import {
  guestVisitUpdate, guestDetail, hostDetail, hostDetailsUpdate,
  validateQRResponse, visitCheckinUpdateResource
} from './guestResource';
import {
  UpdateGuestDetailsAction, FetchHostDetailsAction,
  GuestVisitUpdateType, GuestVisitDetailsType, HostDetailsType, HostDetailsUpdateType,
  ValidateQRCodeAction, ValidateQRResponseType,
  VisitCheckinAction, VisitCheckinUpdateType
} from './guestType';
import msalAuthenticator from '../../auth/msalAuthenticator';
import apiConfig from '../../config/apiConfig';
import httpService from '../../config/apiInterceptor';
import { GuestPortalConstants } from '../../config/constants';
import {
  ErrorCodes, CommonLoggingConstants, EventCodes, GuestDetailLoggingConstants
} from '../../config/loggingCodes';
import logger from '../../common/logging/AILogger';
import { CustomProperties } from '../../common/logging/logger';

function* fetchGuestVisitDetails(): Generator {
  try {
    let guestVisitUpdateDetail: GuestVisitUpdateType = guestVisitUpdate;
    let guestVisitDetails: GuestVisitDetailsType = guestDetail;
    const customProperties: CustomProperties = [];
    const guestEmail = msalAuthenticator.getUserEmail();
    const params = {
      UpcomingVisitsOnly: false,
    };
    if(guestEmail){
    yield httpService.get(apiConfig.GetGuestVisitDetailsConfiguration(guestEmail), { params })
      .then((response) => {
        guestVisitDetails = response.data;
        customProperties[GuestDetailLoggingConstants.GuestVisitDetail] = guestVisitDetails;
        logger.logEvent(EventCodes.EV_103, `${CommonLoggingConstants.ComponentName}guestsaga:fetchGuestVisitDetails`, customProperties);
      }).catch((error) => {
        const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
        logger.logError(ErrorCodes.ER_106, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}guestSaga:fetchGuestVisitDetails`, JSON.stringify(error.stack));
      });
    guestVisitUpdateDetail.guestVisitDetails = guestVisitDetails;
    guestVisitUpdateDetail.guestUpdateStatus = GuestPortalConstants.DefaultStatus;
    yield put(guestAction.fetchGuestVisitDetailsSuccess(guestVisitUpdateDetail));
    }
  } catch (error) {
    const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
    logger.logError(ErrorCodes.ER_106, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}guestSaga:fetchGuestVisitDetails`, JSON.stringify(error.stack));
  }
}

function* fetchHostDetails({ payload }: FetchHostDetailsAction): Generator {
  try {
    let hostUpdateDetail: HostDetailsUpdateType = hostDetailsUpdate;
    let hostDetails: HostDetailsType = hostDetail;
    yield httpService.get(apiConfig.getHostDetailsConfiguration(payload.hostId))
      .then((response) => {
        hostDetails = response.data;
      }).catch((error) => {
        const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
        logger.logError(ErrorCodes.ER_105, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}guestSaga:fetchHostDetails`, JSON.stringify(error.stack));
      });
    hostUpdateDetail.hostDetails = hostDetails;
    hostUpdateDetail.hostUpdateStatus = GuestPortalConstants.DefaultStatus;
    yield put(guestAction.fetchHostDetailsSuccess(hostUpdateDetail));
  } catch (error) {
    const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
    logger.logError(ErrorCodes.ER_105, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}guestSaga:fetchHostDetails`, JSON.stringify(error.stack));
  }
}

function* visitCheckin({ payload }: VisitCheckinAction): Generator {
  try {
    let visitCheckinUpdate: VisitCheckinUpdateType = visitCheckinUpdateResource;
    visitCheckinUpdate.visitId = payload.checkinDetail.visitId;
    const customProperties: CustomProperties = [];
    yield httpService.post(apiConfig.VisitCheckinConfiguration(),
      payload.checkinDetail.checkinDetails)
      .then((response) => {
        visitCheckinUpdate.success = response.data.success
          ? GuestPortalConstants.Success : GuestPortalConstants.Failure;
          customProperties[GuestDetailLoggingConstants
            .GuestVisitUpdateDetail] = payload.checkinDetail.checkinDetails;
          logger.logEvent(EventCodes.EV_105,
            `${CommonLoggingConstants.ComponentName}guestsaga:visitCheckin`, customProperties);
      }).catch((error) => {
        visitCheckinUpdate.success = GuestPortalConstants.Failure;
        const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
        logger.logError(ErrorCodes.ER_104, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}guestSaga:visitCheckin`, JSON.stringify(error.stack));
      });
    yield put(guestAction.visitCheckinSuccess(visitCheckinUpdate));
  } catch (error) {
    const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
    logger.logError(ErrorCodes.ER_104, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}guestSaga:visitCheckin`, JSON.stringify(error.stack));
  }
}

/**
 * Update visit details
 * @param updatedDetails
 */
function* updateGuestDetails({ payload }: UpdateGuestDetailsAction): Generator {
  try {
    let guestVisitUpdateDetail: GuestVisitUpdateType = guestVisitUpdate;
    let guestVisitDetails: GuestVisitDetailsType = guestDetail;
    const customProperties: CustomProperties = [];
    yield httpService.post(apiConfig.UpdateGuestVisitDetailsConfiguration(),
      payload.guestVehicleDetail)
      .then((response) => {
        guestVisitDetails = response.data;
        guestVisitUpdateDetail.guestVisitDetails = guestVisitDetails;
        guestVisitUpdateDetail.guestUpdateStatus = GuestPortalConstants.Success;
        customProperties[GuestDetailLoggingConstants
          .GuestVisitUpdateDetail] = guestVisitUpdateDetail;
        logger.logEvent(EventCodes.EV_102,
          `${CommonLoggingConstants.ComponentName}guestsaga:updateGuestDetails`, customProperties);
      }).catch((error) => {
        guestVisitUpdateDetail.guestUpdateStatus = GuestPortalConstants.Failure;
        const errorToLog = error && error.response && error.response.data ? error.response.data
          : error.message;
        logger.logError(ErrorCodes.ER_150, JSON.stringify(errorToLog),
          `${CommonLoggingConstants.ComponentName}guestSaga:UpdateGuestDetails`,
          JSON.stringify(error.stack));
      });
    yield put(guestAction.fetchGuestVisitDetailsSuccess(guestVisitUpdateDetail));
  } catch (error) {
    const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
    logger.logError(ErrorCodes.ER_150, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}guestSaga:updateGuestDetails`, JSON.stringify(error.stack));
  }
}

/**
 * Validating QR code
 */
function* validateqrcode({ payload }: ValidateQRCodeAction): Generator {
  try {
    let validateQRResponseDetail: ValidateQRResponseType = validateQRResponse;
    const guestEmail = msalAuthenticator.getUserEmail();
    const params = {
      qrString: payload.checkinInfo.qrString,
      emailId: guestEmail,
    };
    yield httpService.get(apiConfig.ValidatingQRCodeConfiguration(
      payload.checkinInfo.visitId
    ), { params })
      .then((response) => {
        validateQRResponseDetail.validateQRCheckinVisitsDetail = response.data;
        validateQRResponseDetail.validateQRSuccessStatus = GuestPortalConstants.Success;
      })
      .catch((error) => {
        validateQRResponseDetail.validateQRSuccessStatus = GuestPortalConstants.Failure;
        const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
        logger.logError(ErrorCodes.ER_103, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}guestSaga:validateqrcode`, JSON.stringify(error.stack));
      });
    yield put(guestAction.fetchValidateQRSuccess(validateQRResponseDetail));
  } catch (error) {
    const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
    logger.logError(ErrorCodes.ER_103, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}guestSaga:validateqrcode`, JSON.stringify(error.stack));
  }
}

/**
 * Export all guest sagas
 */
export default function* guestSagas(): Generator {
  yield all([
    takeLatest(FETCH_GUEST_VISIT_DETAILS, fetchGuestVisitDetails),
    takeLatest(UPDATE_GUEST_DETAILS, updateGuestDetails),
    takeLatest(FETCH_HOST_DETAILS, fetchHostDetails),
    takeLatest(VALIDATE_QRCODE, validateqrcode),
    takeLatest(VISIT_CHECKIN, visitCheckin)
  ]);
}
