/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  IStackStyles, IStackItemStyles, Dropdown, Label, StackItem, Stack
} from 'office-ui-fabric-react';
import styled from 'styled-components';
import { ICSSRule } from '@uifabric/merge-styles';
import { ICSSDisplayRule } from '@uifabric/merge-styles/lib/IRawStyleBase';
import theme from '../../../asset/themes/defaultTheme';
import { size } from '../../../config/constants';

const VehicleStack: IStackStyles = {
  root: {
    width: '100%',
    selectors: {
      [`@media(min-width: ${size.mobileS})`]: {
        width: '100%'
      }
    }
  }
};

const VehicleDetailField: IStackItemStyles = {
  root: {
    width: '48%',
    display: 'flex',
    flexDirection: 'column',
    selectors: {
      [`@media(max-width: ${size.mobileS})`]: {
        width: '100%'
      }
    }
  }
};

const VehicleStateField: IStackItemStyles = {
  root: {
    width: '100%'
  }
};

const LicensePlateField: IStackItemStyles = {
  root: {
    width: '100%',
    height: '70px'
  }
};

const FieldLabel = styled(Label)<{isRTL: boolean}>`
  font-size:${theme.fontSizeSmall} ;
  display: flex;
  flex-direction: ${(props) => (props.isRTL ? 'row-reverse' : 'row')};
  font-weight: ${theme.fontWeightMediumBold};
  float: ${(props) => (props.isRTL ? 'right' : 'left')};
  textAlign: ${(props) => (props.isRTL ? 'right' : 'left')};
`;

const VehicleDropdownContainer = styled.div`
  width: 100%;
  display: inline-block;
  padding-bottom: 5px;
`;

const ModelStackItemStyle = styled(StackItem)<{isRTL: boolean}>`
  padding-left: ${(props) => (props.isRTL ? '0%' : '4%')};
  padding-right: ${(props) => (props.isRTL ? '4%' : '0%')};
  width: 48%;
  display: flex;
  flex-direction: column;
  @media(max-width: ${size.mobileS}){
    padding-left: 0%;
    padding-right: 0%;
    width: 100%
  }

`;

const VehicleDropdown = styled(Dropdown)`
`;

const LongFieldText = styled.input<{isRTL: boolean}>`
  border: 1px solid ${theme.brandExtraDarkGray};
  border-radius: 2px;
  width: 100%;
  height: 30px;
  box-sizing: border-box; 
  padding: 10px;
  direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')} 
  // unicode-bidi:bidi-override;
  fontSize: ${theme.fontSizeXLSmall};
  ::placeholder { 
    color: ${theme.brandDarkGray};
    font-size: ${theme.fontSizeSmall};
  }
  :disabled {
    color: ${theme.brandDarkGray};
    background: ${theme.brandBackgroundDarkGray};
  }
`;
const VehicleDropdownStyles = (isLangRTL) => ({
  root: {
    alignItems: 'center' as ICSSRule,
  },
  title: {
    display: 'flex' as ICSSDisplayRule,
    border: `1px solid ${theme.brandExtraDarkGray}`,
    borderRadius: '2px',
    alignItems: 'center' as ICSSRule,
    top: '0 !important',
    left: '0 !important',
    width: '100%',
    height: '30px',
    textAlign: isLangRTL ? 'right' : 'left',
    fontSize: theme.fontSizeSmall,
    direction: isLangRTL ? 'rtl' : 'ltr',
    paddingRight: '8px',
    selectors: {
      [`@media(min-width: ${size.mobileS})`]: {
        width: '100%'
      },
    }
  },
  dropdownItem: {
    borderWidth: '0px 0px 1px 0px',
    borderStyle: 'solid',
    borderColor: theme.brandGray,
    height: '40px',
    display: 'block' as ICSSDisplayRule,
    fontSize: theme.fontSizeSmall
  },
  dropdownOptionText: {
    marginLeft: isLangRTL ? 'auto' : 'left',
    fontSize: theme.fontSizeSmall
  },
  dropdownItemSelected: {
  },
  caretDownWrapper: {
    display: 'flex' as ICSSDisplayRule,
    alignItems: 'center' as ICSSRule,
    fontSize: theme.fontSizeXSmall
  },
  caretDown: {
    color: `${theme.primaryColor} !important`,
    fontWeight: theme.fontWeightBold as unknown as ICSSRule,
    float: 'left',
  },
  label: {
    fontSize: theme.fontSizeLarge,
    padding: 0
  },
  dropdownItemsWrapper: {
    maxHeight: 300,
    background: theme.brandWhite,
  },
  callout: {
  }
});

const DropdownPlaceholderStyle = styled.span<{isRTL: boolean}>`
  color: ${theme.brandExtraDarkGray};
  font-size: ${theme.fontSizeSmall};
  float: ${(props) => (props.isRTL ? 'right' : 'left')};
`;

const errorFragment = styled.span<{isRTL: boolean}>`
  color: #e50000;
  float: ${(props) => (props.isRTL ? 'left' : 'right')}; 
  font-size: ${theme.fontSizeXSmall};
`;

const LoaderStyle = {
  root: {
  },
  circle: {
    marginTop: '30px'
  }
};

const MakeModelStack = styled(Stack)`
  display: flex;
  flex-direction: row;
  padding-bottom: 4%;
  @media(max-width: ${size.mobileS}){
    display: block;
  }
`;

export default {
  VehicleStack,
  VehicleDetailField,
  VehicleStateField,
  FieldLabel,
  VehicleDropdownContainer,
  VehicleDropdown,
  LongFieldText,
  VehicleDropdownStyles,
  DropdownPlaceholderStyle,
  errorFragment,
  LoaderStyle,
  ModelStackItemStyle,
  LicensePlateField,
  MakeModelStack
};
