/* eslint-disable complexity */
import * as Msal from 'msal';
import JwtDecode from 'jwt-decode';

const state: any = {
  launchApp: null,
  errorApp: null,
  idToken: null,
  accessToken: null,
  scopes: null,
  userName: '',
  webApiAccessToken: null,
  account: null
};
let appConfig = {
  instance: null,
  tenant: null,
  signInPolicy: null,
  resetPolicy: null,
  applicationId: null,
  cacheLocation: null,
  scopes: [],
  webApiAcopes: [],
  redirectUri: null,
  postLogoutRedirectUri: null,
  validateAuthority: null,
  silentLoginOnly: false
};

let msalInstance: any;

function redirect() {
  const instance = appConfig.instance ? appConfig.instance : 'https://login.microsoftonline.com/tfp/';
  const authority = `${instance}${appConfig.tenant}/${appConfig.resetPolicy}`;
  msalInstance.authority = authority;
  loginAndAcquireToken();
}

const loginAndAcquireToken = () => {
  msalInstance.loginRedirect({ scopes: appConfig.scopes });
};

// acquire a token silently
function getToken(accessTokenRequest, isWebApi) {
  return msalInstance.acquireTokenSilent(accessTokenRequest).then((accessToken) => {
    if (isWebApi) {
      state.webApiAccessToken = accessToken.accessToken;
    } else {
      state.accessToken = accessToken;
    }
    if (state.webApiAccessToken && state.accessToken && state.launchApp) {
      state.launchApp();
    }
  }).catch((error) => {
    if (error.errorCode.indexOf('consent_required') > -1
      || error.errorCode.indexOf('interaction_required') > -1
      || error.errorCode.indexOf('login_required') > -1
      || error.errorCode.indexOf('token_renewal_error') > -1
    ) {
      msalInstance.acquireTokenRedirect(accessTokenRequest);
    } else {
      console.error('Non-interactive error:', error.errorCode);
    }
  });
}

const authentication = {
  initialize: (config) => {
    appConfig = config;
    const instance = config.instance ? config.instance : 'https://login.microsoftonline.com/tfp/';
    state.scopes = config.scopes;
    state.webApiAcopes = config.webApiAcopes;

    const msalConfig = {
      auth: {
        clientId: config.applicationId,
        authority: `${instance}${config.tenant}/${config.signInPolicy}`,
        validateAuthority: (config.validateAuthority != null) ? config.validateAuthority : true,
        redirectUri: config.redirectUri,
        postLogoutRedirectUri: config.postLogoutRedirectUri,
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: config.cacheLocation
      }
    };

    msalInstance = new Msal.UserAgentApplication(msalConfig);
    msalInstance.handleRedirectCallback((error, response) => {
      if (response && response.idToken && response.idToken.claims
          && response.idToken.claims.acr === appConfig.resetPolicy) {
        msalInstance.logout();
      }
      if (error) {
        // In case of password reset flow
        if (error.toString().indexOf('AADB2C90118') > -1) {
          redirect();
        } else {
          console.log(error);
        }
      } else if (response && response.idToken && response.idToken.rawIdToken
        && response.idToken.claims
         && response.idToken.claims.acr === appConfig.signInPolicy
         && response.idToken.claims.idp === 'google.com') {
        state.userName = response.idToken.claims.email;
        getToken({ scopes: appConfig.scopes, loginHint: state.userName }, false);
        getToken({ scopes: appConfig.webApiAcopes, loginHint: state.userName }, true);
      } else {
        getToken({ scopes: appConfig.scopes }, false);
        getToken({ scopes: appConfig.webApiAcopes }, true);
      }
    });
    const account = msalInstance.getAccount();
    state.account = account;
  },
  run: (launchApp: any, errorApp?: any) => {
    state.launchApp = launchApp;
    if (errorApp) state.errorApp = errorApp;
    if (state.account) {
      getToken({ scopes: appConfig.scopes }, false);
      getToken({ scopes: appConfig.webApiAcopes }, true);
    }
    if (!state.account && !msalInstance.isCallback(window.location.hash) && window.parent === window && !window.opener) {
      return loginAndAcquireToken();
    }
  },
  signOut: () => {
    msalInstance.logout();
  },
  getAccessToken: () => state.accessToken,
  getIdToken: () => {
    if(state.accessToken.idToken)
    return state.accessToken.idToken.rawIdToken
    else{
      console.log('Token from cache!!')
      return window.sessionStorage.getItem("msal.idtoken");
    }
  },
  getWebApiAccessToken: () => state.webApiAccessToken,
  getUserEmail: () => {
    const accessClaims = JwtDecode(state.accessToken.idToken.rawIdToken);
    const guestEmailId = (accessClaims && accessClaims.email) ? accessClaims.email : '';
    return guestEmailId;
  }
};

export default authentication;
