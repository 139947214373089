/* eslint-disable import/extensions */
import { CustomProperties } from './logger';
import { CommonLoggingConstants, GuestDetailLoggingConstants } from '../../config/loggingCodes';
import msalAuthenticator from '../../auth/msalAuthenticator';
import obfuscateProperty from './propertyObfuscationHelper';
import { getCurrentStore } from '../../store/configureStore';

export const BuildCommonLoggingProperties = (): CustomProperties => {
  const commonProperties: CustomProperties = [];
  commonProperties[CommonLoggingConstants.GuestEmail] = obfuscateProperty(
    msalAuthenticator.getUserEmail()
  );
  commonProperties[CommonLoggingConstants.ChangedBy] = obfuscateProperty(
    msalAuthenticator.getUserEmail()
  );
  commonProperties[CommonLoggingConstants.LogSource] = CommonLoggingConstants.GPWEB;
  // eslint-disable-next-line prefer-destructuring
  commonProperties[CommonLoggingConstants.CurrentUrl] = window.location.href.split('#')[0];

  return commonProperties;
};

export const GuestDetailLoggingProperties = (): CustomProperties => {
  const currentStore = getCurrentStore();

  const guestStoreData = currentStore.guest;
  const guestProperties: CustomProperties = [];
  guestProperties[GuestDetailLoggingConstants.GuestFirstName] = obfuscateProperty(
    guestStoreData.guestVisitDetails?.guest?.firstName
  );
  guestProperties[GuestDetailLoggingConstants.GuestLastName] = obfuscateProperty(
    guestStoreData.guestVisitDetails?.guest?.lastName
  );
  guestProperties[GuestDetailLoggingConstants.CompanyName] = guestStoreData
    .guestVisitDetails?.guest?.companyName;
  guestProperties[GuestDetailLoggingConstants.Phone] = guestStoreData
    .guestVisitDetails?.guest?.phone;
  guestProperties[GuestDetailLoggingConstants.AvatarName] = guestStoreData
    .guestVisitDetails?.guest?.avatarName;
  guestProperties[GuestDetailLoggingConstants.VehicleInfo] = guestStoreData
    .guestVisitDetails?.vehicleInfo;
  guestProperties[GuestDetailLoggingConstants.GuestVisitList] = guestStoreData
    .guestVisitDetails?.visitList;

  return guestProperties;
};
