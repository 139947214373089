export const countryData = [
  { countryShortCode: 'AL', countryName: 'Albania' },
  { countryShortCode: 'DZ', countryName: 'Algeria' },
  { countryShortCode: 'AO', countryName: 'Angola' },
  { countryShortCode: 'AR', countryName: 'Argentina' },
  { countryShortCode: 'AM', countryName: 'Armenia' },
  { countryShortCode: 'AU', countryName: 'Australia' },
  { countryShortCode: 'AT', countryName: 'Austria' },
  { countryShortCode: 'AZ', countryName: 'Azerbaijan' },
  { countryShortCode: 'BH', countryName: 'Bahrain' },
  { countryShortCode: 'BD', countryName: 'Bangladesh' },
  { countryShortCode: 'BY', countryName: 'Belarus' },
  { countryShortCode: 'BE', countryName: 'Belgium' },
  { countryShortCode: 'BM', countryName: 'Bermuda' },
  { countryShortCode: 'BA', countryName: 'Bosnia and Herzegovina' },
  { countryShortCode: 'BR', countryName: 'Brazil' },
  { countryShortCode: 'BN', countryName: 'Brunei' },
  { countryShortCode: 'BG', countryName: 'Bulgaria' },
  { countryShortCode: 'CA', countryName: 'Canada' },
  { countryShortCode: 'CM', countryName: 'Cameroon' },
  { countryShortCode: 'CL', countryName: 'Chile' },
  { countryShortCode: 'CN', countryName: 'China' },
  { countryShortCode: 'CO', countryName: 'Colombia' },
  { countryShortCode: 'CR', countryName: 'Costa Rica' },
  { countryShortCode: 'CI', countryName: 'Côte d’Ivoire' },
  { countryShortCode: 'HR', countryName: 'Croatia' },
  { countryShortCode: 'CY', countryName: 'Cyprus' },
  { countryShortCode: 'CZ', countryName: 'Czechia' },
  { countryShortCode: 'DK', countryName: 'Denmark' },
  { countryShortCode: 'DO', countryName: 'Dominican Republic' },
  { countryShortCode: 'EC', countryName: 'Ecuador' },
  { countryShortCode: 'EG', countryName: 'Egypt' },
  { countryShortCode: 'SV', countryName: 'El Salvador' },
  { countryShortCode: 'EE', countryName: 'Estonia' },
  { countryShortCode: 'FR', countryName: 'France' },
  { countryShortCode: 'FI', countryName: 'Finland' },
  { countryShortCode: 'GE', countryName: 'Georgia' },
  { countryShortCode: 'DE', countryName: 'Germany' },
  { countryShortCode: 'GH', countryName: 'Ghana' },
  { countryShortCode: 'GR', countryName: 'Greece' },
  { countryShortCode: 'GU', countryName: 'Guam' },
  { countryShortCode: 'GT', countryName: 'Guatemala' },
  { countryShortCode: 'HN', countryName: 'Honduras' },
  { countryShortCode: 'HK', countryName: 'Hong Kong SAR' },
  { countryShortCode: 'HU', countryName: 'Hungary' },
  { countryShortCode: 'IS', countryName: 'Iceland' },
  { countryShortCode: 'IN', countryName: 'India' },
  { countryShortCode: 'ID', countryName: 'Indonesia' },
  { countryShortCode: 'IE', countryName: 'Ireland' },
  { countryShortCode: 'IL', countryName: 'Israel' },
  { countryShortCode: 'IT', countryName: 'Italy' },
  { countryShortCode: 'JM', countryName: 'Jamaica' },
  { countryShortCode: 'JP', countryName: 'Japan' },
  { countryShortCode: 'JO', countryName: 'Jordan' },
  { countryShortCode: 'KZ', countryName: 'Kazakhstan' },
  { countryShortCode: 'KE', countryName: 'Kenya' },
  { countryShortCode: 'KR', countryName: 'Korea' },
  { countryShortCode: 'KW', countryName: 'Kuwait' },
  { countryShortCode: 'LV', countryName: 'Latvia' },
  { countryShortCode: 'LB', countryName: 'Lebanon' },
  { countryShortCode: 'LT', countryName: 'Lithuania' },
  { countryShortCode: 'LU', countryName: 'Luxembourg' },
  { countryShortCode: 'MO', countryName: 'Macao SAR' },
  { countryShortCode: 'MY', countryName: 'Malaysia' },
  { countryShortCode: 'MT', countryName: 'Malta' },
  { countryShortCode: 'MU', countryName: 'Mauritius' },
  { countryShortCode: 'MX', countryName: 'Mexico' },
  { countryShortCode: 'ME', countryName: 'Montenegro' },
  { countryShortCode: 'MA', countryName: 'Morocco' },
  { countryShortCode: 'MM', countryName: 'Myanmar' },
  { countryShortCode: 'NA', countryName: 'Namibia' },
  { countryShortCode: 'NP', countryName: 'Nepal' },
  { countryShortCode: 'NL', countryName: 'Netherlands' },
  { countryShortCode: 'NZ', countryName: 'New Zealand' },
  { countryShortCode: 'NG', countryName: 'Nigeria' },
  { countryShortCode: 'MK', countryName: 'North Macedonia' },
  { countryShortCode: 'NO', countryName: 'Norway' },
  { countryShortCode: 'OM', countryName: 'Oman' },
  { countryShortCode: 'PK', countryName: 'Pakistan' },
  { countryShortCode: 'PA', countryName: 'Panama' },
  { countryShortCode: 'PY', countryName: 'Paraguay' },
  { countryShortCode: 'PE', countryName: 'Peru' },
  { countryShortCode: 'PH', countryName: 'Philippines' },
  { countryShortCode: 'PL', countryName: 'Poland' },
  { countryShortCode: 'PT', countryName: 'Portugal' },
  { countryShortCode: 'PR', countryName: 'Puerto Rico' },
  { countryShortCode: 'QA', countryName: 'Qatar' },
  { countryShortCode: 'RO', countryName: 'Romania' },
  { countryShortCode: 'RU', countryName: 'Russia' },
  { countryShortCode: 'SA', countryName: 'Saudi Arabia' },
  { countryShortCode: 'SN', countryName: 'Senegal' },
  { countryShortCode: 'RS', countryName: 'Serbia' },
  { countryShortCode: 'SG', countryName: 'Singapore' },
  { countryShortCode: 'SK', countryName: 'Slovakia' },
  { countryShortCode: 'SI', countryName: 'Slovenia' },
  { countryShortCode: 'ZA', countryName: 'South Africa' },
  { countryShortCode: 'ES', countryName: 'Spain' },
  { countryShortCode: 'LK', countryName: 'Sri Lanka' },
  { countryShortCode: 'SE', countryName: 'Sweden' },
  { countryShortCode: 'CH', countryName: 'Switzerland' },
  { countryShortCode: 'TW', countryName: 'Taiwan' },
  { countryShortCode: 'TH', countryName: 'Thailand' },
  { countryShortCode: 'TT', countryName: 'Trinidad and Tobago' },
  { countryShortCode: 'TN', countryName: 'Tunisia' },
  { countryShortCode: 'TR', countryName: 'Turkey' },
  { countryShortCode: 'UA', countryName: 'Ukraine' },
  { countryShortCode: 'AE', countryName: 'United Arab Emirates' },
  { countryShortCode: 'GB', countryName: 'United Kingdom' },
  { countryShortCode: 'US', countryName: 'United States' },
  { countryShortCode: 'UY', countryName: 'Uruguay' },
  { countryShortCode: 'VE', countryName: 'Venezuela' },
  { countryShortCode: 'VN', countryName: 'Vietnam' },
];
