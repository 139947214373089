import styled from 'styled-components';
import {Stack} from 'office-ui-fabric-react';
import { size } from '../../../config/constants';

const Container = styled(Stack)`
    margin-left: auto;
    margin-right: auto;
    padding-top: 2%;
    padding-bottom: 30px;
    margin-bottom: auto;
    width: 40%;
    @media(min-width: ${size.laptopXL}) {
      width: 35%;
    };
    @media(max-width: ${size.laptop}) {
      width: 70%;
    };
    @media(max-width: ${size.mobileXL}){
        width: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
      }
`;

export default {
  Container
};
