import { Stack } from 'office-ui-fabric-react';
import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import ValidateInfo from './ValidateInfo/ValidateInfo';
import CompleteCheckin from './CompleteCheckin/CompleteCheckin';
import LobbyQR from './LobbyQR/LobbyQR';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import { GuestPortalConstants } from '../../config/constants';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as GuestVisitSelector from '../../store/guest/guestSelector';
import { RootState } from '../../store/rootReducer';

const Checkin: React.FC = () => {
  const [view, setView] = useState(GuestPortalConstants.LobbyQRView);
  const location = useLocation();
  // Getting query params from path
  const visitId = (new URLSearchParams(location.search)).get('id')!;
  const {
    guestVisitDetail
  } = useSelector((state: RootState) => ({
    guestVisitDetail: GuestVisitSelector.guestSelector(state),
  }), shallowEqual);
  let qrCode = guestVisitDetail.visitList.filter(visit=> visit.visitId == visitId)[0].qrCode;
  return (
    <Stack>
      {view === GuestPortalConstants.LobbyQRView && (<LobbyQR visitId={visitId} switchView={setView} />)}
      {view === GuestPortalConstants.EditDetailsView && (<PersonalDetails visitId={visitId} switchView={setView} />)}
      {view === GuestPortalConstants.ValidateInfoView && (<ValidateInfo visitId={visitId} switchView={setView} qrCode={qrCode}/>)}
      {view === GuestPortalConstants.CompleteCheckinView && (<CompleteCheckin />)}
    </Stack>
  );
};

export default Checkin;
