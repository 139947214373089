/* eslint-disable import/extensions */
import React, { useState, useContext, ReactNode } from 'react';
import {
  Modal, PrimaryButton
} from 'office-ui-fabric-react';
import styled from './Dialog.styled';
import { Text, LanguageContext } from '../../providers/LanguageProvider';
import { GuestPortalConstants } from '../../config/constants';

interface Props {
  heading: string;
  icon: ReactNode;
  children: ReactNode;
  isOpen: boolean;
  setIsOpen(boolean);
}

const Dialog: React.FC<Props> = ({
  heading,
  icon,
  children,
  isOpen,
  setIsOpen
}) => {
  const [isDialogHidden, setHideDialog] = useState(true);


  const hideModal = () => setIsOpen(false);

  const languageContext = useContext(LanguageContext);
  const isLangRTL = (languageContext.language.languageType
    === GuestPortalConstants.RtlLanguageType);

  return (
    <Modal
      isDarkOverlay
      isOpen={isOpen}
      onDismiss={hideModal}
      titleAriaId="id"
    >
      <styled.DialogContentContainer isRTL={isLangRTL} >
        <styled.DialogCloseIcon
          onClick={hideModal}
          aria-label={languageContext.dictionary.Dismiss}
        />
        <styled.DialogHeader id="id" role="heading" aria-level={2}>
          {heading}
        </styled.DialogHeader>
        <styled.DialogIcon>
          {icon}
        </styled.DialogIcon>
        <br />
        <styled.DialogText>
          {children}
        </styled.DialogText>
        <styled.DialogFooter>
          <PrimaryButton onClick={hideModal} text={Text({ tid: 'Okay' })} />
        </styled.DialogFooter>
      </styled.DialogContentContainer>
    </Modal>
  );
};

export default Dialog;
