import msftSymbol from "../../asset/images/microsoftImg.svg";

export const options = {
  botAvatarImage: msftSymbol,
  // userAvatarInitials: id.slice(0, 2)?.toUpperCase() || 'UU',
  // userAvatarBackgroundColor: 'darkgreen',
  botAvatarBackgroundColor: "none",

  // botAvatarImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAATUSURBVFhHrVdbTFxVFF13HsDglIe2RSdUaNSaaGurUu0jLTZ+6If1w7Q0EdNYfzRRG3/9IIzaqL9N9UNjSLQvC41+SIwxMfRtJKRtFJoUK+LUUm2ZUhg6MAPDde1z7sCduXceCIsszjn7zj17n7333eccwzRNFAPjvZNBNqvJ7eRmchV5DymIkv3kWfI7mOg1w43j8qAQChpAxVVsmsldpCj2kPkwQ4ohx2jIYRpyW0lzIK8BVL6HzbvkQ0owf/xOfmy2NrbpoROuBlirPkC+ogQLxyHybRri8IbDACoPsTlOblSCxcPP5A4aMaSHGhkGUHmAzQ/kViUohMlp3Zb5dFsYp8jnacSEHjoT6guyOOUzJl5cW4PnVi9XfSZcMZC5RccsZj3A1e9m86UaFAIVejwGUq2NamiET6gWhqHbQjCxm1/HQekqD1hJ94H0XTHNL+vOFJmc5Uzccr9A+rZniPO38k4uGNhnhJVO7QEasJf9/SJwYCqFhroqvLk+hGCJFynlML7Dv6ZHl6mfHOu7qWQys5dOGE1M45PuIVy8Ogb4c5QNE+/QC/sNhE9I4nWRT6sHdlD5g8uD6Hp1LWorSi1hcfhrdBJb2y4iEmW+uRvRTT4jT9aR60XiQHIGT4aC81YuqKssw1O1FWoROdBArhMDXiDd/UR32svEOCd7vbMfu9r7MBRLWFKudmwSLx3txVvfX2H452LPPM0H0bld/m1Swxywf13fXBrG511RtP8yhoO//mtJgc96ruPbnhg+/ekmOvtlX9LIqnFu2CgGPKz7hbFpBV1qYcv9KokVnl1ZbfWADeL24rFKklB8WaLHWeDntLMhhPadj1gCEaWYGiaqSjOr3wirYpnPgwCZRlPHJXT0sPKW+y2JA0n32NvgySou5X6vQ7mgmuXYrlyQ/a4LDHljLmguSOgP/38hNmUrVu4YFgMu674LSnw4PXALpyOjlqB4/PjHLZz7k7tv/o3qsuTAh+zIoWMOsugkrZdWSupdfjTWVyNY6mVm5/eIuP028+HMIJVLSZawSCS4GNVm4iMxQPb9M6QOIBX4vB48cd8Sll6PKr0JGjEtO9484GcRKKFyLw0aY2m+8M84UikuZi4vpGBsMdDKUmzYSjFXvmZFJc699rhKqgWkgILsDXdYnDa3XUDv3wyleEKjmx7e5uGGIIeDI1pGKI971cbjlVVwhoVQ5qhg6MolFKYtBiaOUnfccju+4v+I6tP9UmYljouF6MQUrsW4Tc/VZtGlzh7KAOvo3CJ9SZrIjTgjM6iGi4F9pyK4Fo3rhBSYaKHOEelmnwkPs3lZFXEG/40NtXh/Wz2W5a5keTFKL7Z0DeLA2atUztXrBDzCM6HcMxRyH0pFnkihribIg8dSND9Wg5VVARXPfIglUxgYmcRxHlK+7ruBK0MxQN7Ryh2H0gwDBDQi81gu+7n8hO6rvzuAhtASPFAdwL1BPyqtIhOjoRLjgZEJnL8ew8Aw55fPxyB9olz9rPCxPA3rjOi8mMikUpjke5b30q+KAlkhE1i5WtpMyMVkL5WruNvhakAaPDju4eQLu5qZvJqF53k1s4NGyGbfTEOa2BZ/OTXRwfZQOttzoaABaTAscj1fQ8oRLn09X0oKhsn09byTyn+j4iKu58B/pGHhLQAjt8EAAAAASUVORK5CYII=',
  botAvatarInitials: "Bot",
  // userAvatarImage: 'https://github.com/compulim.png?size=64',
  userAvatarInitials: "Me",
  // userAvatarInitials: 'WC',

  // hideSendBox: true, // Use hideSendBox instead of hideUploadButton
  paddingRegular: 10,
  paddingWide: 10 * 2,
  // Root
  //rootHeight: "100vh", // Set root height to 100% of viewport height
  rootWidth: "100%",
  rootZIndex: 10, // "z-index" for the root container of Web Chat. This will form a new stacking context so "z-index" used in children won't pollute.

  hideUploadButton: true,
  bubbleBackground: "#f2f2f2",
  bubbleFromUserBackground: "#727272",
  bubbleFromUserTextColor: "#fff", // White text color for user messages
  bubbleNubOffset: 10, // Set the offset of the bubble nub (adjust as needed)
  bubbleBorderRadius: 10, // Set the border radius for chat bubbles

  inputMiddleware:
    () =>
    ({}, next) =>
      next(), // Disable user text input

  bubbleFromUserBorderRadius: 20,
  suggestedActionStyle: "button", // This makes the suggested actions appear as buttons
  suggestedActionBackground: "#F3F2F1", // Background color of the suggested actions
  suggestedActionBorder: "1px solid #ccc", // Border style for suggested actions
  suggestedActionBorderRadius: 20, // Adjust the border radius for rounded corners
  suggestedActionBackgroundColorOnActive: "#EDEBE9",
  // Adjusting the position of suggested actions
  suggestedActionLayout: "stacked", // Ensure actions are stacked
  suggestedActionStackedLayoutSpacing: 10, // Adjust the spacing between stacked actions
};
