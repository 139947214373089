/* eslint-disable import/extensions */
import styled from 'styled-components';
import {
  StackItem, PrimaryButton, Stack, Pivot, mergeStyleSets
} from 'office-ui-fabric-react';
import welcomeImgPath from '../../asset/images/welcomeguest-desktop.png';
import { size } from '../../config/constants';
import theme from '../../asset/themes/defaultTheme';

const Container = styled(Stack.Item)`
    align-items: center;
    display: flex;
    height: auto;
    color: ${theme.brandWhite};

    background: url(${welcomeImgPath}) right no-repeat;
    background-size: cover;
    background-position: top right;
    width: 100vw;

    @media(max-width: ${size.laptop}) {
        flex-direction: column;
        background: none;
        height: auto;
        color: ${theme.brandBlack};
      };
`;

const LoaderStyle = {
  root: {
  },
  circle: {
    marginTop: '40vh'
  }
};

const WelcomeGuestContainer = styled(StackItem)<{isRTL: boolean; isDesktop: boolean}>`
    padding: ${(props) => (props.isRTL ? '2% 0% 2% 0%' : '2% 2% 2% 0%')};
    margin-right: ${(props) => (props.isRTL ? '12%' : '0%')};
    width: 50%;
    text-align: ${(props) => (props.isRTL ? 'right' : 'left')};
    margin-left: ${(props) => (props.isRTL ? 'auto' : '12%')}; 
    background-color: ${(props) => (props.isDesktop ? 'none' : `${theme.brandWhite}`)};
    
    @media(max-width: ${size.laptop}) {
        padding: 5% 5% 5% 5%;
        margin-left: ${(props) => (props.isRTL ? '2%' : '0%')};
        width: ${(props) => (props.isRTL ? '100%' : '90%')};
        text-align: ${(props) => (props.isRTL ? 'right' : 'left')};
      };
`;

const WelcomeImage = styled.img`
      width: 100vw;
      height: 20vh;
`;

const Title = styled.div`          
    font-size: ${theme.fontSizeXLarge};
    font-weight: ${theme.fontWeightMediumBold};
`;

const SubTitle = styled(StackItem)`
    margin-top: 3%;
    font-size: ${theme.fontSizeMedium};
    font-weight: ${theme.fontWeightBold};
    @media(max-width: ${size.mobileL}) {
       // margin-top: 3%;
    };
`;

const WelcomeMessage = styled.div`
    margin-top: 2%;
    font-size: ${theme.fontSizeXLSmall};
    @media(max-width: ${size.tabletS}) {
        width: 100%;
        margin-top: 4%;
    };
`;

const Button = styled(PrimaryButton)`
    font-size: ${theme.fontSizeLSmall};
    font-weight: ${theme.fontWeightBold};
    margin-top: 6%;
    @media(max-width: ${size.laptop}) {
        margin-top: 4%;   
    };
`;

const ButtonContainer = styled.div`
    margin-top: 2%;
`;

const ErrorTextContainer = styled(StackItem)`
    text-align: center;
    padding-top: 10%;
    font-size: ${theme.fontSizeLarge};
    font-weight: ${theme.fontWeightBold};

    @media(max-width: ${size.mobileS}) {
      padding-top: 40%;
    };
`;

const Text = styled.span`
    font-weight: ${theme.fontWeightMediumBold};
    font-size: ${theme.fontSizeMedium};
    @media(max-width: ${size.mobileS}) {
      font-size: ${theme.fontSizeLSmall};
    };
`;

const BannerButton = styled(PrimaryButton)`
  height: 50px;
  font-size: ${theme.fontSizeLSmall};
  margin-top: 20px;
`;

const StackStyle = styled(Stack)`
  width: 100vw;
`;

const PivotContainer = styled(Stack)`
  width: 40%;
  padding: 20px;
  @media(max-width: ${size.laptopL}) {
    width: 50%;
  };
  @media(max-width: ${size.laptop}) {
    width: 70%;
  };
  @media(max-width: ${size.tabletS}) {
    width: 80%;
  };
  @media(max-width: ${size.mobileXL}) {
    width: 100%;
  };
  margin: 0 auto;
  margin-top: 0px !important;
  padding-top: 0px;
  padding-bottom: 0.5rem;
  background-color: ${theme.brandBackgroundGray}; 

  @media(min-width: ${size.tabletS}) {
    position: sticky;
    top:0;
    z-index: 3000;
  };
`;

const VisitsContainer = styled(Stack)`
  width: 40%;
  padding: 20px;
  @media(max-width: ${size.laptopL}) {
    width: 50%;
  };

  @media(max-width: ${size.laptop}) {
    width: 70%;
  };

  @media(max-width: ${size.tabletS}) {
    width: 80%;
  };

  margin: 0 auto;
  padding-top: 0px;
  background-color: ${theme.brandBackgroundGray}; 
  @media(max-width: ${size.mobileXL}) {
    width: 100%;
    margin-top: 0 !important;
  };
  margin-bottom: 20px;
`;

const RTLResponsivePivot = styled(Pivot) <{ isRTL: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isRTL ? 'row-reverse' : 'row')};
  margin-bottom: 10px;
  width: 100%;
`;


const MobileStickyContainer = styled.div`
  align-self: flex-start;
  box-sizing: border-box;
  padding: 1.25rem;
  padding-top:0px;
  padding-bottom: 0.5rem;
  background-color: ${theme.brandBackgroundGray}; 
  @media(max-width: ${size.tabletS}) {
    top:0;
    z-index: 3000;
  };
`;

const ContentStyles = mergeStyleSets({
  scrollable: {
    overflowY: 'scroll',
    maxHeight: '80vh'
  }
});

export default {
  Container,
  WelcomeGuestContainer,
  WelcomeImage,
  Title,
  SubTitle,
  WelcomeMessage,
  Button,
  LoaderStyle,
  ButtonContainer,
  Text,
  BannerButton,
  ErrorTextContainer,
  StackStyle,
  PivotContainer,
  VisitsContainer,
  RTLResponsivePivot,
  MobileStickyContainer,
  ContentStyles
};
