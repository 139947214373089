/* eslint-disable import/extensions */
import styled from 'styled-components';
import {
  Stack, PrimaryButton, DefaultButton, StackItem, Pivot, IStackTokens, Icon, IStackStyles
} from 'office-ui-fabric-react';
import theme from '../../asset/themes/defaultTheme';


const WarningIcon = styled(Icon)`
  position: relative;
  padding-left: 1%;
  padding-top: 1%;
  padding-right: 1%;
  padding-bottom: 1%;
  color: #D73B02;
`;

const WarningStackItem = styled(StackItem)`
  padding: 1% 1% 1% 1%;
  margin-top: 2px;
`;

const warningText = styled.div`
  white-space: nowrap;
  padding-left: 3%;
  padding-right: 3%;
`;

const DismissIconStyle = {
  root: {
    border: 'none',
    minWidth: 'auto',
    paddingLeft: '1%',
    paddingTop: '1%',
    paddingRight: '1%',
    paddingBottom: '1%',
    backgroundColor: '#FED9CC'
  },
  icon: {
    fontSize: '12px'
  },
  flexContainer: {
    // width: '2px'
  }
};

const CancelIcon = {
  root: {
    border: 'none',
    minWidth: 'auto',
    paddingLeft: '2px',
    paddingRight: '2px',

  },
  icon: {
    fontSize: '12px'
  },
  flexContainer: {
    // width: '2px'
  }
};

const FailureMessage = styled(StackItem)<{isRTL: boolean}>`
  font-size: ${theme.fontSizeSmall};
  padding-left: auto;
  text-align: ${(props) => (props.isRTL ? 'right' : 'left')}; 
`;

const FailureMessageandIcon = (isLangRTL): IStackStyles => ({
  root: {
    width: '100%',
    left: isLangRTL ? 'auto' : '1%',
    right: isLangRTL ? '1%' : 'auto',
  }
});
const FailureMessageStack = styled(Stack)`
  background-color: ${theme.brandErrorBar};
  width: 100%;
`;
const ErrorBar = styled.div`
  z-index: 2;
  position: relative;
  width: 100%;
  box-shadow: ${theme.boxShadow};
  font-family: ${theme.baseFontFamily};
`;

const DismissButtonStackItem = styled(StackItem)<{isRTL: boolean}>`
  margin-left: ${(props) => (props.isRTL ? '0px' : 'auto !important')};
  margin-right: ${(props) => (props.isRTL ? 'auto !important' : '0px')};
`;

export default {
  ErrorBar,
  FailureMessage,
  FailureMessageandIcon,
  FailureMessageStack,
  CancelIcon,
  DismissIconStyle,
  WarningIcon,
  WarningStackItem,
  warningText,
  DismissButtonStackItem
};