/* eslint-disable max-len */
// ENGLISH
// Maintain this list in sorted order.
const fr = {
  PrivacyStatement2:
    "pour plus d’informations sur la manière dont la confidentialité des données personnelles des invités est gérée",
  PrivacyLink: "//privacy.microsoft.com/fr-fr/privacystatement",
  AddVehicle: "Ajouter un véhicule",
  AmericasLinkText: "Amérique",
  SetDefaultVeh: "Appliquer ce changement à mes visites à venir et futures",
  ApplyDefaultVehToFutureVisits:
    "Appliquer ce changement à mes visites à venir et futures",
  CampusParkingQuestion: "Venez-vous en voiture à cette réunion ?",
  VehicleInfoHeader:
    "Au moins un des lieux de réunion nécessite que vous enregistriez les détails relatifs à votre véhicule",
  Back: "Précédent",
  BadgeImage: "Image du badge",
  MeetingPurposeBusiness: "Fonction métier",
  Cancel: "Annuler",
  ChangeAvatar: "Modifier l’avatar",
  Chat: "Conversations (chat)",
  CheckInCompleted: "Enregistré",
  CheckIn: "Enregistrement",
  QRCodeCheckin: "Enregistrement avec un QR code dans le hall d’accueil.",
  CheckingInMeeting: "Vous vous enregistrez pour la réunion ?",
  ChooseAvatar: "Choisir votre avatar",
  Close: "Fermer",
  closeAvatarModalAriaLabel: "Fermer la fenêtre contextuelle modale",
  Company: "Entreprise (facultatif)",
  WebCheckin: "Enregistrez-vous en ligne ici",
  ConfirmVehUpdate: "Confirmer la mise à jour du véhicule",
  ErrorSavingData:
    "Impossible d’enregistrer les données en raison d’un problème. Veuillez réessayer.",
  Country: "Pays",
  COVIDBannerText:
    "Un contrôle COVID-19 quotidien est obligatoire avant d’entrer dans un bâtiment Microsoft.",
  DataProtection: "Protection des données",
  Directions: "Directions",
  Dismiss: "Masquer",
  DrivingMeeting: "Vous venez en voiture ?",
  Edit: "Modifier",
  EditPen: "Icône de stylet Modifier",
  CompanyPlaceholder: "Entrer le nom de l’entreprise",
  FirstNamePlaceholder: "Entrer le prénom",
  LastNamePlaceholder: "Entrer le nom",
  LicensePlatePlaceholder: "Entrer le numéro de permis",
  MakePlaceholder: "Entrer une marque",
  MiddleNamePlaceholder: "Entrer le nom de jeune fille",
  ModelPlaceholder: "Entrer un modèle",
  PhonePlaceholder: "Entrer un numéro de téléphone",
  MeetingPurposeEvent: "Événement",
  ExpandedInfoHeader: `Les exemples de cartes d’identité avec photo valide sont le permis de conduire, la carte nationale d'identité, le passeport, la carte de résident permanent. Toutefois, cette liste peut varier selon le pays.`,
  FirstName: "Prénom",
  LocatingLobbyQRcodeMessage2:
    "Pour toute assistance, veuillez vous adresser à l’hôte ou à l’hôtesse d’accueil",
  GlobalText: "Pour des visites en Europe et en Asie-Pacifique",
  AmericasText: "Pour des visites en Amérique du Nord et du Sud",
  GuestImageAriaLabel: "Icône du profil d’invité",
  GuestVehicleReg:
    "L’enregistrement des véhicules invités n’est pas requis pour ce site.",
  PreRegisterQRHeader: "Bonjour",
  HeyThere: "Bonjour,",
  BackToHome: "Accueil",
  individualsTab: "Individus",
  InfoImgPathAltText: "Chemin vers l’image Information",
  MeetingPurposeInterview: "Entretien",
  KnowLess: "en savoir moins",
  KnowMore: "en savoir plus",
  landmarksTab: "Points de repère",
  LangSelector: "Sélecteur de langue",
  LastName: "Nom",
  LicensePlate: "Plaque d’immatriculation",
  LobbyHost: "hôte ou hôtesse d’accueil.",
  Make: "Marque",
  MettingDetails: "Détails de la réunion",
  meetingDetails: "Détails de la réunion",
  MSLogo: "Logo Microsoft",
  PrivacyStatementLinkText: "Déclaration de confidentialité de Microsoft",
  MiddleName: "Nom de jeune fille (facultatif)",
  Model: "Modèle",
  Next: "Suivant",
  No: "Non",
  NoPastVisitsErrorMessage: "Aucune visite passée",
  NoVehicle: "Vous n’avez enregistré aucun véhicule",
  NoVehicleSaved: "Vous n’avez enregistré aucun véhicule",
  NoVehicleRequiredInfo:
    "Aucun des lieux de réunion ne nécessite que vous enregistriez les détails relatifs à votre véhicule",
  Okay: "Oui",
  NoVisitsWelcomeMessage:
    "Une fois notre invitation reçue, elle s’affiche ici.",
  OrText: "ou",
  GlobalLinkText: "Autres régions du monde",
  ParkingInformation: "Information sur le parking",
  PreviousVisits: "Visites passées",
  MeetingPurposePersonal: "Personnalisation",
  GuestFormHeader: "Informations personnelles",
  Phone: "Numéro de téléphone (facultatif)",
  InfoHeader:
    "Veuillez apporter une pièce d’identité avec photo délivrée par le gouvernement le jour de votre venue.",
  ContactLobbyHostToCollectGuestBadge:
    "Rapprochez-vous de l’hôte ou l’hôtesse d’accueil pour récupérer votre badge invité",
  CollectGuestBadge:
    "Rapprochez-vous de l’hôte ou l’hôtesse d’accueil pour récupérer votre badge invité",
  SelectRegion:
    "Veuillez sélectionner une région pour commencer à gérer vos visites",
  ShowPhotoID:
    "Veuillez présenter une pièce d’identité avec photo délivrée par le gouvernement à l’hôte ou l’hôtesse d’accueil pour récupérer votre badge invité",
  DisplayNameText: "Nom préféré",
  Prefix: "Préfixe",
  PreRegisterButtonText: "Pré-enregistrement",
  PrivacyPolicy: "Déclaration de confidentialité",
  Processing: "Traitement...",
  VehFormSubHeader:
    "Fournir des détails concernant votre véhicule pour cette réunion.",
  LobbyQRErrorMessage:
    "Le QR code ne correspond pas aux détails de la réunion. Veuillez réessayer.",
  RedirectMessage: "Redirection vers la page d’accueil",
  ValidateInfoSubText:
    "Vérifiez/mettez à jour vos informations personnelles avant de vous enregistrer. Vous pouvez également prévisualiser votre badge invité et apporter des modifications en fonction de vos préférences",
  Save: "Enregistrer",
  LobbyQRScanningMessage: "Scanner le QR code de l’accueil",
  Searching: "Recherche...",
  Seconds: "Sec",
  selectAvatar: "Sélectionner un avatar",
  CountryPlaceholder: "Sélectionner un pays",
  StatePlaceholder: "Sélectionner un département/une région",
  ShowAvatar: "Afficher l’image/l’avatar de l’invité",
  StateLabel: "Département/région",
  TypeLabel: "Type de véhicule",
  TypePlaceholder: "Sélectionnez le type de véhicule",
  Submit: "Soumettre",
  COVIDBannerLinkText: "Faites-le ici",
  PrivacyStatement1:
    "Les données collectées par Microsoft, lorsque les invités fournissent leurs informations pour l’enregistrement, nous permettent de créer un badge d’entrée visiteur afin de sécuriser nos installations. Lire la",
  PrivacyStatement:
    "Les données collectées par Microsoft, lorsque les invités fournissent leurs informations pour l’enregistrement, nous permettent de créer un badge d’entrée visiteur afin de sécuriser nos installations. Pour plus d’informations sur la manière dont la confidentialité des données personnelles des invités est gérée, lire la Déclaration de confidentialité de Microsoft.",
  LocatingLobbyQRcodeMessage:
    "Le QR code pour l’enregistrement se trouve au niveau de la réception à l’accueil.",
  AppErrorTitle:
    "Il semble y avoir un problème. Veuillez réessayer ultérieurement.",
  PreRegisterQRSubject:
    "Il semble y avoir un problème. Veuillez réessayer ultérieurement.",
  VehUpdateDescription:
    "Cette modification s’appliquera à votre/vos 0 réunion(s) à venir et sera utilisée par défaut pour vos futures visites.",
  FieldInvalid: "Ce champ n’est pas valide",
  FieldRequired: "Ce champ est requis",
  UpcomingVisits: "Visites à venir",
  UpdateRegisterButtonText: "Mettre à jour les informations",
  UpdateDisplayNameText: "Mettre à jour le nom préféré",
  UpdateVehicle: "Mettre à jour le véhicule",
  QRCodeInfo: "Utiliser ce QR code pour vous enregistrer à la réception",
  MeetingPurposeUserResearch: "Recherche utilisateur",
  ValidateYourInfo: "Valider vos informations",
  VehicleInfoMessage:
    "Les détails relatifs à votre véhicule sont requis pour les réunions à",
  VehFormHeader: "Carte grise",
  WarningImgPathAltText: "Chemin vers l’image Avertissement",
  ProfileUpdateMessage: "Nous avons mis à jour votre profil.",
  UpdateFailureMessage:
    "Une erreur est survenue lors de la mise à jour des informations. Veuillez réessayer ultérieurement.",
  GuestFormSubHeader:
    "Certaines données seront utilisées pour l’impression de votre badge invité au moment de votre arrivée.",
  HasOnlyUpcomingVisitsTitle:
    "Nous sommes ravis de votre visite chez Microsoft.",
  HasUpcomingAndPastVisitsTitle:
    "Vous voilà de retour, bienvenue ! Nous sommes ravis de votre visite chez Microsoft.",
  NoUpcomingVisitsTitle:
    "Vous voilà de retour, bienvenue ! Aucune visite à venir planifiée chez Microsoft.",
  WelcomeText: "Bienvenue sur le portail des invités Microsoft",
  LocatingLobbyQRcode: "Où trouver le QR code de l’accueil ?",
  WorldLogoImgPathAltText: "Chemin vers l’image Monde",
  Yes: "Oui",
  Continue: "Oui, continuer",
  ProfileUpdateSubMessage: "Vous êtes prêt !",
  CheckedIn: "Vous êtes enregistré",
  NoUpcomingVisitsWelcomeMessage:
    "Mettez à jour vos informations de contact pour vos futures visites. Sinon, vous pouvez attendre de tout compléter à votre arrivée à l’accueil.",
  WelcomeMessage:
    "Vous pouvez accélérer le processus d’enregistrement de vos visites à venir en vous pré-enregistrant avant votre arrivée. Sinon, vous pouvez attendre de tout compléter à votre arrivée à l’accueil.",
  NoVisitsTitle: "Aucune visite à venir planifiée chez Microsoft.",
  VehicleInfoTitle:
    "Les lieux de réunion nécessitant les détails relatifs à votre véhicule se trouvent ci-dessous :",
  logOutText: "Déconnexion",
};
export default fr;
