/* eslint-disable max-len */
// SPANISH
// Maintain this list in sorted order.

const es = {
  AppErrorTitle:
    "Parece que hay algún problema. Por favor, intente nuevamente más tarde.",
  Back: "Atrás",
  BackToHome: "Hogar",
  CampusParkingQuestion: "¿Conducirás a esta reunión?",
  Cancel: "Cancelar",
  ChangeAvatar: "Cambiar el avatar",
  Chat: "Charla",
  CheckedIn: "Usted está registrado",
  CheckIn: "Registrarse",
  CheckInCompleted: "Registrado",
  Country: "País",
  Processing: "Processing...",
  ChooseAvatar: "elegir el avatar",
  CollectGuestBadge:
    "Favor contacte al recepcionista para recoger su credencial de visitante",
  Company: "Empresa (Opcional)",
  CompanyPlaceholder: "Introduzca el nombre de la empresa",
  CompanyToolTipMessage:
    "Empresa - (50 caracteres) Se permiten caracteres alfanuméricos y caracteres especiales limitados",
  DataProtection: "Protección de datos",
  Directions: "Instrucciones",
  // DrivingLicense: ' Licencia de conducción',
  ErrorSavingData:
    "No se pudo guardar la información debido a algún problema. Favor inténtelo de nuevo.",
  ExpandedInfoHeader:
    "A continuación se muestran algunos ejemplos de tarjetas de identificación con foto, sin embargo, esta lista puede variar según el país",
  FieldInvalid: "Este campo no es valido",
  FieldRequired: "este campo es requerido",
  FirstName: "Nombre",
  FirstNamePlaceholder: "Ingresar el nombre",
  FirstNameToolTipMessage:
    "Nombre (20 caracteres) - No se permiten caracteres especiales ni números.",
  GuestFormHeader: "Datos personales",
  GuestFormSubHeader:
    "Utilizaremos una parte de esta información para imprimir su credencial de visitante a su llegada.",
  GuestImageAriaLabel: "Icono de perfil de invitado",
  DisplayNameText: "Nombre preferido",
  UpdateDisplayNameText: "Actualizar nombre preferido",
  GuestFormSubTitle:
    "Solo utilizaremos esta información para su visita. La misma será eliminada en conformidad con nuestra política de privacidad.",
  HasOnlyUpcomingVisitsTitle:
    "Estamos entusiasmados con su visita a Microsoft.",
  HasUpcomingAndPastVisitsTitle:
    "Bienvenido de nuevo. Estamos entusiasmados con su visita a Microsoft.",
  InfoHeader:
    "Por favor, lleve una tarjeta de identificación con foto emitida por el gobierno el día de este viaje.",
  Invalid: "No válido",
  KnowLess: "Ver menos",
  KnowMore: "Ver más",
  LastName: "Apellidos",
  LastNamePlaceholder: "Ingresar los apellidos",
  LastNameTootlTipMessage:
    "Apellidos - (20 caracteres) - No se permiten caracteres especiales ni números.",
  LicensePlate: "Matrícula",
  LicensePlatePlaceholder: "Introduzca el número de licencia",
  LicenseToolTipMessage:
    "Matrícula (20 caracteres) Se permiten caracteres alfanuméricos y espacio",
  LobbyHost: "recepcionista.",
  LobbyQRErrorMessage:
    "El código QR no coincide con los detalles de la reunión. Favor inténtelo de nuevo.",
  LobbyQRScanningMessage: "Escanear el código QR del Lobby",
  LocatingLobbyQRcode: "¿Dónde encontrar el código QR del Lobby?",
  LocatingLobbyQRcodeMessage2:
    "Para cualquier ayuda que necesite, favor contacte a recepcionista.",
  LocatingLobbyQRcodeMessage:
    "El código QR para el check-in se encuentra en la recepción.",
  Logout: "Cerrar sesión",
  Make: "Marca",
  MakePlaceholder: "Ingrese a make",
  meetingDetails: "Meeting Details", //
  MeetingHistory: "Acercamiento a la historia",
  MeetingPurposeBusiness: "Negocios",
  MeetingPurposeEvent: "Evento",
  MeetingPurposeInterview: "Entrevista",
  MeetingPurposePersonal: "Personal",
  MeetingPurposeUserResearch: "Investigación del Usuario",
  MiddleName: "Segundo nombre (Opcional)",
  MiddleNamePlaceholder: "Introduzca el segundo nombre",
  // MilitaryId: ' Identificación militar',
  Model: "Modelo",
  ModelPlaceholder: "Introducir modelo",
  Next: "Siguiente",
  No: "No",
  NoPastVisitsErrorMessage: "Sin visitas anteriores",
  NoUpcomingVisitsTitle:
    "Bienvenido de nuevo. No tiene planeada una próxima visita a Microsoft.",
  NoUpcomingVisitsWelcomeMessage:
    "Usted puede mantener su información de contacto actualizada para sus próximas visitas. Usted también puede esperar a finalizar el proceso cuando llegue al lobby.",
  NoVehicleRequiredInfo:
    "Ninguno de los lugares de reunión necesita que registre la información detallada de su vehículo.",
  NoVisitsTitle: "¡No hay visitas próximas planeadas para usted en Microsoft!",
  NoVisitsWelcomeMessage:
    "Una vez que usted sea invitado por nosotros, esta información aparecerá aquí.",
  Okay: "Bueno",
  ParkingInformation: "Información del estacionamiento",
  // ParmanentResidentCard: ' Tarjeta de residente permanente',
  // Passport: ' Pasaporte',
  Phone: "Número telefónico (Opcional)",
  PhonePlaceholder: "Introduzca el número telefónico",
  PhoneToolTipMessage:
    "Teléfono - (20 caracteres) Puede comenzar con +,/,( o un número. Se permiten caracteres especiales limitados.",
  Prefix: "Prefijo",
  PreRegisterButtonText: "PREINSCRIPCIÓN",
  PreRegisterQRSubject: "invitado",
  PreRegisterSubtitle:
    "Preinscríbase ahora para acelerar su proceso de registro",
  PreRegisterQRHeader: "Hola",
  PreviousVisits: "Visitas anteriores",
  PrivacyLink: "https://privacy.microsoft.com/es-mx/privacystatement",
  PrivacyPolicy: "Declaración de privacidad",
  PrivacyStatement:
    "Los datos que Microsoft recoge cuando los clientes proporcionan su información para registrarse, nos permiten crear una credencial de entrada para el visitante con el objetivo de asegurar nuestras instalaciones. Lea ------ Declaración de Privacidad para información adicional sobre cómo se maneja la privacidad de los datos personales de los clientes.",
  ProfileUpdateMessage: "Se ha actualizado su perfil.",
  ProfileUpdateSubMessage: "Ya tiene todo listo!",
  QRCodeInfo: "Use este código QR para registrarse en la recepción",
  RedirectMessage: "Redirigiendo a la página de inicio en ",
  Save: "Guardar",
  Searching: "Buscando...",
  Seconds: " segundos",
  ShowPhotoID:
    "Favor muestre al recepcionista una tarjeta de identificación con foto emitida por el gobierno para recoger su credencial de visitante",
  ContactLobbyHostToCollectGuestBadge:
    "Póngase en contacto con el anfitrión del vestíbulo para recoger su credencial de huésped",
  StateLabel: "estado",
  TypeLabel: "Tipo de vehículo",
  StatePlaceholder: "Seleccione estado",
  TypePlaceholder: "Seleccione el tipo de vehículo",
  CountryPlaceholder: "Seleccionar país",
  Submit: "Enviar",
  UpcomingVisits: "Próximas visitas",
  UpdateFailureMessage:
    "Nos encontramos con un problema al actualizar la información. Por favor, inténtelo de nuevo más tarde.",
  UpdateRegisterButtonText: "ACTUALIZAR INFORMACIÓN",
  ValidateInfoSubText:
    "Revise/actualice sus datos personales antes del check-in. Usted también puede realizar una vista previa de su credencial de visitante y hacer cambios según su preferencia",
  ValidateYourInfo: "Validar su Información",
  VehicleInfoHeader:
    "Al menos uno de los lugares de reunión necesita que registre los detalles de su vehículo.",
  VehicleInfoMessage:
    "Se necesitan los datos del vehículo para las reuniones en",
  VehicleInfoTitle:
    "Los lugares de reunión de abajo necesitan información detallada de su vehículo",
  WelcomeMessage:
    "Usted puede agilizar el proceso de check-in para sus próximas visitas por medio de un pre-registro antes de su llegada. Por otro lado, usted puede esperar a finalizar el proceso cuando llegue al lobby.",
  Yes: "Sí",
  COVIDBannerText:
    "El registro diario COVID-19 es obligatorio antes de ingresar a un edificio de Microsoft. ",
  COVIDBannerLinkText: "Tómalo aquí",
  PrivacyStatement1:
    "The data Microsoft collects when guests provide their information for registration, enables us to create a visitor entry badge for the purpose of securing our facilities. Read",
  PrivacyStatementLinkText: "Microsoft Privacy Statement",
  PrivacyStatement2:
    "for additional information around how the privacy of guest's personal data is handled.",
  WorldLogoImgPathAltText: "Ruta de la imagen del logotipo del mundo",
  InfoImgPathAltText: "Ruta de la imagen de información",
  WarningImgPathAltText: "Ruta de la imagen de advertencia",
  Close: "Cerca",
  ShowAvatar: "Mostrar avatar/imagen de invitado",
  MSLogo: "Logotipo de Microsoft",
  EditPen: "Editar icono de lápiz",
  LangSelector: "Selector de idiomas",
  Edit: "Edit",
  NoVehicleSaved: "No Vehicle saved",
  Dismiss: "Dismiss",
  BadgeImage: "Badge Image",
  SetDefaultVeh: "Aplique este cambio a mis próximas y futuras visitas",
  WebCheckin: "Check-in web completo desde aquí",
  QRCodeCheckin: "Check-in con código QR en el vestíbulo",
  OrText: "O",
  Continue: "Sí, continúe",
  DrivingMeeting: "Conducir para la reunión",
  CheckingInMeeting: "Check-in para la reunión",
  ApplyDefaultVehToFutureVisits:
    "Aplique este cambio a mis próximas y futuras visitas",
  VehFormHeader: "Matrículas",
  VehFormSubHeader: "Proporcione detalles sobre su vehículo para esta reunión.",
  MettingDetails: "Detalles de la reunión",
  GuestVehicleReg:
    "No se requiere el registro del vehículo de invitado para esta ubicación",
  UpdateVehicle: "Actualizar vehículo",
  NoVehicle: "Sin vehículo guardado",
  AddVehicle: "Añadir un vehículo",
  ConfirmVehUpdate: "Confirmar actualización del vehículo",
  VehUpdateDescription:
    "Este cambio se aplicará a sus 0 próximas reuniones y se utilizará como valor predeterminado para sus próximas visitas.",

  AmericasLinkText: "Américas",
  AmericasText: "Para visitas en América del Norte y del Sur",
  GlobalLinkText: "Otras regiones globales",
  GlobalText: "Para visitas en Europa y Asia-Pacífico",
  HeyThere: "Hola",
  WelcomeText: "Bienvenido al portal de invitados de Microsoft",
  SelectRegion: "Seleccione una región para comenzar a administrar sus visitas",
  individualsTab: "Individuos",
  landmarksTab: "Monumentos",
  closeAvatarModalAriaLabel: "Cerrar modal emergente",
  selectAvatar: "Seleccionar avatar",
  logOutText: "Cerrar sesión",
};

export default es;
