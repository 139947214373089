/**
 * Action types for guest state
 */
export const VALIDATE_QRCODE = 'VALIDATE_QRCODE';
export const FETCH_VALIDATE_QR_SUCCESS = 'FETCH_VALIDATE_QR_SUCCESS';
export const UPDATE_VALIDATE_QR_STATUS = 'UPDATE_VALIDATE_QR_STATUS';
export const FETCH_GUEST_VISIT_DETAILS = 'FETCH_GUEST_VISIT_DETAILS';
export const FETCH_GUEST_VISIT_DETAILS_SUCCESS = 'FETCH_GUEST_VISIT_DETAILS_SUCCESS';
export const UPDATE_GUEST_DETAILS = 'UPDATE_GUEST_DETAILS';
export const UPDATE_GUEST_VISIT_DETAILS_SUCCESS = 'UPDATE_GUEST_VISIT_DETAILS_SUCCESS';
export const FETCH_BUILDING_CONFIG = 'FETCH_BUILDING_CONFIG';
export const FETCH_BUILDING_CONFIG_SUCCESS = 'FETCH_BUILDING_CONFIG_SUCCESS';
export const HIDE_CALLOUT = 'HIDE_CALLOUT';
export const SHOW_SUCCESS_CALLOUT = 'SHOW_SUCCESS_CALLOUT';
export const SHOW_ERROR_CALLOUT = 'SHOW_ERROR_CALLOUT';
export const FETCH_HOST_DETAILS = 'FETCH_HOST_DETAILS';
export const FETCH_HOST_DETAILS_SUCCESS = 'FETCH_HOST_DETAILS_SUCCESS';
export const VISIT_CHECKIN = 'VISIT_CHECKIN';
export const VISIT_CHECKIN_SUCCESS = 'VISIT_CHECKIN_SUCCESS';
export const RESET_CHECKIN_STATUS = 'RESET_CHECKIN_STATUS';
export const FETCH_VISIT_BUILDING = 'FETCH_VISIT_BUILDING';
