/* eslint-disable import/extensions */
import styled from 'styled-components';
import { Stack } from 'office-ui-fabric-react';
import theme from '../../asset/themes/defaultTheme';
import { AvatarColorMap } from '../../config/constants';

const CardSection = styled(Stack)<{ highlighted }>`
    padding: ${(props) => (props.highlighted ? '2%' : '1%')};
    padding-top: ${(props) => (props.highlighted ? '2%' : '5%')};
    padding-bottom: ${(props) => (props.highlighted ? '2%' : '0%')};
    background-color: ${(props) => (props.highlighted ? theme.brandBackgroundGray : theme.brandWhite)};
    font-family: ${theme.baseFontFamily};
    font-size: ${(props) => (props.highlighted ? theme.fontSizeXLSmall : theme.fontSizeMedium)};
`;

const CardSectionText = styled.div<{ isRTL; icon }>`
    text-align: ${(props) => (props.isRTL ? 'right' : 'left')};
    width: ${(props) => (props.icon ? `calc(100% - ${theme.iconSizeMedium})` : '100%')};
    max-height: 20%;
`;

const CardSectionImage = styled(Stack)`
    text-align: right;
`;

const CardAvatar = styled.img`
    height: 56px;
    border-radius: 50%;
`;

const CardSectionContent = styled.div`
    color: ${theme.brandBlack};
    width: 100%;
    overflow: hidden;
`;

const CardSectionContentBold = styled.div`
    font-size: ${theme.fontSizeXLSmall};
    color: ${theme.brandBlack};
    font-weight: ${theme.fontWeightMediumBold};
`;

const CardSectionIcon = styled.img`
    width: ${theme.iconSizeMedium};
    height: auto;
    padding-top: 3%;
`;

const Initials = styled.span`
  position: relative;
  top: 12.5px; /* 25% of parent */
  font-size: 20px; /* 50% of parent */
  line-height: 25px; /* 50% of parent */
  color: #fff;
  font-weight: ${theme.fontWeightLight};
`;

const sectionLeft = styled(Stack)`
    width: 70%;
`;

const Notification = styled.div<{ isRTL }>`
    color: ${theme.BrandDarkBlue};
    line-height: ${theme.fontSizeMLarge};
    padding-top: ${theme.fontSizeXSmall};
    white-space: nowrap;
    text-align: ${(props) => (props.isRTL ? 'right' : 'left')};
`;

const NotificationText = styled.div<{ isRTL }>`
    float: ${(props) => (props.isRTL ? 'right' : 'left')};
`;

const AvatarCircle = styled.div<{ num: number }>`
  width: 50px;
  height: 50px;
  background-color: ${(props) => AvatarColorMap[props.num % 10]};
  text-align: center;
  border-radius: 50%;
`;

export default {
  CardSection,
  CardSectionText,
  CardSectionImage,
  CardSectionContent,
  CardSectionContentBold,
  CardAvatar,
  sectionLeft,
  Initials,
  AvatarCircle,
  Notification,
  NotificationText,
  CardSectionIcon
};
