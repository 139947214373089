// JAPANESE
// Maintain this list in sorted order.

const de = {
  AddVehicle: "車両の追加",
  AmericasLinkText: "アメリカ",
  AmericasText: "南北アメリカへの訪問",
  AppErrorTitle:
    "エラーが発生しています。申し訳ありませんが、もう一度やり直してください。",
  ApplyDefaultVehToFutureVisits: "会議のチェックイン",
  Back: "戻る",
  BackToHome: "ホームへ戻る",
  BadgeImage: "入館証のイメージ",
  COVIDBannerLinkText: "回答入力はこちら",
  COVIDBannerText:
    "新型コロナウィル対策として、訪問日毎に必ず質問表へ回答いただく必要がございます。",
  CampusParkingQuestion: "お車での来館",
  Cancel: "キャンセル",
  ChangeAvatar: "プロフィール画像を変更する",
  Chat: "チャット",
  CheckIn: "ウェブチェックイン",
  CheckInCompleted: "チェックイン完了",
  CheckedIn: "チェックイン済",
  CheckingInMeeting: "チェックインするには？",
  ChooseAvatar: "プロフィール画像を選択する",
  Close: "閉じる",
  CollectGuestBadge: "入館証を回収",
  Company: "会社",
  Country: "国",
  CompanyPlaceholder: "会社名を入力してください",
  CompanyToolTipMessage:
    "会社-（50文字）ー英数字㝨陝定㝕れ㝟特殊文字を使用㝧㝝㝾㝙 ",
  ConfirmVehUpdate: "車両の更新を確認",
  Continue: "はい、続行します",
  DataProtection: "データ保護",
  Directions: "目的地",
  Dismiss: "取り消す",
  DrivingMeeting: "お車での来館",
  Edit: "マイクロソフト ロゴ",
  EditPen: "閉じる",
  ErrorSavingData:
    "問題が発生し、データ保存できませんでした。もう一度やり直してください。",
  ExpandedInfoHeader:
    "有効な身分証明書の一例。\n例）運転免許証、パスポート、在留カード",
  FieldInvalid: "無効な項目",
  FieldRequired: "必須項目",
  FirstName: "名",
  FirstNamePlaceholder: "お名前を入力してください",
  FirstNameToolTipMessage: " 坝（20文字）ー 特殊文字㝨数字㝯使用㝧㝝㝾㝛ん。 ",
  GlobalLinkText: "その他の世界の地域",
  GlobalText: "ヨーロッパおよびアジア太平洋地域への訪問",
  GuestFormHeader: "個人情報詳細",
  GuestFormSubHeader:
    "ご入力いただいたデータはご来館時発行の入館証に利用させていただきます。",
  GuestImageAriaLabel: "ゲスト プロファイル アイコン",
  DisplayNameText: "優先名",
  UpdateDisplayNameText: "優先名を更新",
  GuestFormSubTitle:
    "弊社㝯㝊客様㝌訪啝㝙る㝓㝨㝮㝿,㝓㝮データを使用㝗㝾㝙。当社㝮プライポシーポリシー㝫従㝄,削除㝗㝾㝙。 ",
  GuestVehicleReg: "当館は来客用駐車場の用意がございません",
  HasOnlyUpcomingVisitsTitle: "Microsoftへの訪問を心待ちにしております。",
  HasUpcomingAndPastVisitsTitle:
    "おかえりなさいませ。Microsoftへの再訪問を心待ちにしております。",
  HeyThere: "ようこそ",
  InfoHeader: "ご旅行当日、政府発行の写真付き身分証明書をご持参ください。",
  InfoImgPathAltText: "情報イメージ㝮パス",
  WarningImgPathAltText: "警告イメージのパス",
  Invalid: "無効",
  KnowLess: "よくわかりません",
  KnowMore: "よく知っています",
  LangSelector: "プロフィール画像を表示",
  LastName: "姓",
  LastNamePlaceholder: "苗字を入力してください",
  LastNameTootlTipMessage: "姓-（20文字）ー 特殊文字㝨数字㝯使用㝧㝝㝾㝛ん。 ",
  LicensePlate: "車両番号",
  LicensePlatePlaceholder: "ライセンス番坷を入力㝗㝾㝙",
  LicenseToolTipMessage:
    "ライセンスプレート（20文字）ー英数字㝨スペースを使用㝧㝝㝾㝙 ",
  LobbyHost: "受付担当者",
  LobbyQRErrorMessage: "QRコードが会議詳細と合致しません。再度お試しください。",
  LobbyQRScanningMessage: "QRコードをスキャンしてください",
  LocatingLobbyQRcode: "チェックイン用QRコードは当カウンター上にございます。",
  LocatingLobbyQRcodeMessage: "その他、お困りの際は受付担当者まで",
  LocatingLobbyQRcodeMessage2: "お声がけください",
  Logout: "ログアウト",
  MSLogo: "マイクロソフト ロゴ",
  Make: "メーカー",
  MakePlaceholder: "メイクを入力",
  MeetingHistory: "ミーティング履歴",
  MeetingPurposeBusiness: "ビジネス",
  MeetingPurposeEvent: "イベント",
  MeetingPurposeInterview: "面接",
  MeetingPurposePersonal: "個人",
  MeetingPurposeUserResearch: "調査",
  MettingDetails: "会議詳細",
  MiddleName: "ミドルネーム",
  MiddleNamePlaceholder: "ミドル ネームを入力してください",
  Model: "車種",
  ModelPlaceholder: "モデルを入力",
  Next: "次へ",
  No: "いいえ",
  NoPastVisitsErrorMessage: "過去の訪問履歴はありません",
  NoUpcomingVisitsTitle:
    "おかえりなさいませ。お客様は今後Microsoftへの訪問予定はありません。",
  NoUpcomingVisitsWelcomeMessage:
    "次回以降の訪問のために、お客様の連絡先を登録できます",
  NoVehicle: "車両が保存されていません",
  NoVehicleRequiredInfo: "車輌登録登録の必要はありません",
  NoVehicleSaved: "No Vehicle saved",
  NoVisitsTitle: "現在、お客様のMicrosoftへの訪問予定はありません。",
  NoVisitsWelcomeMessage:
    "当社からの招待メールが届いた際はこちらにその内容が表示されます。",
  Okay: "閉じる",
  OrText: "または",
  ParkingInformation: "駝車場情報",
  Phone: "電話番号",
  PhonePlaceholder: "電話番号を入力ください",
  PhoneToolTipMessage:
    " 電話番坷-（20文字）ー +,/,（㝾㝟㝯数字㝧始ゝる㝓㝨㝌㝧㝝㝾㝙。陝定㝕れ㝟特殊文字㝯使用㝧㝝㝾㝙。 ",
  Prefix: "接頭辞",
  PreRegisterButtonText: "事前登録",
  PreRegisterQRHeader: "ようこそ",
  PreRegisterQRSubject: "ようこそ",
  PreviousVisits: "過去の訪問",
  PrivacyLink: "https://privacy.microsoft.com/ja-jp/privacystatement",
  PrivacyPolicy: "個人情報保護方針",
  PrivacyStatement:
    "当施設の安全な運営のため、お客様にご登録いただきました情報は\v入館証作成等に利用させていただきます。個人情報の取り扱いに関して\vさらに詳しく確認されたい方は、マイクロソフト個人情報保護方針を\vお読みください。",
  PrivacyStatement1:
    "当施設の安全な運営のため、お客様にご登録いただきました情報は\n入館証作成等に利用させていただきます。個人情報の取り扱いに関して\nさらに詳しく確認されたい方は、",
  PrivacyStatement2: "をお読みください。",
  PrivacyStatementLinkText: "マイクロソフト個人情報保護方針",
  Processing: "進行中…",
  ProfileUpdateMessage: "プロフィールを更新",
  ProfileUpdateSubMessage: "お客様は設定完了しています",
  QRCodeCheckin: "QRコードで\nチェックイン",
  QRCodeInfo: "受付カウンターでのチェックイン時、このQRコードをお使いください",
  RedirectMessage: "ホームページへ再接続しています",
  Save: "保存",
  Searching: "スキャン中…",
  Seconds: "数秒お待ちください",
  SelectRegion: "訪問する地域を選択してください",
  SetDefaultVeh: "この変更を今後の訪問および今後の訪問に適用する",
  ShowAvatar: "プロフィール画像を表示",
  ShowPhotoID: "身分証明書を提示",
  ContactLobbyHostToCollectGuestBadge:
    "ロビーのホストに連絡してゲストバッジを受け取ってください",
  StateLabel: "状態",
  TypeLabel: "車種",
  StatePlaceholder: "州を靸択",
  TypePlaceholder: "車種を選択",
  CountryPlaceholder: "国の選択",
  Submit: "提出",
  UpcomingVisits: "今後の訪問予定",
  UpdateFailureMessage:
    "情報登録中に問題が発生しました。もう一度やり直してください。",
  UpdateRegisterButtonText: "情報更新",
  UpdateVehicle: "車両の更新",
  ValidateInfoSubText:
    "簡易チェックインのためにお客様の登録情報を確認または更新ください。事前にお客様の入館証を確認およびお好みで変更できます。",
  ValidateYourInfo: "ご登録情報をご確認ください",
  VehFormHeader: "車両登録",
  VehFormSubHeader: "この会議の車両に関する詳細を入力します。",
  VehUpdateDescription:
    "この変更は、0回の会議に適用され、今後の訪問のデフォルトとして使用されます",
  VehicleInfoHeader:
    "少㝪㝝㝨も１㝤㝮ミーティング場所㝫㝊車㝮情報を登録㝙る必覝㝌㝂り㝾㝙。",
  VehicleInfoMessage: "「㝧㝮会議㝫必覝㝪車両㝮詳細〝",
  VehicleInfoTitle:
    "以下㝮ミーティング場所㝫㝊車㝮情報を登録㝙る必覝㝌㝂り㝾㝙",
  WebCheckin: "ウェブから\nチェックイン",
  WelcomeMessage:
    "お客様情報を事前登録いただくことにより、受付ロビー到着後の手続き\nを簡略化することができます。未登録の場合は、ロビーで情報入力いた\nだきます。",
  WelcomeText: "マイクロソフトゲストポータルへようこそ",
  WorldLogoImgPathAltText: "ワールド ロゴ イメージ パス",
  Yes: "はい",
  closeAvatarModalAriaLabel: "プロフィール画像選択画面を閉じる",
  individualsTab: "人物画像",
  landmarksTab: "風景画像",
  meetingDetails: "会議詳細",
  selectAvatar: "プロフィール画像を選択",
  logOutText: "ログアウト",
};

export default de;
