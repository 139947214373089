/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/extensions */
import React from 'react';
import { matchPath } from 'react-router-dom';
import WelcomeGuest from '../views/WelcomeGuest/WelcomeGuest';
import Checkin from '../views/Checkin/Checkin';
/**
 * RouteType interface
 */
export interface RouteType {
  id: string;
  title: string;
  path: string;
  component?: React.FC;
  subRoutes?: RouteType[];
  default?: boolean;
  props: {
  };
}

const routes: RouteType = {
  id: 'root',
  title: 'Home',
  path: '/:region_name/',
  props: {},
  subRoutes: [
    {
      id: 'guest',
      title: 'Guest',
      path: 'guest',
      default: true,
      props: {},
      subRoutes: [{
        id: 'guest_qr',
        title: 'Guest QR',
        path: '/qr',
        default: true,
        component: WelcomeGuest,
        props: {}
      },
      {
        id: 'checkin',
        title: 'Checkin',
        path: '/checkin',
        component: Checkin,
        props: {}
      }]
    }
  ] as RouteType[]
};

/**
 * 404 route path
 */
export const notFoundRoute: RouteType = {
  id: '404',
  title: 'Not Found',
  path: '/not-found',
  props: {},
  subRoutes: []
};

/**
 * Returns the current region from the path url
 */
export const getCurrentRegion = (): string => {
  const { params }: any = matchPath(window.location.pathname, {
    path: routes.path
  }) ? matchPath(window.location.pathname, {
      path: routes.path
    }) : { params: { region_name: '' } };
  return params.region_name;
};

/**
 * Returns the url to redirect to based on page type
 * @param page Page to redirect to
 * @param visitId The Id of the visit
 */
export const getRoute = (page: string, visitId = ''): string => {
  const regionName = getCurrentRegion();
  let route;
  switch (page) {
    case 'checkin':
      route = `/${regionName}/guest/checkin?id=${visitId}`;
      break;
    case 'home':
      route = `/${regionName}/guest`;
      break;
    default:
      route = `/${regionName}/guest`;
      break;
  }
  return route;
};

export default routes;
