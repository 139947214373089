import React, { useState, createContext, useContext } from 'react';
import { languageOptions, languageDictionaryList } from '../asset/resources/resourceSelector';
import { GuestPortalConstants } from '../config/constants';

const selectedLanguage = languageOptions.find((item) => localStorage.getItem(GuestPortalConstants.DefaultLanguage) === item.id);
/**
 * create the language context with default selected language
 */
export const LanguageContext = createContext({
  language: selectedLanguage ? selectedLanguage : languageOptions[0],
  dictionary: languageDictionaryList[selectedLanguage?.id || languageOptions[0].id],
  setLanguage: (selectedLanguage: { id: string; text: string; languageType: string }) => {
  }
});

/**
 * It provides the language context to app
 * @param props
 */
export function LanguageProvider(props: any) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage: { id: string; text: string; languageType: string }) => {
      localStorage.setItem(GuestPortalConstants.DefaultLanguage, selectedLanguage.id.toString());
      setLanguage(selectedLanguage);
      setDictionary(languageDictionaryList[selectedLanguage.id]);
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {props.children}
    </LanguageContext.Provider>
  );
}

/**
 * Get text according to id & current language
 * @param props
 */
export function Text(props: any) {
  const languageContext = useContext(LanguageContext);
  return languageContext.dictionary[props.tid];
}
