/* eslint-disable import/extensions */
import moment from 'moment-timezone';
import { useContext } from 'react';
import { LanguageContext } from '../../providers/LanguageProvider';

const useDateTimeParser = (start: string, end: string, zone: string): string => {
  const languageContext = useContext(LanguageContext);
  moment.locale(languageContext.language.id);
  const startTime = moment(start).tz(zone);
  const endTime = moment(end).tz(zone);
  return `${startTime.format('LLL')} - ${endTime.format(
    'LT'
  )} ${startTime.zoneAbbr()}`;
};

export default useDateTimeParser;
