/* eslint-disable import/extensions */
import React, { useContext } from 'react';
import styled from './ValidateCardSection.styled';
import Config from '../../config/config';
import { GuestPortalConstants } from '../../config/constants';
import { LanguageContext } from '../../providers/LanguageProvider';
import car from '../../asset/images/car.svg';
import person from '../../asset/images/personal_info.svg';
import peopleImgPath from '../../asset/images/People.png';

interface Props {
  isHostDetails: boolean;
  showAvatar: boolean;
  avatar?: string;
  icon?: string;
  data: string[];
  notif?: string;
}

const ValidateCardSection: React.FC<Props> = ({
  isHostDetails, showAvatar, avatar, icon, data, notif
}) => {
  const languageContext = useContext(LanguageContext);

  const isLangRTL = (languageContext.language.languageType
    === GuestPortalConstants.RtlLanguageType);

  const defaultImageName = GuestPortalConstants.DefaultImageName;

  return (
    <styled.CardSection
      highlighted={isHostDetails}
      horizontal
      reversed={isLangRTL}
      horizontalAlign="space-between"
    >
      <styled.sectionLeft
        horizontal
        reversed={isLangRTL}
        gap="10%"
        verticalAlign="start"
      >
        {icon && (
          <styled.CardSectionIcon
            src={icon==="car" ? car : person}
            alt={languageContext.dictionary.ShowAvatar}
          />
        )}
        <styled.CardSectionText isRTL={isLangRTL} icon={icon}>
          <styled.CardSectionContentBold>{data[0]}</styled.CardSectionContentBold>
          {data.slice(1).map((item) => (
            <styled.CardSectionContent key={item}>{item}</styled.CardSectionContent>
          ))}
          {notif && (
            <styled.Notification isRTL={isLangRTL}>
              <styled.NotificationText isRTL={isLangRTL}>
                {notif}
              </styled.NotificationText>
            </styled.Notification>
          )}
        </styled.CardSectionText>
      </styled.sectionLeft>
      <styled.CardSectionImage verticalAlign="center">
        {showAvatar && isHostDetails && data[0] && (
          <styled.AvatarCircle num={(data[0]).length}>
            <styled.Initials>{data[0] ? data[0].split(' ')[0]?.charAt(0) + data[0].split(' ')[1]?.charAt(0) : 'NA'}</styled.Initials>
          </styled.AvatarCircle>
        )}

        {showAvatar && !isHostDetails && (
          <styled.CardAvatar
            src={avatar && avatar!=='' ? `${Config.CDN_AVATAR_URL}${avatar}` : peopleImgPath}
            alt={languageContext.dictionary.ShowAvatar}
          />
        )}
      </styled.CardSectionImage>
    </styled.CardSection>
  );
};

export default ValidateCardSection;
