
/**
 * Default theme applied to whole app
 */
export default {
  // Color definitions
  primaryColor: '#8661c5',
  secondaryColor: '#0078d4',

  // Brand Colors
  brandGray: '#d2d2d2',
  brandLightGray: '#fafafa',
  brandDarkGray: '#a19f9d',
  brandWhite: '#ffffff',
  brandBlack: '#000000',
  BrandBrightBlue: '#0064C1',
  BrandDarkBlue: '#0064BF',
  brandDeepSkyBlue: '#0078d4',
  brandDullGray: '#C6C6C6',
  brandBackgroundGray: '#f2f2f2',
  brandBorder: '#c4c4c4',
  brandExtraDarkGray: '#646464',
  brandBackgroundDarkGray: '#f3f2f1',
  brandBannerGray: '#171717',
  brandBannerYellow: '#FED9CC',
  brandIconRed: '#D73B02',
  brandYellow: '#FABF1B',
  brandGreen: '#90c052',
  brandLightBlue: '#3EACDD',
  brandOrange: '#F26B31',
  brandErrorBar: '#FED9CC',
  brandGrey: '#E5E5E5',
  brandSteelGrey: '#C0C0C0',
  brandWhiteSmoke: '#F5F5F5',

  // Font definitions
  baseFontFamily: 'Segoe UI',
  fontWeightLight: 350,
  fontWeightNormal: 400,
  fontWeightBold: 500,
  fontWeightMediumBold: 650,
  fontWeightExtraBold: 700,
  fontSizeXSmall: '10px',
  fontSizeSmall: '12px',
  fontSizeLSmall: '14px',
  fontSizeXLSmall: '16px',
  fontSizeSMedium: '17px',
  fontSizeMedium: '18px',
  fontSizeXLMedium: '20px',
  fontSizeLarge: '24px',
  fontSizeMXLarge: '26px',
  fontSizeMLarge: '22px',
  fontSizeXLarge: '30px',
  fontSizeXXLarge: '46px',
  iconSizeLarge: '60px',
  iconSizeMedium: '45px',
  iconSizeSmall: '30px',

  // Extras
  boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132)',
  badgeBoxShadow: '1px 1px 6px rgba(0, 0, 0, 0.25)'
};
