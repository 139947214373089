import {
  SHOW_SUCCESS_CALLOUT, HIDE_CALLOUT, SHOW_ERROR_CALLOUT
} from '../actionTypes';
import {
  SettingsState, SettingsAction, HideCalloutAction, CalloutAction
} from './settingsType';
import { IIconProps } from 'office-ui-fabric-react';

// Success message iconProps
const successMessageIconProps: IIconProps = {
  iconName: 'SkypeCircleCheck'
};

// Initial settings state reducer
const initialSettingsState: SettingsState = {
  messageCalloutDetails: {
    message: '',
    hidden: true,
    iconProps: successMessageIconProps
  }
};

// Show callout reducer
const showCallout = (
  state: SettingsState, { payload }: CalloutAction
): SettingsState => ({
  ...state,
  messageCalloutDetails: {
    message: payload.message,
    hidden: false,
    iconProps: payload.iconProps
  }
});

// Hide callout reducer
const hideCallout = (
  state: SettingsState, { payload }: HideCalloutAction
): SettingsState => ({
  ...state,
  messageCalloutDetails: {
    message: state.messageCalloutDetails.message,
    hidden: payload.hidden,
    iconProps: state.messageCalloutDetails.iconProps
  }
});

// Settings reducer
const settingsReducer = (
  state: SettingsState = initialSettingsState, action: SettingsAction
): SettingsState => {
  // Mutate (if necessary) and return state for each action
  switch (action.type) {
    case SHOW_SUCCESS_CALLOUT:
      return showCallout(state, action);
    case SHOW_ERROR_CALLOUT:
      return showCallout(state, action);
    case HIDE_CALLOUT:
      return hideCallout(state, action);
    default:
      return state;
  }
};

export default settingsReducer;
