export const size = {
  mobileS: '375px',
  mobileM: '415px',
  mobileL: '500px',
  mobileXL: '600px',
  tabletS: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  laptopXL: '1680px',
  desktop: '2560px',
  heightM: '675px',
  heightL: '730px',
  heightXL: '740px'
};

export const AvatarColorMap: Record<string, string> = {
  1: '#CD5C5C',
  2: '#2980B9',
  3: '#CB4335',
  4: '#6C3483',
  5: '#B9770E',
  6: '#34495E',
  7: '#5D6D7E',
  8: '#117A65',
  9: '#808000',
  0: '#008080'
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileXL: `(min-width: ${size.mobileXL})`,
  tabletS: `(min-width: ${size.tabletS})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

/**
 * Landmark avatars
 */
export const LandmarksAvatarNameList = [
  {
    avatarName: 'bogota.png',
    avatarAriaLabel: 'Illustration of landmark buildings in Bogota, Colombia'
  },
  {
    avatarName: 'charlotte.png',
    avatarAriaLabel: 'Illustration of landmark buildings in Charlotte, North Carolina'
  },
  {
    avatarName: 'chicago.png',
    avatarAriaLabel: 'Illustration of landmark buildings in Chicago, Illinois'
  },
  {
    avatarName: 'dublin.png',
    avatarAriaLabel: 'Illustration of landmark bridge in Dublin, Ireland'
  },
  {
    avatarName: 'dulles.png',
    avatarAriaLabel: 'Illustration of building in Dulles, Virginia'
  },
  {
    avatarName: 'fargo.png',
    avatarAriaLabel: 'Illustration of landmark sign in Fargo, North Dakota'
  },
  {
    avatarName: 'ftlauderdale.png',
    avatarAriaLabel: 'Illustration of skyscraper in Fort Lauderdale, Florida'
  },
  {
    avatarName: 'gurgaon.png',
    avatarAriaLabel: 'Illustration of landmark temple in Gurgoan, India'
  },
  {
    avatarName: 'london.png',
    avatarAriaLabel: 'Illustration of landmarks in London'
  },
  {
    avatarName: 'mexicocity.png',
    avatarAriaLabel: 'Illustration of landmark buildings in Mexico City'
  },
  {
    avatarName: 'milan.png',
    avatarAriaLabel: 'Illustration of landmark buildings in Milan, Italy'
  },
  {
    avatarName: 'munich.png',
    avatarAriaLabel: 'Illustration of landmark building in Munich, Germany'
  },
  {
    avatarName: 'paris.png',
    avatarAriaLabel: 'Illustration of landmarks in Paris, France'
  },
  {
    avatarName: 'reno.png',
    avatarAriaLabel: 'Illustration of landmarks in Reno, Nevada'
  },
  {
    avatarName: 'rome.png',
    avatarAriaLabel: 'Illustration of landmarks in Rome, Italy'
  },
  {
    avatarName: 'sanfrancisco.png',
    avatarAriaLabel: 'Illustration of landmarks in San Francisco, California'
  },
  {
    avatarName: 'seattle.png',
    avatarAriaLabel: 'Illustration of landmark and mountain in Seattle, Washington'
  },
  {
    avatarName: 'seoul.png',
    avatarAriaLabel: 'Illustration of landmark buildings in Seoul, Korea'
  },
  {
    avatarName: 'siliconvalley.png',
    avatarAriaLabel: 'Illustration of glass building in Silicon Valley'
  },
  {
    avatarName: 'singapore.png',
    avatarAriaLabel: 'Illustration of landmarks in Singapore'
  },
  {
    avatarName: 'sydney.png',
    avatarAriaLabel: 'Illustration of landmark buildings in Sydney, Australia'
  },
  {
    avatarName: 'vancouver.png',
    avatarAriaLabel: 'Illustration of landmark buildings in Vancouver, Canada'
  },
  {
    avatarName: 'washingtondc.png',
    avatarAriaLabel: 'Illustration of landmark buildings in Washington, D C'
  },
  {
    avatarName: 'wilsonville.png',
    avatarAriaLabel: 'Illustration of landmark buildings in Wilsolville, Oregon'
  },
  {
    avatarName: 'worldmap.png',
    avatarAriaLabel: 'Illustration of worldmap with white lines on a globe'
  }
];

/**
 * Individuals avatar
 */
export const IndividualsAvatarNameList = [
  {
    avatarName: 'avatarspeople001.png',
    avatarAriaLabel: 'Avatar of female with blonde hair, red lipstick, and red sweater against gray background'
  },
  {
    avatarName: 'avatarspeople002.png',
    avatarAriaLabel: 'Avatar of female with short black hair, white earrings, and white blouse against gray background'
  },
  {
    avatarName: 'avatarspeople003.png',
    avatarAriaLabel: 'Avatar of male with black hair and beard, glasses, gray suit, white shirt, and blue tie against orange background'
  },
  {
    avatarName: 'avatarspeople004.png',
    avatarAriaLabel: 'Avatar of male with styled black hair, goatee, blue eyes, and green turtleneck sweater'
  },
  {
    avatarName: 'avatarspeople005.png',
    avatarAriaLabel: 'Avatar of male with brown hat, yellow glasses, black hair, goatee, dark gray suit, and pink tie against lime green background'
  },
  {
    avatarName: 'avatarspeople006.png',
    avatarAriaLabel: 'Avatar of female with long black hair and white collared shirt against blue background'
  },
  {
    avatarName: 'avatarspeople007.png',
    avatarAriaLabel: 'Avatar of male with green mohawk, sunglasses, black jacket, and blue shirt against orange background'
  },
  {
    avatarName: 'avatarspeople008.png',
    avatarAriaLabel: 'Avatar of bald male with black beard and white collared shirt against blue background'
  },
  {
    avatarName: 'avatarspeople009.png',
    avatarAriaLabel: 'Avatar of female with black hair, orange beanie, yellow headphones, and black shirt against blue background'
  },
  {
    avatarName: 'avatarspeople010.png',
    avatarAriaLabel: 'Avatar of female with blue hair and bangs, yellow sunglasses, pink earrings, and white shirt against yellow background'
  },
  {
    avatarName: 'avatarspeople011.png',
    avatarAriaLabel: 'Avatar of male with short black hair and purple shirt against gray background'
  },
  {
    avatarName: 'avatarspeople012.png',
    avatarAriaLabel: 'Avatar of male with yellow hair, green jacket, and white shirt against orange background'
  },
  {
    avatarName: 'avatarspeople013.png',
    avatarAriaLabel: 'Avatar of male with brown beard wearing white headdress and white tunic against yellow background'
  },
  {
    avatarName: 'avatarspeople014.png',
    avatarAriaLabel: 'Avatar of female with light brown hair and bangs wearing white shirt against orange background'
  },
  {
    avatarName: 'avatarspeople015.png',
    avatarAriaLabel: 'Avatar of female with long brown hair wearing red lipstick and a red shirt against a yellow background'
  },
  {
    avatarName: 'avatarspeople016.png',
    avatarAriaLabel: 'Avatar of male with gray hair wearing dark gray collared shirt against lime green background'
  },
  {
    avatarName: 'avatarspeople017.png',
    avatarAriaLabel: 'Avatar of female wearing orange headdress, lipstick, and black tunic against light gray background'
  },
  {
    avatarName: 'avatarspeople018.png',
    avatarAriaLabel: 'Avatar of female with long yellow hair wearing gold earrings, lipstick, and a green shirt against a light gray background'
  },
  {
    avatarName: 'avatarspeople019.png',
    avatarAriaLabel: 'Avatar of female with red hair, freckles, and glasses wearing white earrings and white shirt against a yellow background'
  },
  {
    avatarName: 'avatarspeople020.png',
    avatarAriaLabel: 'Avatar of male with brown hair and facial hair wearing green collared shirt against orange backgroun'
  },
  {
    avatarName: 'avatarspeople021.png',
    avatarAriaLabel: 'Avatar of male with brown hair and goatee wearing blue shirt against yellow background'
  },
  {
    avatarName: 'avatarspeople022.png',
    avatarAriaLabel: 'Avatar of female with brown hair and red lipstick wearing purple shirt against blue background'
  },
  {
    avatarName: 'avatarspeople023.png',
    avatarAriaLabel: 'Female with black braided hair, wearing HoloLens and orange shirt against light gray background'
  },
  {
    avatarName: 'avatarsaquatic001.png',
    avatarAriaLabel: 'Avatar of blue sea turtle, yellow fish, and orange sea horse in aquatic setting'
  },
  {
    avatarName: 'avatarscat001.png',
    avatarAriaLabel: 'Avatar of gray cat against yellow background'
  },
  {
    avatarName: 'avatarsdog001.png',
    avatarAriaLabel: 'Avatar of brown dog against lime green background'
  },
  {
    avatarName: 'avatarsrobot001.png',
    avatarAriaLabel: 'Avatar of red robot against gray background'
  }
];


export const GuestPortalConstants = {
  YesKey: 'yes',
  NoKey: 'no',
  RtlLanguageType: 'RTL',
  LtrLanguageType: 'LTR',
  DefaultImageName: 'People.png',
  Failure: 'failure',
  Success: 'success',
  DefaultStatus: '',
  DefaultLanguage: 'defaultLangType',
  MobileMaxWidth: 500,
  TabletMaxWidth: 1024,
  LaptopMaxWidth: 2560,
  DefaultCountry: 'United States',
  DefaultCountryCode: 'US',
  EditDetailsView: 'edit',
  LobbyQRView: 'scan',
  ValidateInfoView: 'validate',
  CompleteCheckinView: 'complete',
  checkinSource: 'GUEST_PORTAL',
  checkinStatusDue: 'DUE_FOR_ENTRY',
  baseTimeZone: 'GMT',
  guestBadgeCaption: 'Visitor - Host required',
  Expiry: 'Exp. ',
  CancelButtonAriaDescription: 'Click on cancel button that allows you to return to the previous section',
  EditButtonAriaDescription: 'Click on edit button that allows you to edit your personal details on the same page',
  UpdateInfoAriaDescription: 'Update your personal details',
  UpdateInfoDialogDescription: 'This opens a non blocking dialog for updating your personal details'
};

export const govermentIdList = ['DrivingLicense', 'Passport',
  'MilitaryId', 'ParmanentResidentCard'];

export const NameRegex = /^[\p{L}\p{M}' \\.\\,\\-]+$/u;
export const PhoneRegex = /^[\p{N}\\+\\(]+[\p{N}\\+\-\\(\\)]+[\p{N}]+$/u;
export const CompanyRegex = /^[\p{L}\p{M}\p{N}\\&\\(\\)\\.\\'\\ ]+$/u;
export const LicensePlateRegex = /^[\p{L}\p{M}\p{N}' \\.\\,\\-]+$/u;
export const MakeRegex = /^[\p{L}\p{M}\p{N}' \\.\\,\\-]+$/u;
export const ModelRegex = /^[\p{L}\p{M}\p{N}' \\.\\,\\-]+$/u;

// Timer to redirect to home page in seconds
export const RedirectToHomePageTimer = 5;
// Timer for the message callout in ms
export const MessageCalloutTimer = 5000;

export const RegionHeader = 'X-Region';

export const UpcomingVisits = 'UpcomingVisits';
export const PreviousVisits = 'PreviousVisits';
export const RequestSourceHeader = 'RequestSource';
export const Source = 'Guest Portal';