/* eslint-disable import/extensions */
import React, { useContext, useState } from 'react';
import { Link, ILinkStyles, Stack } from 'office-ui-fabric-react';
import styled from './Footer.styled';
import theme from '../../asset/themes/defaultTheme';
import { Text, LanguageContext } from '../../providers/LanguageProvider';
import Dialog from '../../components/Dialog/Dialog';
import privacyIcon from '../../asset/images/privacy-statement.svg';

/**
 * Type for Footer Props
 */
interface FooterPropTypes {
  className?: string;
}

const linkStyle: ILinkStyles = {
  root: {
    color: theme.brandBlack,
    textDecoration: 'underline',
  },
};

const pivacyIconElement: JSX.Element = (
  <styled.PrivacyIcon src={privacyIcon} role="presentation" />
);

/**
 * Footer Component
 */
const Footer: React.FC<FooterPropTypes> = ({ className }) => {
  const languageContext = useContext(LanguageContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const privacyStatement: JSX.Element = (
    <>
      <Text tid="PrivacyStatement1" />
      {" "}
      <styled.PrivacyLink target="_blank" href={Text({ tid: 'PrivacyLink' })}>
        <Text tid="PrivacyStatementLinkText" />
      </styled.PrivacyLink>
      {" "}
      <Text tid="PrivacyStatement2" />
    </>
  );

  return (
    
      <styled.Container role="contentinfo">
        <Stack
          className={className}
          horizontal
          styles={styled.StackStyle}
          verticalAlign="center"
          horizontalAlign="end"
        >
          <Dialog
            children={privacyStatement}
            heading={Text({ tid: 'PrivacyPolicy' })}
            icon={pivacyIconElement}
            isOpen={isDialogOpen}
            setIsOpen={setIsDialogOpen}
          />
          <Link
            styles={linkStyle}
            onClick={() => setIsDialogOpen(true)}
          >
            <Text tid="PrivacyPolicy" />
          </Link>
        </Stack>
      </styled.Container>
    
  );
};

export default Footer;
