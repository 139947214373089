/* eslint-disable import/extensions */
import {
  applyMiddleware, compose, createStore, Store
} from 'redux';
import createSagaMiddleware, { SagaMiddlewareOptions } from 'redux-saga';
import rootReducer, { RootState } from './rootReducer';
import rootSaga from './rootSaga';

// Create Saga Middleware
const sagaMiddlewareOptions: SagaMiddlewareOptions<object> = {};
const sagaMiddleware = createSagaMiddleware(sagaMiddlewareOptions);

let store: Store;

/**
 * Confgures & Returns the created store object
 * @param preloadedState The initial state to be passed
 */
const configureStore = (): Store => {
  // Setup Middlewares
  const middlewareEnhancer = applyMiddleware(sagaMiddleware);

  // Join enhancers
  const composedEnhancers = compose(middlewareEnhancer);

  // Create store object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store = createStore(rootReducer as any, undefined, composedEnhancers);

  // Run Saga middlewares
  sagaMiddleware.run(rootSaga);

  // Return Store object
  return store;
};

export const getCurrentStore = (): RootState => store.getState() as RootState;

/**
 * Export configure store function
 */
export default configureStore;
