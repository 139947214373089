import { VehicleDetailsType } from "./guestType";

export const vehicleStatesProvinces = [
  {
    StateID: '7',
    StateDesc: 'AL',
    SortOrder: '0',
  },
  {
    StateID: '8',
    StateDesc: 'AK',
    SortOrder: '0',
  },
  {
    StateID: '9',
    StateDesc: 'AZ',
    SortOrder: '0',
  },
  {
    StateID: '10',
    StateDesc: 'AR',
    SortOrder: '0',
  },
  {
    StateID: '11',
    StateDesc: 'CA',
    SortOrder: '0',
  },
  {
    StateID: '12',
    StateDesc: 'CO',
    SortOrder: '0',
  },
  {
    StateID: '13',
    StateDesc: 'CT',
    SortOrder: '0',
  },
  {
    StateID: '14',
    StateDesc: 'DE',
    SortOrder: '0',
  },
  {
    StateID: '15',
    StateDesc: 'DC',
    SortOrder: '0',
  },
  {
    StateID: '16',
    StateDesc: 'FL',
    SortOrder: '0',
  },
  {
    StateID: '17',
    StateDesc: 'GA',
    SortOrder: '0',
  },
  {
    StateID: '18',
    StateDesc: 'HI',
    SortOrder: '0',
  },
  {
    StateID: '19',
    StateDesc: 'ID',
    SortOrder: '0',
  },
  {
    StateID: '20',
    StateDesc: 'IL',
    SortOrder: '0',
  },
  {
    StateID: '21',
    StateDesc: 'IN',
    SortOrder: '0',
  },
  {
    StateID: '22',
    StateDesc: 'IA',
    SortOrder: '0',
  },
  {
    StateID: '23',
    StateDesc: 'KS',
    SortOrder: '0',
  },
  {
    StateID: '24',
    StateDesc: 'KY',
    SortOrder: '0',
  },
  {
    StateID: '25',
    StateDesc: 'LA',
    SortOrder: '0',
  },
  {
    StateID: '26',
    StateDesc: 'ME',
    SortOrder: '0',
  },
  {
    StateID: '27',
    StateDesc: 'MD',
    SortOrder: '0',
  },
  {
    StateID: '28',
    StateDesc: 'MA',
    SortOrder: '0',
  },
  {
    StateID: '29',
    StateDesc: 'MI',
    SortOrder: '0',
  },
  {
    StateID: '30',
    StateDesc: 'MN',
    SortOrder: '0',
  },
  {
    StateID: '31',
    StateDesc: 'MS',
    SortOrder: '0',
  },
  {
    StateID: '32',
    StateDesc: 'MO',
    SortOrder: '0',
  },
  {
    StateID: '33',
    StateDesc: 'MT',
    SortOrder: '0',
  },
  {
    StateID: '34',
    StateDesc: 'NE',
    SortOrder: '0',
  },
  {
    StateID: '35',
    StateDesc: 'NV',
    SortOrder: '0',
  },
  {
    StateID: '36',
    StateDesc: 'NH',
    SortOrder: '0',
  },
  {
    StateID: '37',
    StateDesc: 'NJ',
    SortOrder: '0',
  },
  {
    StateID: '38',
    StateDesc: 'NM',
    SortOrder: '0',
  },
  {
    StateID: '39',
    StateDesc: 'NY',
    SortOrder: '0',
  },
  {
    StateID: '40',
    StateDesc: 'NC',
    SortOrder: '0',
  },
  {
    StateID: '41',
    StateDesc: 'ND',
    SortOrder: '0',
  },
  {
    StateID: '42',
    StateDesc: 'OH',
    SortOrder: '0',
  },
  {
    StateID: '43',
    StateDesc: 'OK',
    SortOrder: '0',
  },
  {
    StateID: '44',
    StateDesc: 'OR',
    SortOrder: '0',
  },
  {
    StateID: '45',
    StateDesc: 'PA',
    SortOrder: '0',
  },
  {
    StateID: '46',
    StateDesc: 'RI',
    SortOrder: '0',
  },
  {
    StateID: '47',
    StateDesc: 'SC',
    SortOrder: '0',
  },
  {
    StateID: '48',
    StateDesc: 'SD',
    SortOrder: '0',
  },
  {
    StateID: '49',
    StateDesc: 'TN',
    SortOrder: '0',
  },
  {
    StateID: '50',
    StateDesc: 'TX',
    SortOrder: '0',
  },
  {
    StateID: '51',
    StateDesc: 'UT',
    SortOrder: '0',
  },
  {
    StateID: '52',
    StateDesc: 'VT',
    SortOrder: '0',
  },
  {
    StateID: '53',
    StateDesc: 'VA',
    SortOrder: '0',
  },
  {
    StateID: '54',
    StateDesc: 'WA',
    SortOrder: '0',
  },
  {
    StateID: '55',
    StateDesc: 'WV',
    SortOrder: '0',
  },
  {
    StateID: '56',
    StateDesc: 'WI',
    SortOrder: '0',
  },
  {
    StateID: '57',
    StateDesc: 'WY',
    SortOrder: '0',
  },
  {
    StateID: '58',
    StateDesc: 'AB',
    SortOrder: '0',
  },
  {
    StateID: '59',
    StateDesc: 'BC',
    SortOrder: '0',
  },
  {
    StateID: '60',
    StateDesc: 'MB',
    SortOrder: '0',
  },
  {
    StateID: '61',
    StateDesc: 'NB',
    SortOrder: '0',
  },
  {
    StateID: '62',
    StateDesc: 'NF',
    SortOrder: '0',
  },
  {
    StateID: '63',
    StateDesc: 'NT',
    SortOrder: '0',
  },
  {
    StateID: '64',
    StateDesc: 'NS',
    SortOrder: '0',
  },
  {
    StateID: '65',
    StateDesc: 'NU',
    SortOrder: '0',
  },
  {
    StateID: '66',
    StateDesc: 'ON',
    SortOrder: '0',
  },
  {
    StateID: '67',
    StateDesc: 'PE',
    SortOrder: '0',
  },
  {
    StateID: '68',
    StateDesc: 'QC',
    SortOrder: '0',
  },
  {
    StateID: '69',
    StateDesc: 'SK',
    SortOrder: '0',
  },
  {
    StateID: '70',
    StateDesc: 'YT',
    SortOrder: '0',
  },
  {
    StateID: '71',
    StateDesc: 'PR',
    SortOrder: '0',
  },
  {
    StateID: '72',
    StateDesc: 'VI',
    SortOrder: '0',
  },
  {
    StateID: '73',
    StateDesc: 'Alberta',
    SortOrder: '1',
  },
  {
    StateID: '74',
    StateDesc: 'British Columbia',
    SortOrder: '1',
  },
  {
    StateID: '75',
    StateDesc: 'Manitoba',
    SortOrder: '1',
  },
  {
    StateID: '76',
    StateDesc: 'New Brunswick',
    SortOrder: '1',
  },
  {
    StateID: '77',
    StateDesc: 'Newfoundland/Labrador',
    SortOrder: '1',
  },
  {
    StateID: '78',
    StateDesc: 'Nova Scotia',
    SortOrder: '1',
  },
  {
    StateID: '79',
    StateDesc: 'Ontario',
    SortOrder: '1',
  },
  {
    StateID: '80',
    StateDesc: 'Prince Edward Island',
    SortOrder: '1',
  },
  {
    StateID: '81',
    StateDesc: 'Saskatchewan',
    SortOrder: '1',
  },
  {
    StateID: '82',
    StateDesc: 'Quebec',
    SortOrder: '1',
  },
  {
    StateID: '83',
    StateDesc: 'Other (International)',
    SortOrder: '2',
  },
];


export const vehicleMakes = [];

export const vehicleModels = [];

const guest = {
  firstName: "",
  lastName: "",
  emailId: "",
  phone: "",
  companyName: "",
  avatarName: "",
  prefix: "",
  displayName: "",
  middleName: "",
  country: "",
  contactId: "",
};

export const vehicle: VehicleDetailsType = {
  make: "",
  model: "",
  registrationYear: "",
  color: "",
  registrationState: "",
  registrationId: "",
};

export const guestVehicleResource = {
  guest,
  vehicleInfo: vehicle,
};

export const vehicleMasterList = {
  make: vehicleMakes,
  model: vehicleModels,
  state: vehicleStatesProvinces,
};

export const vehicleType = [
  { Id: "Car", Text: "Car" },
  { Id: "Motorcycle", Text: "Motorcycle" },
  { Id: "Truck/Pick-up", Text: "Truck/Pick-up" },
  { Id: "Van", Text: "Van" },
];

export const guestDetail = {
  guest,
  vehicleInfo: vehicle,
  isVehicleDefault: false,
  visitList: [],
};

export const hostDetail = {
  hostId: "",
  displayName: "",
  preferredCommunication: "",
  delegates: [],
  discoverable: "",
  notifyHost: "",
  givenName: "",
  officeLocation: "",
  mobilePhone: "",
  surname: "",
  userPrincipalName: "",
  employeeId: "",
  mail: "",
};

export const guestVisitUpdate = {
  guestVisitDetails: guestDetail,
  guestUpdateStatus: "",
};

export const visitCheckinUpdateResource = {
  visitId: "",
  success: "",
};

export const hostDetailsUpdate = {
  hostDetails: hostDetail,
  hostUpdateStatus: "",
};

export const validateQRResponse = {
  validateQRCheckinVisitsDetail: guestDetail,
  validateQRSuccessStatus: "",
};

export const checkinInfo = {
  qrString: "",
  visitId: "",
};
