/* eslint-disable import/extensions */
import React, { useContext, useEffect } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  IconButton, IIconProps, Button, Spinner, SpinnerSize,
} from 'office-ui-fabric-react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import QrReader from 'react-qr-reader';
import { Text, LanguageContext } from '../../../providers/LanguageProvider';
import theme from '../../../asset/themes/defaultTheme';
import styled from './LobbyQR.styled';
import * as GuestAction from '../../../store/guest/guestAction';
import { RootState } from '../../../store/rootReducer';
import * as GuestVisitSelector from '../../../store/guest/guestSelector';
import { GuestPortalConstants, MessageCalloutTimer } from '../../../config/constants';
import { getRoute } from '../../../routes/routerConfig';
import { CheckinInfoType } from '../../../store/guest/guestType';
import { checkinInfo } from '../../../store/guest/guestResource';


interface Props {
  visitId: string;
  switchView: Function;
}

const LobbyQR: React.FunctionComponent<Props> = ({ visitId, switchView }) => {
  const [qrString, setQRString] = React.useState<string>('');
  const [awaitingResponse, setAwaitingResponse] = React.useState<boolean>(false);

  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const languageContext = useContext(LanguageContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const isLangRTL = (languageContext.language.languageType
     === GuestPortalConstants.RtlLanguageType);

  const {
    guestVisitDetail,
    validateQRSuccessStatus,
  } = useSelector((state: RootState) => ({
    guestVisitDetail: GuestVisitSelector.guestSelector(state),
    validateQRSuccessStatus: GuestVisitSelector.ValidateQRSuccessStatusSelector(state),

  }), shallowEqual);

  /*
  The users is considered a new guest if the first name is empty
  */
  const { guest } = guestVisitDetail;
  const isNewGuest = !(guest && guest.emailId && guest.firstName);

  const DismissErrorBar = (): void => {
    // setErrorBar(false);
    dispatch(GuestAction.updateValidateQRStatus(GuestPortalConstants.DefaultStatus));
  };

  let timeoutId;

  /*
  Automatically Dismisses the Error Bar after 5 sec of showing
  */
  const showErrorBar = (): void => {
    // Timeout to hide message after the configured seconds
    timeoutId = setTimeout(DismissErrorBar, MessageCalloutTimer);
  };

  const ErrorBar = (): JSX.Element => (
    <styled.FailureMessageStack horizontal reversed={isLangRTL}>
      <styled.WarningIcon
        iconName="warning"
        aria-hidden="true"
      />
      <styled.FailureMessage>
        <Text tid="LobbyQRErrorMessage" />
      </styled.FailureMessage>
      <styled.DismissButtonStackItem isRTL={isLangRTL}>
        <Button
          iconProps={cancelIcon}
          styles={styled.DismissIconStyle}
          onClick={() => DismissErrorBar()}
          aria-label={languageContext.dictionary.Dismiss}
          tabIndex={0}
        />
      </styled.DismissButtonStackItem>
    </styled.FailureMessageStack>
  );

  /*
  Routing to homepage when clicked on close icon
  */
  const handleCloseIcon = (): void => {
    DismissErrorBar();
    history.push(getRoute('home'));
  };

  /*
If a QR code is scanned the string would not be null
*/
  const onScan = (e): void => {
    if (e != null && !awaitingResponse) {
      setQRString(e);
    }
  };


  /** Resetting updateValidateQRStatus to initial value on component unmount */
  useEffect(() => () => {
    dispatch(GuestAction.updateValidateQRStatus(GuestPortalConstants.DefaultStatus));
  }, []);


  /*
    Fetches checkin visit list on a successful scan
  */
  useEffect(() => {
    if (qrString !== '') {
      const checkinValidationDetails: CheckinInfoType = checkinInfo;
      checkinValidationDetails.qrString = qrString;
      checkinValidationDetails.visitId = visitId;
      setAwaitingResponse(true);
      dispatch(GuestAction.validateqrcode(checkinValidationDetails));
      setQRString('');
    }
  }, [qrString]);

  /*
    If checkin update status is successful, switches to next screen.
  */
  useEffect(() => {
    if (validateQRSuccessStatus === GuestPortalConstants.Success) {
      setAwaitingResponse(false);
      dispatch(GuestAction.updateValidateQRStatus(GuestPortalConstants.DefaultStatus));
      if (isNewGuest) {
        switchView(GuestPortalConstants.EditDetailsView);
      } else {
        switchView(GuestPortalConstants.ValidateInfoView);
      }
    }
    if (validateQRSuccessStatus === GuestPortalConstants.Failure) {
      setAwaitingResponse(false);
      showErrorBar();
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [validateQRSuccessStatus]);

  return (
    // <Stack styles={styled.Card} tokens={styled.sectionStackTokens}>
    <styled.QRCodeContainer horizontal horizontalAlign="center">
      <Stack styles={styled.topStackStyles} horizontalAlign="center">
        <IconButton
          styles={styled.closeIconButtonStyles(isLangRTL)}
          iconProps={cancelIcon}
          onClick={handleCloseIcon}
          aria-label={languageContext.dictionary.Dismiss}
        />
        <Stack styles={styled.QRStackStyle}>
          <styled.QRstackItemStyles>
            <styled.QrView>
              <QrReader
                onScan={onScan}
                onError={() => showErrorBar()}
                showViewFinder={false}
              />
            </styled.QrView>
          </styled.QRstackItemStyles>
          <styled.ErrorBar>
            {validateQRSuccessStatus === GuestPortalConstants.Failure && <ErrorBar /> }
          </styled.ErrorBar>

          { validateQRSuccessStatus === GuestPortalConstants.DefaultStatus
                && (
                  <styled.OnQRstackItemStyles>
                    {!awaitingResponse && <Text tid="Searching" /> }
                    { awaitingResponse
                      && (
                        <Stack horizontal reversed={isLangRTL}>
                          <Spinner styles={styled.LoaderStyle} size={SpinnerSize.small} />
                          <Text tid="Processing" />
                        </Stack>
                      )}
                  </styled.OnQRstackItemStyles>
                )}
          <styled.OnQRstackItemStyles2>
            <div role="text">
              <Text tid="LobbyQRScanningMessage" />
            </div>
          </styled.OnQRstackItemStyles2>
          <styled.OnQRstackItemStylesFocusBox viewBox="0 0 155 155">
            <path
              d="M20,7 L2,7 L2,25"
              fill="none"
              stroke={theme.brandOrange}
              strokeWidth="3.5"
            />
            <path
              d="M2,80 L2,98 L20,98"
              fill="none"
              stroke={theme.brandLightBlue}
              strokeWidth="3.5"
            />
            <path
              d="M80,98 L98,98 L98,80"
              fill="none"
              stroke={theme.brandYellow}
              strokeWidth="3.5"
            />
            <path
              d="M98,25 L98,7 L80,7"
              fill="none"
              stroke={theme.brandGreen}
              strokeWidth="3.5"
            />
          </styled.OnQRstackItemStylesFocusBox>
          <styled.OnQRstackItemStylesShaded>
            <rect width="20%" height="100%" x="0%" y="0%" />
            <rect width="89%" height="21.6%" x="19.975%" y="0%" />
            <rect width="20.4%" height="87.5%" x="79.6%" y="21.558%" />
            <rect width="59.657%" height="21.8%" x="19.975%" y="78.2%" />
          </styled.OnQRstackItemStylesShaded>
        </Stack>
        <Stack styles={styled.QRTextStack}>
          <styled.QRTextStyle>
            <Text tid="LocatingLobbyQRcode" />
          </styled.QRTextStyle>
        </Stack>
        <Stack styles={styled.QRSubTextStack}>
          <span>
            <Text tid="LocatingLobbyQRcodeMessage" />
          </span>
          <span>
            <span><Text tid="LocatingLobbyQRcodeMessage2" /></span>
          </span>
        </Stack>
      </Stack>
    </styled.QRCodeContainer>
    // </Stack>
  );
};

export default LobbyQR;
