/* eslint-disable max-len */
/* eslint-disable import/extensions */
import { takeLatest, all, put } from 'redux-saga/effects';
import {
  FETCH_BUILDING_CONFIG
} from '../actionTypes';
import httpService from '../../config/apiInterceptor';
import apiConfig from '../../config/apiConfig';
import * as BuildingAction from './buildingAction';
import { fetchBuildingConfigAction, Building } from './buildingType';
import logger from '../../common/logging/AILogger';
import {
  ErrorCodes, CommonLoggingConstants, BuildingLoggingConstants, EventCodes
} from '../../config/loggingCodes';
import { CustomProperties } from '../../common/logging/logger';

function* fetchBuildingConfigs({ payload }: fetchBuildingConfigAction): Generator {
  try {
    const buildingIdList = payload.visitList.map((visit) => visit.buildingId);
    const buildingIds = Array.from(new Set(buildingIdList));
    let buildingList: Building[] = [];
    const customProperties: CustomProperties = [];
    yield httpService.post(apiConfig.GetBuildingParkingConfiguration(), { buildingIds })
      .then((response) => {
        buildingList = response.data;
        customProperties[BuildingLoggingConstants.GuestVisitBuildingList] = buildingList;
        logger.logEvent(EventCodes.EV_104,
          `${CommonLoggingConstants.ComponentName}guestsaga:fetchBuildingConfigs`,
          customProperties);
      })
      .catch((error) => {
        const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
        logger.logError(ErrorCodes.ER_102, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}buildingSaga:fetchBuildingConfigs`, JSON.stringify(error.stack));
      });
    yield put(BuildingAction.fetchBuildingConfigsSuccess(buildingList));
  } catch (error) {
    const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
    logger.logError(ErrorCodes.ER_102, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}buildingSaga:fetchBuildingConfigs`, JSON.stringify(error.stack));
  }
}

/**
 * Export building sagas
 */
export default function* guestSagas(): Generator {
  yield all([
    takeLatest(FETCH_BUILDING_CONFIG, fetchBuildingConfigs)
  ]);
}
