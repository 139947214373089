import {
  BuildingConfigState,
  fetchBuildingConfigSuccessAction,
  fetchVisitBuildingAction
} from "./buildingType";
import { BuildingAction } from './buildingType';
import { 
  FETCH_BUILDING_CONFIG,
  FETCH_BUILDING_CONFIG_SUCCESS,
  FETCH_VISIT_BUILDING,
} from "../actionTypes";

const initialBuildingConfigState: BuildingConfigState = {
  isBuildingConfigLoaded: false,
  buildingList: [],
  visitBuilding: undefined,
}

/**
 * Fetch building parking config
 * @param state
 * @param payload
 */
const fetchBuildingConfigSuccess = (
  state: BuildingConfigState, { payload }: fetchBuildingConfigSuccessAction
): BuildingConfigState => ({
  ...state,
  buildingList: payload.buildingList,
  isBuildingConfigLoaded: true,
});

const fetchVisitBuilding = (
  state: BuildingConfigState, { payload }: fetchVisitBuildingAction
): BuildingConfigState => ({
  ...state,
  visitBuilding: payload.building
});

const buildingReducer = (
  state: BuildingConfigState = initialBuildingConfigState, action: BuildingAction
): BuildingConfigState => {
  switch (action.type) {
    case FETCH_BUILDING_CONFIG:
      return state;
    case FETCH_BUILDING_CONFIG_SUCCESS:
      return fetchBuildingConfigSuccess(state, action);
    case FETCH_VISIT_BUILDING:
      return fetchVisitBuilding(state, action);
    default:
      return state;
  }
}
      

export default buildingReducer;