/* eslint-disable complexity */
/* eslint-disable import/extensions */
import {
  FETCH_GUEST_VISIT_DETAILS,
  FETCH_GUEST_VISIT_DETAILS_SUCCESS,
  UPDATE_GUEST_VISIT_DETAILS_SUCCESS,
  VALIDATE_QRCODE,
  FETCH_HOST_DETAILS,
  FETCH_HOST_DETAILS_SUCCESS,
  FETCH_VALIDATE_QR_SUCCESS,
  VISIT_CHECKIN_SUCCESS,
  VISIT_CHECKIN,
  UPDATE_VALIDATE_QR_STATUS,
  RESET_CHECKIN_STATUS
} from '../actionTypes';
import {
  FetchGuestVisitDetailsSuccessAction, GuestVisitState,
  GuestAction, UpdateGuestVisitDetailsSuccessAction, FetchHostDetailsSuccessAction,
  FetchValidateQRSuccessAction, VisitcheckinSuccessAction,
  UpdateValidateQRStatusAction
} from './guestType';

// Intitial State
const initialGuestState: GuestVisitState = {
  hostDetails: {
    displayName: '',
    hostId: '',
  },
  checkinStatus: {
    visitId: '',
    success: ''
  },
  guestVisitDetails: {
    guest: {
      firstName: '',
      lastName: '',
      emailId: '',
      companyName: '',
      phone: '',
      avatarName: '',
      prefix: '',
      displayName: '',
      middleName: '',
      country: '',
      contactId: ''
    },
    vehicleInfo: {
      make: '',
      model: '',
      color: '',
      registrationYear: '',
      registrationState: '',
      registrationId: ''
    },
    isVehicleDefault: false,
    visitList: [
    ]
  },

  vehicleMasterList: {
    make: [],
    model: [],
    state: []
  },
  validatedCheckinVisits: [
  ],
  guestUpdateStatus: '',
  isGuestLoaded: false,
  isVehicleListLoaded: false,
  validateQRSuccessStatus: '',
};

/**
 * Fetch guest visit details
 * @param state
 * @param payload
 */
const fetchGuestVisitDetailsSuccess = (
  state: GuestVisitState, { payload }: FetchGuestVisitDetailsSuccessAction
): GuestVisitState => ({
  ...state,
  guestVisitDetails: payload.guestVisitDetail.guestVisitDetails,
  guestUpdateStatus: payload.guestVisitDetail.guestUpdateStatus,
  isGuestLoaded: true
});

const updateGuestVisitDetailsSuccess = (
  state: GuestVisitState, { payload }: UpdateGuestVisitDetailsSuccessAction
): GuestVisitState => ({
  ...state,
  guestUpdateStatus: payload.guestUpdateStatus
});

const updateValidateQRStatus = (
  state: GuestVisitState, { payload }: UpdateValidateQRStatusAction
): GuestVisitState => ({
  ...state,
  validateQRSuccessStatus: payload.validateQRSuccessStatus
});

const fetchHostDetailsSuccess = (
  state: GuestVisitState, { payload }: FetchHostDetailsSuccessAction
): GuestVisitState => ({
  ...state,
  hostDetails: {
    displayName: payload.hostDetail.hostDetails.displayName,
    hostId: payload.hostDetail.hostDetails.hostId
  }
});


const fetchValidateQRSuccess = (
  state: GuestVisitState, { payload }: FetchValidateQRSuccessAction
): GuestVisitState => ({
  ...state,
  validateQRSuccessStatus: payload.validateQRResponseDetail.validateQRSuccessStatus,
  validatedCheckinVisits: payload.validateQRResponseDetail.validateQRCheckinVisitsDetail.visitList,
  guestVisitDetails: {
    guest: payload.validateQRResponseDetail.validateQRCheckinVisitsDetail.guest,
    vehicleInfo: payload.validateQRResponseDetail.validateQRCheckinVisitsDetail.vehicleInfo,
    isVehicleDefault: payload.validateQRResponseDetail.validateQRCheckinVisitsDetail.isVehicleDefault,
    visitList: state.guestVisitDetails.visitList,
  }
});

const visitCheckinSuccess = (
  state: GuestVisitState, { payload }: VisitcheckinSuccessAction
): GuestVisitState => ({
  ...state,
  checkinStatus: payload.checkinDetail
});

const resetCheckinStatus = (
  state: GuestVisitState
): GuestVisitState => ({
  ...state,
  checkinStatus: initialGuestState.checkinStatus
});

const guestReducer = (
  state: GuestVisitState = initialGuestState, action: GuestAction
): GuestVisitState => {
  switch (action.type) {
    case FETCH_HOST_DETAILS:
      return state;
    case FETCH_GUEST_VISIT_DETAILS:
      return state;
    case VALIDATE_QRCODE:
      return state;
    case VISIT_CHECKIN:
      return state;
    case FETCH_GUEST_VISIT_DETAILS_SUCCESS:
      return fetchGuestVisitDetailsSuccess(state, action);
    case UPDATE_VALIDATE_QR_STATUS:
      return updateValidateQRStatus(state, action);
    case UPDATE_GUEST_VISIT_DETAILS_SUCCESS:
      return updateGuestVisitDetailsSuccess(state, action);
    case FETCH_HOST_DETAILS_SUCCESS:
      return fetchHostDetailsSuccess(state, action);
    case FETCH_VALIDATE_QR_SUCCESS:
      return fetchValidateQRSuccess(state, action);
    case VISIT_CHECKIN_SUCCESS:
      return visitCheckinSuccess(state, action);
    case RESET_CHECKIN_STATUS:
      return resetCheckinStatus(state);
    default:
      return state;
  }
};

export default guestReducer;
