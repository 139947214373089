/* eslint-disable import/extensions */
import React from 'react';
import {
  Switch, Redirect, Route
} from 'react-router-dom';
import routesList, { RouteType } from './routerConfig';

/**
 * Load all routes with its corresponding sub routes recursively
 * @param Props routes - Route object, path - Absolute path of route from parent
 */
const routeWithSubRoutes = ({ routes, path }: {routes: RouteType; path: string}): JSX.Element => {
  // Find the default route of the Parent route
  const defaultRoute = routes.subRoutes?.find((route) => route.default);

  /**
   * Default redirection Routes if any - to goto desired child
   */
  const redirectElements = defaultRoute
    && (
      <Redirect
        exact
        from={path}
        to={`${path}${defaultRoute.path}`}
      />
    );

  /**
   * Return Route elements with corresponding component and recursive nested children
   */
  const routeElements = routes.subRoutes?.map((route) =>
    (
      <Route
        key={route.id}
        path={`${path}${route.path}`}
        // NOTE: To allow only child components to render add exact matching
        // and move out subroutes rendering
      >
        {route.component ? <route.component /> : <></>}
        {route.subRoutes && routeWithSubRoutes({ routes: route, path: `${path}${route.path}` })}
      </Route>
    ));

  /**
   * Return rendered Route config
   */
  return (
    <Switch>
      {redirectElements}
      {routeElements}
    </Switch>
  );
};

/**
 * Load the whole Route configuration using the JSON route list
 */
const RouterOutlet: React.FC = (
) =>
  // Create subroutes
  routeWithSubRoutes({ routes: routesList, path: routesList.path });
export default RouterOutlet;
