/* eslint-disable import/extensions */
import styled from 'styled-components';
import {
  IconButton, IIconProps, Dialog
} from 'office-ui-fabric-react';
import theme from '../../asset/themes/defaultTheme';
import { size } from '../../config/constants';

const DialogHeader = styled.div`
  padding: 0 3rem;
  font-size: ${theme.fontSizeLarge};
  font-weight: 700;
`;
const DialogFooter = styled.div`
  padding: 0 3rem;
  padding-top:1.25rem;
  border-top: 1px solid ${theme.brandBorder}
`;

const DialogContentContainer = styled.div <{ isRTL: boolean }>`
  text-align : ${(props) => (props.isRTL ? 'right' : 'left')};
  box-sizing:border-box;
  width: 35vw;
  padding-top: 3rem;
  padding-bottom: 1rem;
  @media(max-width: ${size.mobileXL}) {
    width: auto;
  }
`;

const DialogText = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 3.125rem;
  padding: 0 3rem;
`;

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const DialogCloseIcon = styled(IconButton).attrs({ iconProps: cancelIcon })`
  position: absolute;
  font-size: 1.5rem;
  top: 0;
  right: 0;
`;

const DialogIcon = styled.div`
  padding: 0 3rem;
  color: ${theme.brandDeepSkyBlue};
  font-size: 5.625rem;
`;

const header = styled.div`
  display: flex;
`;

const ConfirmationDialog = styled(Dialog) <{ isRTL: boolean }>`
    direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};
    text-align : ${(props) => (props.isRTL ? 'right' : 'left')};
`;

export default {
  DialogHeader,
  DialogFooter,
  DialogContentContainer,
  DialogText,
  DialogIcon,
  DialogCloseIcon,
  ConfirmationDialog,
  header
};
