/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useContext ,useMemo} from 'react';
import {
  ImageFit, Stack, IStackTokens, IContextualMenuItem, ContextualMenu, StackItem, ActionButton, IContextualMenuProps,DirectionalHint
} from 'office-ui-fabric-react';
import { useSelector, shallowEqual } from 'react-redux';
import Config from '../../config/config';
import styled from './Header.styled';
import MsLogoImgPath from '../../asset/images/Microsoft-Logo.png';
import msalAuthenticator from '../../auth/msalAuthenticator';
import { RootState } from '../../store/rootReducer';
import * as GuestVisitSelector from '../../store/guest/guestSelector';
import { GuestPortalConstants } from '../../config/constants';
import theme from '../../asset/themes/defaultTheme';
import defaultPeopleimg from '../../asset/images/People.png';
import { LanguageContext } from '../../providers/LanguageProvider';
import peopleImgPath from '../../asset/images/People.png';

/**
 * Type for Header Props
 */
interface HeaderPropTypes {
  className?: string;
}

const innerStackTokens: IStackTokens = {
  childrenGap: 10,
};

const ContextMenuLinkStyle = {
  cursor: 'pointer'
};

const handleSignout = () => {
  msalAuthenticator.signOut();
};
/**
 * Header Component
 */
const Header: React.FC<HeaderPropTypes> = ({ className }) => {
  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = () => setShowContextualMenu(true);
  const onHideContextualMenu = () => setShowContextualMenu(false);

  const languageContext = useContext(LanguageContext);
  const isLangRTL = (languageContext.language.languageType
    === GuestPortalConstants.RtlLanguageType);

  const {
    guestVisitDetail
  } = useSelector((state: RootState) => ({
    guestVisitDetail: GuestVisitSelector.guestSelector(state)
  }), shallowEqual);

  const menuItems: IContextualMenuItem[] = [
    {
      // TODO- add this in constants (already a file created in another pending PR)
      key: 'logout',
      text: languageContext.dictionary.logOutText,
      onClick: () => { handleSignout(); },
      style: {
        fontSize: `${theme.fontSizeLSmall}`
      },
    }
  ];

  const defaultImageName = GuestPortalConstants.DefaultImageName;

  
  const menuProps: IContextualMenuProps = useMemo(
    () => ({
      shouldFocusOnMount: true,
      items: menuItems,
      directionalHint: DirectionalHint.bottomAutoEdge,
    }),
    [menuItems],
  );


  return (
    <styled.StackStyle role="banner">
      <styled.Container horizontal horizontalAlign="space-between" verticalAlign="center" >
        {!isLangRTL && (
          <Stack className={className}>
            <styled.MsLogoImage src={MsLogoImgPath} alt={languageContext.dictionary.MSLogo} />
          </Stack>
        )}
        <Stack className={className} horizontal tokens={innerStackTokens} disableShrink>
          {!isLangRTL && (
            <Stack.Item>
              <styled.StyledLanguageSelector />
            </Stack.Item>
          )}


          <StackItem>
            <ActionButton
              ref={linkRef}
              onClick={onShowContextualMenu}
              styles={{menuIcon:{
                display:'none'  
              }}}
              aria-label={languageContext.dictionary.ShowAvatar}
              menuProps={menuProps}
            >
              <styled.GuestImage
                src={guestVisitDetail.guest && guestVisitDetail.guest.avatarName && guestVisitDetail.guest.avatarName!==''
                  ? `${Config.CDN_AVATAR_URL}${guestVisitDetail.guest.avatarName}`
                  : peopleImgPath}
                alt={languageContext.dictionary.ShowAvatar}
                imageFit={ImageFit.contain}
              />
            </ActionButton>
            {/* {guestVisitDetail.guest?.emailId !== '' && (
              <ContextualMenu
                items={menuItems}
                hidden={showContextualMenu}
                target={linkRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
              />
            )} */}
          </StackItem>


          {isLangRTL && (
            <Stack.Item>
              <styled.StyledLanguageSelector />
            </Stack.Item>
          )}
        </Stack>
        {isLangRTL && (
          <Stack className={className}>
            <styled.MsLogoImage src={MsLogoImgPath} />
          </Stack>
        )}
      </styled.Container>
    </styled.StackStyle>
  );
};

export default Header;
