
import React from 'react';
import {
  DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton
} from 'office-ui-fabric-react';
import styled from './Dialog.styled';

const ConfirmationDialog: React.FC<{ dialogProps: any }> = ({ dialogProps }) =>
  /**
   * Render Element
   */
  (
    <Dialog
      hidden={dialogProps.hideDialog}
      onDismiss={dialogProps.onCloseDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: <styled.header>
          {dialogProps.PageTitle}
        </styled.header>,
        subText: dialogProps.PageSubText
      }}
      modalProps={{
        isBlocking: dialogProps.isBlocking
      }}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={dialogProps.onLeavePage}
          text={dialogProps.buttonLeavePage}
        />
        <DefaultButton
          onClick={dialogProps.onCloseDialog}
          text={dialogProps.buttonCancel}
        />

      </DialogFooter>
    </Dialog>
  );
export default ConfirmationDialog;
