/* eslint-disable import/extensions */
import azureB2CAuth from './msalAuthenticator';
import config from '../env/index';
import { getCurrentRegion } from '../routes/routerConfig';

const region = getCurrentRegion();
const authConfig = config.AUTH_CONFIG[region];

if (authConfig) {
  azureB2CAuth.initialize({
    instance: authConfig.instance,
    tenant: authConfig.tenant,
    signInPolicy: authConfig.signInPolicy,
    resetPolicy: authConfig.resetPolicy,
    applicationId: authConfig.applicationId,
    cacheLocation: authConfig.cacheLocation,
    scopes: authConfig.scopes,
    redirectUri: authConfig.redirectUri,
    postLogoutRedirectUri: authConfig.postLogoutRedirectUri,
    validateAuthority: authConfig.validateAuthority,
    silentLoginOnly: authConfig.silentLoginOnly,
    webApiAcopes: authConfig.webApiAcopes
  });
}

export default azureB2CAuth;
