/* eslint-disable import/extensions */
import {
  FETCH_GUEST_VISIT_DETAILS,
  FETCH_GUEST_VISIT_DETAILS_SUCCESS,
  UPDATE_GUEST_DETAILS,
  UPDATE_GUEST_VISIT_DETAILS_SUCCESS,
  FETCH_HOST_DETAILS,
  FETCH_HOST_DETAILS_SUCCESS,
  VALIDATE_QRCODE,
  FETCH_VALIDATE_QR_SUCCESS,
  UPDATE_VALIDATE_QR_STATUS,
  VISIT_CHECKIN,
  VISIT_CHECKIN_SUCCESS,
  RESET_CHECKIN_STATUS
} from '../actionTypes';
import {
  GuestAction, GuestVehicleDetailsType, GuestVisitUpdateType,
  HostDetailsUpdateType, ValidateQRResponseType, CheckinInfoType, VisitCheckinUpdateType,
  checkinDetailsType
} from './guestType';

/**
 * Action to validate the QR and fetch guest details
 * @param checkinInfo
 */
export const validateqrcode = (
  checkinInfo: CheckinInfoType
): GuestAction => ({
  type: VALIDATE_QRCODE,
  payload: {
    checkinInfo
  }
});

/**
 * action to fetch guest details depending on email
 * @param email
 */
export const fetchGuestVisitDetails = (
): GuestAction => ({
  type: FETCH_GUEST_VISIT_DETAILS
});

export const updateValidateQRStatus = (
  validateQRSuccessStatus: string
): GuestAction => ({
  type: UPDATE_VALIDATE_QR_STATUS,
  payload: {
    validateQRSuccessStatus
  }
});

export const updateGuestVisitDetailsSuccess = (
  guestUpdateStatus: string
): GuestAction => ({
  type: UPDATE_GUEST_VISIT_DETAILS_SUCCESS,
  payload: {
    guestUpdateStatus
  }
});

/**
 * action on guest visit details fetch success
 * @param guestVisitDetail
 */
export const fetchGuestVisitDetailsSuccess = (
  guestVisitDetail: GuestVisitUpdateType
): GuestAction => ({
  type: FETCH_GUEST_VISIT_DETAILS_SUCCESS,
  payload: {
    guestVisitDetail
  }
});

export const visitCheckin = (
  checkinDetail: checkinDetailsType
): GuestAction => ({
  type: VISIT_CHECKIN,
  payload: {
    checkinDetail
  }
});

export const visitCheckinSuccess = (
  checkinDetail: VisitCheckinUpdateType
): GuestAction => ({
  type: VISIT_CHECKIN_SUCCESS,
  payload: {
    checkinDetail
  }
});

export const fetchHostDetails = (
  hostId: string
): GuestAction => ({
  type: FETCH_HOST_DETAILS,
  payload: {
    hostId
  }
});

export const resetCheckinStatus = (
): GuestAction => ({
  type: RESET_CHECKIN_STATUS
});

export const fetchHostDetailsSuccess = (
  hostDetail: HostDetailsUpdateType
): GuestAction => ({
  type: FETCH_HOST_DETAILS_SUCCESS,
  payload: {
    hostDetail
  }
});

/**
 * action on checkin visit details fetch success
 * @param checkinDetails
 */
export const fetchValidateQRSuccess = (
  validateQRResponseDetail: ValidateQRResponseType
): GuestAction => ({
  type: FETCH_VALIDATE_QR_SUCCESS,
  payload: {
    validateQRResponseDetail
  }
});

/**
 * Action to update visit details
 * @param visitDetails
 */
export const updateGuestDetails = (
  guestVehicleDetail: GuestVehicleDetailsType
): GuestAction => (
  {
    type: UPDATE_GUEST_DETAILS,
    payload: { guestVehicleDetail }
  });
