import { all } from 'redux-saga/effects';
import guestSagas from './guest/guestSaga';
import buildingSagas from './building/buildingSaga';

/**
 * Return Generator to run all Sagas
 */
export default function* rootSaga(): Generator {
  yield all([
    guestSagas(),
    buildingSagas()
  ]);
}
