/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GuestDetail from '../../../components/GuestDetail/GuestDetail';
import styled from './PersonalDetails.styled';
import { RootState } from '../../../store/rootReducer';
import * as GuestVisitSelector from '../../../store/guest/guestSelector';
import * as BuildingSelector from '../../../store/building/buildingSelector';
import { getRoute } from '../../../routes/routerConfig';
import { GuestPortalConstants } from '../../../config/constants';


interface Props {
  visitId: string;
  switchView: Function;
}

const PersonalDetails: React.FC<Props> = ({ visitId, switchView }) => {
  const [isViewOpen, setIsViewOpen] = useState(true);
  const [submit, onSubmit] = useState(false);

  const {
    buildingList,
    guestVisitDetail,
  } = useSelector((state: RootState) => ({
    buildingList: BuildingSelector.buildingsSelector(state),
    guestVisitDetail: GuestVisitSelector.guestSelector(state),
  }), shallowEqual);

  const history = useHistory();
  const { guest } = guestVisitDetail;
  const isNewGuest = !(guest && guest.emailId && guest.firstName);
  const buildingId = (guestVisitDetail.visitList.find((v) => v.visitId === visitId))?.buildingId;
  const isVehicleStateVisible = buildingList.length > 0 ? (buildingList?.find((building) =>
    building.buildingId === buildingId)?.gmsConfigs?.vehicleStateVisible) : false;

  useEffect(() => {
    if (!isViewOpen) {
      setIsViewOpen(true);
      // when cancel is clicked for new guest checkin flow.
      if (isNewGuest) {
        history.push(getRoute('home'));
      }
      // when cancel is click while editing from validate info view
      switchView(GuestPortalConstants.ValidateInfoView);
    }
  }, [isViewOpen]);

  useEffect(() => {
    if (submit) {
      onSubmit(false);
      switchView(GuestPortalConstants.ValidateInfoView);
    }
  }, [submit]);

  return (
    <styled.Container>
      <GuestDetail
        isVehicleStateVisible={isVehicleStateVisible}
        setIsModalOpen={setIsViewOpen}
        isPage
        vehForm={false}
        onSave={onSubmit}
        aria-hidden="true"
      />
    </styled.Container>
  );
};

export default PersonalDetails;
