/* eslint-disable import/extensions */
import styled from 'styled-components';
import {
  Stack, StackItem, Dialog, DefaultButton, PrimaryButton, Label, mergeStyleSets
} from 'office-ui-fabric-react';
import QRCode from 'qrcode.react';
import { FontIcon, Icon } from 'office-ui-fabric-react/lib/Icon';
import theme from '../../asset/themes/defaultTheme';
import { size } from '../../config/constants';


const PurposeColorMap: Record<string, string> = {
  Business: '#FFDAC3',
  Interview: '#F1E6F9',
  Personal: '#F4EACA',
  Classified: '#D8EAF7',
  Event: '#CFE8D0'
};

const AvatarColorMap: Record<string, string> = {
  1: '#BC3A3A',
  2: '#20638F',
  3: '#CB4335',
  4: '#6C3483',
  5: '#8A590A',
  6: '#34495E',
  7: '#5D6D7E',
  8: '#117A65',
  9: '#676700',
  0: '#008080'
};

const PhotoIdImage = styled.img`
  margin-top: 0.3125rem;
  border-radius: 50%;
  background-color: ${theme.brandWhite}
`;

const CardStack = styled.div<{ isRTL: boolean }>`
  flex-direction: row;
  direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.brandWhite};
  margin-bottom: 0.625rem;
  @media(max-width: ${size.mobileXL}) {
    height: auto;
  }
`;

const InfoHeader = styled.span<{ isRTL: boolean }>`
text-align: ${(props) => (props.isRTL ? 'right' : 'left')};
padding-right: ${(props) => (props.isRTL ? '0.625rem' : '5rem')};
padding-left: ${(props) => (props.isRTL ? '5rem' : '0.625rem')};
display: inline-block;
max-width: 70vw;
align-self: center;
font-size: 14px;
@media(min-width: ${size.tabletS}){
  max-width: 55vw;
};
@media(max-width: ${size.mobileXL}){
};
@media(max-width: ${size.mobileL}) {
}
`;

const InfoContainer = styled.div`
background: #FFF4CE;
padding-bottom: 5px;
padding-top: 5px;
padding-left: 10px;
`;

const CardBody = styled(Stack)<{ isRTL: boolean }>`
  flex-direction: row;
  ${(props) => (props.isRTL ? 'padding-right: 0.6rem;' : 'padding-left: 0.6rem;')}
  border-bottom: 1px solid ${theme.brandBorder};
  @media(max-width: ${size.mobileXL}) {
    flex-direction: column;
    padding: 0px;
  }
`;

const PurposeContainer = styled(StackItem)`
  font-size: ${theme.fontSizeLarge};
  font-weight: ${theme.fontWeightMediumBold};
  margin-bottom: 1rem;
`;

const DateContainer = styled(StackItem) <{ isPast: boolean }>`
  font-size: ${theme.fontSizeXLSmall};
  font-weight: ${theme.fontWeightMediumBold};
  height: '14%';
`;

const HeadingContainer = styled(StackItem)`
  font-size: ${theme.fontSizeXLSmall};
  font-weight: ${theme.fontWeightMediumBold};
  height: 14%;
  @media(max-width: ${size.mobileXL}) {
    margin-bottom: 6%;
  }
`;

const LocationContainer = styled.button`
  font-size: ${theme.fontSizeLSmall};
  color: ${theme.BrandBrightBlue};
  margin-bottom: 1rem;
  cursor: pointer;
  display: inline-block;
  text-transform: capitalize;
  background: ${theme.brandWhite};
  border: none;
`;

const QRCodeContainer = styled(Stack)`
    padding: 1% 1% 1% 1%;
    background: ${theme.brandWhiteSmoke};
    
    @media(min-width: ${size.mobileL}) {
      padding-top: 4%;
      padding-bottom: 3%;
    };
    @media(max-width: ${size.mobileXL}){
      width: 100%;
      padding: 4%;

    };
`;

const QRCodeInfo = styled.span`
    font-size: ${theme.fontSizeXLSmall};
`;


const NoVehDefault = styled.span`
    font-size: ${theme.fontSizeSmall};
    width: 72%;
`;

const VehInfo = styled.span <{ isAddVeh: boolean }>`
    font-size: ${theme.fontSizeSmall};
    width: 45%;
    padding-top:  ${(props) => (props.isAddVeh ? '3%' : '0%')};
`;

const BoldText = styled.span`
  font-weight: ${theme.fontWeightMediumBold}; 
`;

const ResponsiveQRCode = styled(QRCode)<{ isRtl: boolean }>`
    width: 3rem !important;
    height: 3rem !important;
    ${(props) => (props.isRTL ? 'margin-right: 9%;' : 'margin-left: 9%;')};
    @media(max-width: ${size.mobileL}) {
      height: 3rem !important;
      width: 3rem !important;
    }
    @media(max-width: ${size.laptop}) {
      width: 3rem !important;
      height: 3rem !important;
    }
`;

const MeetingDetailsContainer = styled(StackItem) <{ isPast: boolean }>`
  padding:right: 10%;
  width: 100%;
  padding-top:  ${(props) => (props.isPast ? '2%' : '1%')};
  padding-bottom:  ${(props) => (props.isPast ? '2%' : '3%')};
  @media(max-width: ${size.mobileXL}) {
    ${(props) => (!props.isPast ? `border-top: 1px solid ${theme.brandBorder};` : '')}
    padding: 3%;
    width: 95%
  }
`;

const CheckinDetailsContainer = styled(StackItem) <{ isPast: boolean }>`
  padding:right: 10%;
  @media(max-width: ${size.mobileL}) {
    ${(props) => (!props.isPast ? `border-top: 1px solid ${theme.brandBorder};` : '')}
    padding-right: 0;
  }
`;

const DialogHeader = styled.div`
  font-size: ${theme.fontSizeLarge};
  font-weight: 700;
  margin-bottom: 2.5rem;
`;
const RightAlignedDialogFooter = styled.div`
  margin-top:1.25rem;
`;

const DialogContentContainer = styled.div <{ isRTL: boolean }>`
  text-align : ${(props) => (props.isRTL ? 'right' : 'left')};
  padding: 0 4%;
`;

const DialogText = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 3.125rem;
`;

const IconsContainer = styled(Stack)`
  margin-top: 1.4rem;
  @media(max-width: ${size.mobileXL}) {
    margin-top: 1rem;
  }
`;

const DivisonContainer = styled(Stack)`
  height: 40%;
  @media(max-width: ${size.mobileL}) {
    height: 7rem;
  }
`;

const ParkingDirectionContainer = styled(Stack)`
  flex-direction: column;
  align-items: center;
  padding-right: 1.1rem;
`;


const DivisionItemContainer = styled(Stack)`
`;


const CenterItemContainer = styled(Stack)`
  background: linear-gradient(#BEBEBE, #BEBEBE) no-repeat center/1px 100%;
`;

const IconTextContainer = styled.span`
  font-size: ${theme.fontSizeLSmall}
`;


const QRTextContainer = styled.span`
  font-size: ${theme.fontSizeLSmall};
  margin-bottom: auto;
`;


const CheckInTextContainer = styled.span`
  font-size: ${theme.fontSizeLSmall}
  margin-bottom: auto;
`;

const VehicleRegContainer = styled(Stack)`
  font-size: ${theme.fontSizeLSmall};
  padding-bottom: 20%;
  @media(max-width: ${size.mobileXL}) {
    margin-bottom: 9%;
    padding-bottom: 0%;
  }
`;

const OrTextContainer = styled.span`
  border-radius: 100px;
  background: #0078D4;
  padding: 7px;
  width: 8px;
  height: 8px;
  margin: auto;
`;

const IconContainer = styled.span`
  display: inline-block;
  text-align: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 1px solid ${theme.brandBorder};
  cursor: pointer;
`;


const CarIconContainer = styled.span <{ isRTL: boolean }>`
  display: inline-block;
  text-align: center;
  height: 2rem;
  width: 2rem;
  margin:  ${(props) => (props.isRTL ? '0 0.5rem 0 1rem' : '0 1rem 0 0.5rem')};
`;


const StyledIconSwitch = styled(FontIcon)`
  font-size: 0.6rem;
  margin: -0.2px;
`;

const StyledIconParking = styled(FontIcon)`
  font-size: 1.5rem;
  margin-top: 0.375rem;
`;

const StyledIconDirections = styled(FontIcon)`
  font-size: 1.5rem;
  margin-top: 0.625rem;
`;

const WideDialog = styled(Dialog)`
  max-width: 35% !important;
`;

const DialogParkingIcon = styled(FontIcon)`
  color: ${theme.brandDeepSkyBlue};
  font-size: 5.625rem;
`;

const HostContainer = styled(Stack) <{ isRTL: boolean }>`
  flex-direction: row;
  ${(props) => (props.isRTL ? 'padding-right: 1%;' : 'padding-left: 1%;')}
`;


const AvatarCircle = styled.div<{ num: number }>`
  width: 50px;
  height: 50px;
  background-color: ${(props) => AvatarColorMap[props.num % 10]};
  text-align: center;
  border-radius: 50%;
`;

const Initials = styled.span`
  position: relative;
  top: 12.5px; /* 25% of parent */
  font-size: 20px; /* 50% of parent */
  line-height: 25px; /* 50% of parent */
  color: #fff;
  font-weight: ${theme.fontWeightLight};
`;

const HostImage = styled(StackItem) <{ bgURL: string }>`
  background: url(${(props) => props.bgURL});
  background-size: cover;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
`;
const HostDetails = styled(StackItem)`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;

`;
const HostName = styled.span`
  font-weight: ${theme.fontWeightBold};
  font-size: ${theme.fontSizeXLSmall}
`;
const HostEmail = styled.button`
  color: ${theme.BrandBrightBlue};
  cursor: pointer;
  font-size: ${theme.fontSizeXLSmall};
  background: ${theme.brandWhite};
  border: none;
`;

const UpdateVeh = styled.button <{ isRTL: boolean }>`
  color: ${theme.BrandBrightBlue};
  cursor: pointer;
  font-size: ${theme.fontSizeSmall};
  text-decoration: underline;
  align-self: center;
  height:80%;
  background: ${theme.brandWhiteSmoke};
  border: none;
`;

const CardFooter = styled(Stack)`
  flex-direction: row;
  padding: 0.8rem;
`;

const SpacedDefaultButton = styled(DefaultButton)`
  margin: 0 1rem;
  padding: 1% 1% 1% 1%;
  font-weight: ${theme.fontWeightBold};
`;

const PrimaryButtonStyle = styled(PrimaryButton)<{ isdisabled: boolean }>`
  font-weight: ${theme.fontWeightBold};
  width: max-content;
  padding: 1% 1% 1% 1%;
  background-color: ${(props) => (props.isdisabled ? theme.brandSteelGrey : theme.BrandDarkBlue)};
  color: white;
`;

const VisitPurposeLabel = styled.div<{ visitPurpose: string }>`
  display: inline-block;
  font-size: ${theme.fontSizeSmall};
  padding: 0.33rem 0.68rem;
  color: ${theme.brandBlack};
  background-color: ${(props) => PurposeColorMap[props.visitPurpose]};
  margin-bottom: 0.6rem;
  border-radius: 3px;
  @media(max-width: ${size.mobileXL}) {
    margin-bottom: 0.4rem;
  }
`;

const OrLabel = styled(Label) <{ isRTL: boolean }>`
  font-size:${theme.fontSizeXSmall} ;
  font-weight: ${theme.fontWeightMediumBold};
  float: right;
  font-family: auto;
  padding: 0;
  color: white;
`;

const LocationIconStyle = {
  paddingRight: '0.2rem'
};

const CheckInCompleted = styled.span`
  font-size: ${theme.fontSizeLSmall}
  padding-bottom: 3%;
`;

const CheckIcon = styled(Icon) <{ isRTL: boolean }>`
  color: ${theme.brandGreen};
  padding-top: 0.5%;
  margin: 0 0.4rem;
  margin-${(props) => (props.isRTL ? 'right' : 'left')}: auto;
  @media(max-width: ${size.mobileL}) {
    padding-top: 3%;
  }
`;

const ModelContainer = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '30rem'
  }
});

export default {
  PhotoIdImage,
  DivisonContainer,
  QRCodeContainer,
  CardStack,
  ResponsiveQRCode,
  QRCodeInfo,
  MeetingDetailsContainer,
  CheckinDetailsContainer,
  RightAlignedDialogFooter,
  DialogHeader,
  DialogContentContainer,
  DialogText,
  StyledIconParking,
  StyledIconDirections,
  IconContainer,
  DivisionItemContainer,
  IconsContainer,
  WideDialog,
  DialogParkingIcon,
  ParkingDirectionContainer,
  IconTextContainer,
  QRTextContainer,
  OrTextContainer,
  OrLabel,
  CenterItemContainer,
  PurposeContainer,
  DateContainer,
  LocationContainer,
  LocationIconStyle,
  HostContainer,
  HostImage,
  HostDetails,
  HostName,
  AvatarCircle,
  HostEmail,
  CardFooter,
  CardBody,
  SpacedDefaultButton,
  PrimaryButtonStyle,
  VisitPurposeLabel,
  CheckInCompleted,
  CheckIcon,
  HeadingContainer,
  VehicleRegContainer,
  CarIconContainer,
  CheckInTextContainer,
  VehInfo,
  BoldText,
  UpdateVeh,
  ModelContainer,
  StyledIconSwitch,
  NoVehDefault,
  Initials,
  InfoHeader,
  InfoContainer
};
