import { combineReducers } from 'redux';
import guestReducer from './guest/guestReducer';
import buildingReducer from './building/buildingReducer';
import settingsReducer from './Settings/settingsReducer';

const combineReducer = combineReducers({
  guest: guestReducer,
  building: buildingReducer,
  settings: settingsReducer
});

/**
 * Return combined reducers
 */
export default combineReducer;

/**
 * Return Type of Root state
 */
export type RootState = ReturnType<typeof combineReducer>;
