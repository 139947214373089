/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-lines */
/* eslint-disable import/extensions */
import styled from "styled-components";
import { Image } from "office-ui-fabric-react/lib/Image";
import {
  Stack,
  mergeStyleSets,
  IStackItemStyles,
  IStackStyles,
  IChoiceGroupStyles,
  IButtonStyles,
  Label,
  IChoiceGroupOptionStyles,
  StackItem,
  Link,
  DefaultButton,
  Icon,
  ILinkStyles,
  IPivotStyles,
  Dropdown,
  IconButton,
} from "office-ui-fabric-react";
import {
  ICSSDisplayRule,
  ICSSRule,
} from "@uifabric/merge-styles/lib/IRawStyleBase";
import theme from "../../asset/themes/defaultTheme";
import { device, size } from "../../config/constants";
import InfoBox from "../../components/InfoBox/InfoBox";

const Outermost: IStackStyles = {
  root: {
    backgroundColor: theme.brandWhite,
    position: "relative",
    boxShadow: theme.boxShadow,
    overflowY: "hidden",
  },
};

const FormContainer = styled(Stack)<{ parkingSpace: boolean; isPage: boolean }>`
  @media (min-width: ${size.laptopXL}) {
    width: ${(props) => (props.isPage ? "70%" : "30vw")};
    //height: ${(props) => (props.isPage ? "100%" : "66vh")};
  }
  @media (min-width: ${size.laptopL}) {
    width: ${(props) => (props.isPage ? "70%" : "40vw")};
    //height: ${(props) => (props.isPage ? "100%" : "66vh")};
  }
  @media (min-width: ${size.laptop}) {
    width: ${(props) => (props.isPage ? "70%" : "55vw")};
    //height: ${(props) => (props.isPage ? "100%" : "82vh")};
  }
  @media (max-width: ${size.mobileXL}) {
    width: 100%;
  }

  overflow-y: hidden;
  z-index: 0;
  padding: ${(props) => (props.parkingSpace ? "5% 5% 5% 5%;" : "5% 5% 35% 5%")};
  font-family: ${theme.baseFontFamily};
  @media (max-width: ${size.mobileXL}) {
    padding: ${(props) =>
      props.parkingSpace ? "5% 10% 5% 10%;" : "5% 10% 35% 10%"};
    width: 100%;
  }
`;

const VehFormContainer = styled(Stack)<{
  parkingSpace: boolean;
  isPage: boolean;
}>`
  width: 85%;
  z-index: 0;
  padding: 0% 5% 5% 5%;
  font-family: ${theme.baseFontFamily};
  @media (min-width: ${size.laptopXL}) {
    width: ${(props) => (props.isPage ? "70%" : "85%")};
  }
  @media (max-width: ${size.laptop}) {
    width: 90%;
  }
  @media (max-width: ${size.mobileXL}) {
    padding: ${(props) =>
      props.parkingSpace ? "0% 10% 5% 5%;" : "5% 10% 35% 5%"};
    width: 100%;
  }
`;

const FormTitle = styled.span<{ isRTL: boolean }>`
  font-size: ${theme.fontSizeMLarge};
  font-weight: ${theme.fontWeightExtraBold};
  margin-left: ${(props) => (props.isRTL ? "auto" : "left")};
`;

const CloseButton = styled(IconButton)`
  color: ${theme.brandDarkGray};
  margin-left: auto !important;
  display: flex;
`;

const CloseButtonContainer = styled(Stack)`
  margin-left: auto !important;
  display: flex;
`;

const FormSubTitle = styled(StackItem)<{ isRTL: boolean }>`
  margin-top: 1% !important;
  font-size: ${theme.fontSizeSmall};
  font-weight: ${theme.fontWeightNormal};
  color: ${theme.brandExtraDarkGray} !important;
  margin-left: ${(props) => (props.isRTL ? "auto" : "left")};
  text-align: ${(props) => (props.isRTL ? "right" : "left")};
`;

const LeftStackItemStyles = {
  root: {
    minWidth: "106px",
    height: "106px",
    paddingBottom: "70px",
  },
};

const GuestImage = styled(Image)<{ isPage: boolean }>`
  // height: ${(props) => (props.isPage ? "60px" : "50px")};
  // width: ${(props) => (props.isPage ? "60px" : "50px")};
  border-radius: 50%;
  height: 106px;
  width: 106px;
  margin-top: 10px;
`;

const LoaderStyle = {
  root: {},
  circle: {
    marginTop: "15vh",
    marginBottom: "15vh",
  },
};

const displayNameStyles = {
  root: {
    fontWeight: theme.fontWeightExtraBold.toString(),
    fontSize: theme.fontSizeLarge,
  },
};

// const MenuPivotStyles = (isRTL) => ({
//   root: {
//     marginTop: '1%',
//     float: isRTL ? 'right' : 'left'
//   }
// linkIsSelected: {
//   fontSize: `${theme.fontSizeMedium}`,
//   marginBottom: '1%',
//   selectors: {
//     ':before': {
//       backgroundColor: `${theme.brandPurple}`
//     }
//   }
// },
// link: {
//   fontSize: `${theme.fontSizeMedium}`
// }
// });

const choiceOptionStyle: IChoiceGroupOptionStyles = {
  root: {
    fontWeight: `${theme.fontWeightBold}` as unknown as ICSSRule,
  },
  iconWrapper: {
    height: "10px",
    width: "10px",
  },
};

const NameContainer = styled.div`
  // @media(min-width: ${size.laptopXL}) {
  //   width: 80%;
  // };
  // @media(min-width: ${size.laptopL}) {
  //   width: 80%;
  // };
  // @media(min-width: ${size.laptop}) {
  //   width: 80%;
  // };
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: ${size.mobileXL}) {
    display: block;
  }
`;

const TextField = styled.input<{ isRTL: boolean }>`
  border: 1px solid ${theme.brandExtraDarkGray};
  border-radius: 2px;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  direction: ${(props) => (props.isRTL ? "rtl" : "ltr")};
  // unicode-bidi:bidi-override;
  ::placeholder {
    color: ${theme.brandDarkGray};
    font-size: ${theme.fontSizeSmall};
  }
`;

const ChangeAvatarButtonStyles = {
  root: {
    color: `${theme.brandDeepSkyBlue}!important`,
    border: "none",
    textDecoration: "underline",
    background: "none!important",
    display: "block" as ICSSDisplayRule,
    margin: "auto",
    whiteSpace: "nowrap",
  },
};

const LongTextField = styled.input<{ isRTL: boolean }>`
  // @media(min-width: ${size.laptopXL}) {
  //   width: 80%;
  // };
  // @media(min-width: ${size.laptopL}) {
  //   width: 80%;
  // };
  // @media(min-width: ${size.laptop}) {
  //   width: 80%;
  // };
  border: 1px solid ${theme.brandExtraDarkGray};
  border-radius: 2px;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  direction: ${(props) => (props.isRTL ? "rtl" : "ltr")};
  // unicode-bidi:bidi-override;
  fontsize: ${theme.fontSizeXLSmall};
  ::placeholder {
    color: ${theme.brandDarkGray};
    font-size: ${theme.fontSizeSmall};
  }
`;

const AvatarButton = styled.button`
  height: 100px;
  border: none;
  background-color: ${theme.brandWhite};
  align-items: center;

  @media (max-width: ${size.mobileXL}) {
    height: 90px;
    width: 90px;
  }

  :hover {
    background-color: ${theme.brandDarkGray};
  }
  :focus {
    background-color: ${theme.BrandBrightBlue};
  }
  :active {
    background-color: ${theme.brandDarkGray};
  }
`;

const ContentStyles = mergeStyleSets({
  scrollable: {
    overflowY: "hidden",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "scroll",
  },
});

const CustomStackItem = styled(Stack.Item)<{
  inputWidth?: string;
  left?: boolean;
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
}>`
  min-height: 70px;
  width: ${(props) => (props.inputWidth ? props.inputWidth : "25%")};
  padding-left: ${(props) => (props.left ? "20px" : "0px")};
  padding-top: ${(props) => (props.top ? "24px" : "0px")};
  padding-bottom: ${(props) => (props.bottom ? "20px" : "0px")};
  padding-right: ${(props) => (props.right ? "24px" : "0px")};
`;

const AvatarImageStackItemStyle = styled(StackItem)<{
  isRTL: boolean;
  isPage: boolean;
}>`
  margin-left: ${(props) => (props.isRTL ? "auto" : "left")};
  padding-top: ${(props) => (props.isPage ? "6% !important" : "4% !important")};
  padding-right: ${(props) => (props.isRTL ? "1%" : "0%")};
`;

const AvatarContainerStackStyle: IStackStyles = {
  root: {
    marginBottom: "2%",
    marginLeft: "4%",
    marginRight: "4%",
    selectors: {
      [`@media(max-width: ${size.mobileL})`]: {
        marginBottom: "3%",
        marginLeft: "0%",
        marginRight: "0%",
      },
    },
  },
};

const AvatarLinkStyle = styled(Link)<{ isRTL: boolean }>`
  padding-top: 2%;
  margin-left: ${(props) => (props.isRTL ? "auto" : "left")};
  float: ${(props) => (props.isRTL ? "right" : "left")};
  color: ${theme.BrandDarkBlue};
  text-decoration: none;
  font-weight: ${theme.fontWeightNormal};
  font-size: ${theme.fontSizeSmall};
`;

const ParkingLabel = styled(Label)<{ isRTL: boolean }>`
   {
    font-size: ${theme.fontSizeSmall};
    font-weight: ${theme.fontWeightMediumBold};
    float: ${(props) => (props.isRTL ? "right" : "left")};
    textalign: ${(props) => (props.isRTL ? "right" : "left")};
    @media (max-width: ${size.tabletS}) {
      margin-top: 7px;
    }
  }
`;

const GuestDetailItemWidth = {
  root: {
    marginTop: "2%",
    width: "100%",
    height: "55px",
  },
};

const PrefixItemWidth = {
  root: {
    marginTop: "2%",
    width: "30%",
    height: "55px",
  },
};

const CountryWidth = {
  root: {
    marginTop: "2%",
    width: "100%",
    height: "55px",
  },
};

const ErrorStack = {
  root: {
    width: "100%",
    background: "#FDE7E9",
    fontSize: theme.fontSizeLSmall,
    fontWeight: theme.fontWeightBold as unknown as ICSSRule,
    height: "50px",
  },
};

const NoVehicleInfoStack = {
  root: {
    width: "100%",
    background: "#EFEDEC",
    fontSize: theme.fontSizeLSmall,
    fontWeight: theme.fontWeightBold as unknown as ICSSRule,
    height: "50px",
  },
};

const FieldLabel = styled(Label)<{ isRTL: boolean }>`
  font-size: ${theme.fontSizeSmall};
  font-weight: ${theme.fontWeightMediumBold};
  float: ${(props) => (props.isRTL ? "right" : "left")};
  textalign: ${(props) => (props.isRTL ? "right" : "left")};
`;

const FieldLabelName = styled(Label)<{ isRTL: boolean }>`
  font-size: ${theme.fontSizeSmall};
  font-weight: ${theme.fontWeightMediumBold};
  float: ${(props) => (props.isRTL ? "right" : "left")};
  text-align: ${(props) => (props.isRTL ? "right" : "left")};
  padding: 0;
`;

const FieldLabelDateLocation = styled(Label)<{ isRTL: boolean }>`
  font-size: ${theme.fontSizeSmall};
  font-weight: ${theme.fontWeightNormal};
  float: ${(props) => (props.isRTL ? "right" : "left")};
  text-align: ${(props) => (props.isRTL ? "right" : "left")};
  padding: 0;
`;

const NameStackStyle = styled(Stack)<{ isPage: boolean }>`
  width: 50%;
  margin-top: ${(props) => (props.isPage ? "5%" : "3%")};
  @media (max-width: ${size.mobileXL}) {
    width: 100%;
  }
  @media (max-width: ${size.mobileXL}) {
    margin-top: ${(props) => (props.isPage ? "3%" : "1%")};
  }
`;

const VehicleMeetingDetailStackStyle = styled(Stack)<{ isPage: boolean }>`
  width: 80%;
  @media (max-width: ${size.mobileXL}) {
    width: 100%;
  }
`;

const NameStackItemStyle = (isLeft, isRight): IStackItemStyles => ({
  root: {
    [`@media(max-width: ${size.desktop})`]: {
      paddingLeft: isLeft ? "0px" : "10%",
      paddingRight: isRight ? "0px" : "10%",
    },
    [`@media(max-width: ${size.mobileXL})`]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
});

const ChoiceGoupStackStyle: IStackStyles = {
  root: {
    width: "100%",
    flexDirection: "column",
    marginTop: "2%",
    selectors: {
      [`@media(min-width: ${size.mobileXL})`]: {
        width: "100%",
      },
    },
  },
};

const ChoiceGroupStyles = (isLangRTL): IChoiceGroupStyles => ({
  root: {
    marginBottom: 10,
    width: "100%",
    fontSize: `${theme.fontSizeSmall} !important`,
  },
  label: {
    fontSize: `${theme.fontSizeSmall} !important`,
  },
  flexContainer: {
    display: "flex",
    width: "100%",
    justifyContent: isLangRTL ? "flex-end" : "flex-start",
    flexWrap: "wrap",
    selectors: {
      ":before": {
        height: "10px !important",
        width: "10px !important",
      },
      label: {
        fontSize: `${theme.fontSizeSmall}`,
      },
    },
  },
});

const ButtonStackStyle = styled(Stack)<{ isPage: boolean }>`
  padding-top: 2%;
  padding-bottom: ${(props) => (props.isPage ? "4%" : "2%")};
  padding-left: 5%;
  padding-right: 5%;
  // box-shadow: ${theme.boxShadow},
  @media (max-width: ${size.mobileXL}) {
    width: 100%;
    // margin-bottom: 30px;
  }
  background-color: ${theme.brandWhite};
  margin-top: ${(props) => (props.isPage ? "2px" : "0px")};
  overflow-y: hidden;
  margin-bottom: 15px;
`;

const ButtonStyle = (isLangRTL): IButtonStyles => ({
  root: {
    // paddingTop: '2px',
    borderRadius: "6%",
    fontSize: theme.fontSizeSmall,
    // minWidth: 'auto',

    // left: isLangRTL ? 'auto' : '30px',
    // right: isLangRTL ? '30px' : 'auto',
    marginRight: isLangRTL ? "0%" : "5%",
  },
});

const CancelButton = styled(DefaultButton)<{ isRTL: boolean }>`
  border-radius: 6%;
  min-width: auto;
  // margin-left: ${(props) => (props.isRTL ? "0px" : "45px")};
  // margin-right: ${(props) => (props.isRTL ? "45px" : "0px")};
  font-size: ${theme.fontSizeSmall};
`;

const ButtonStackItems = styled(StackItem)`
  background-color: white;
`;

// const FormButtonStackItemStyle = styled(StackItem)<{isRTL: boolean}>`
//   float: right;
//   margin-left: ${(props) => (props.isRTL ? 'auto' : 'left')};
// `;

const CancelButtonStackItem = styled(StackItem)<{ isRTL: boolean }>`
  padding-right: ${(props) => (props.isRTL ? "5%" : "0%")};
  margin-left: ${(props) => (props.isRTL ? "auto" : "0%")};
`;

const PrimaryButtonStackItem = styled(StackItem)<{ isRTL: boolean }>`
  padding-right: ${(props) => (props.isRTL ? "0%" : "5%")};
  margin-left: ${(props) => (props.isRTL ? "auto" : "0%")};
`;

const DismissButtonStackItem = styled(StackItem)<{ isRTL: boolean }>`
  margin-left: ${(props) => (props.isRTL ? "0px" : "auto !important")};
  margin-right: ${(props) => (props.isRTL ? "auto !important" : "0px")};
`;

const errorFragment = styled.span<{ isRTL: boolean }>`
  color: #e50000;
  float: ${(props) => (props.isRTL ? "left" : "right")};
  font-size: ${theme.fontSizeXSmall};
`;

const SubmitButtonText = styled.span`
  font-weight: ${theme.fontWeightBold};
  font-size: ${theme.fontSizeSmall};
`;

const UpdateInfoErrorText = styled.span`
  font-weight: ${theme.fontWeightBold};
  font-size: ${theme.fontSizeLSmall};
`;

const Loader = styled(Stack)`
  width: 500px;
  @media (max-width: ${size.mobileXL}) {
    width: 100%;
  }
`;

const CancelIcon = {
  root: {
    border: "none",
    minWidth: "auto",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  icon: {
    fontSize: "12px",
  },
};

const SubmitLoaderStyle = {
  root: {},
  circle: {
    marginLeft: "10px",
  },
};

const VehicleInfoBox = styled(InfoBox)``;

const ErrorIconStyle = {
  color: "#C5262C",
};

const MeetingDetailsTitle = styled(StackItem)<{ isRTL: boolean }>`
  margin-top: 3% !important;
  margin-bottom: 2%;
  font-size: ${theme.fontSizeSMedium};
  font-weight: ${theme.fontWeightBold};
  color: ${theme.brandExtraDarkGray} !important;
  margin-left: ${(props) => (props.isRTL ? "auto" : "left")};
  text-align: ${(props) => (props.isRTL ? "right" : "left")};
`;

const MeetingDetailsContainer = styled(Stack)<{ isRTL: boolean }>`
  padding: 1% 1% 1% 1%;
  width: ${(props) => (props.isRTL ? "100%" : "110%")};
  background: ${theme.brandWhiteSmoke};
  flex-direction: ${(props) => (props.isRTL ? "row-reverse" : "inherit")};

  @media (min-width: ${size.laptop}) {
    padding-top: 2%;
    width: 100%;
  }

  @media (min-width: ${size.mobileL}) {
    padding-top: 2%;
    width: 100%;
  }
`;

const CheckBoxStackItem = styled(StackItem)<{ isRTL: boolean }>`
  direction: ${(props) => (props.isRTL ? "rtl" : "ltr")};
  padding-left: 5%;
  padding-bottom: 2%;
  @media (max-width: ${size.mobileXL}) {
    padding: ${(props) => (props.isRTL ? "0% 5% 2% 0%" : "0% 0% 2% 5%")};
  }
`;

const UpdateDisplayNameButtonStyles = {
  root: {
    // color: `${theme.brandPurple}!important`,
    border: "none",
    background: "none!important",
    paddingLeft: "0px",
    height: "",
  },
};

const CancelDisplayNameButtonStyles = {
  root: {
    // color: `${theme.brandPurple}!important`,
    border: "none",
    background: "none!important",
    padding: "0px",
    marginLeft: "-17px",
  },
};

const stackItemStyles = styled(Stack.Item)`
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-left: -37px !important;
  z-index: 1;
  background: ${theme.brandWhite};
  padding-left: 3px;
  padding-right: 3px;
`;

const TextFieldStyles = {
  root: {
    marginLeft: "0px",
    marginTop: "10px",
  },
};

const CountryDropdown = styled(Dropdown)``;

const CountryDropdownContainer = styled(StackItem)`
  width: 100%;
`;

const CountryDropdownStyles = (isLangRTL) => ({
  root: {
    alignItems: "center" as ICSSRule,
  },
  title: {
    display: "flex" as ICSSDisplayRule,
    border: `1px solid ${theme.brandExtraDarkGray}`,
    borderRadius: "2px",
    alignItems: "center" as ICSSRule,
    top: "0 !important",
    left: "0 !important",
    width: "100%",
    height: "30px",
    textAlign: isLangRTL ? "right" : "left",
    fontSize: theme.fontSizeSmall,
    direction: isLangRTL ? "rtl" : "ltr",
    paddingRight: "8px",
    selectors: {
      [`@media(min-width: ${size.mobileS})`]: {
        width: "100%",
      },
    },
  },
  dropdownItem: {
    borderWidth: "0px 0px 1px 0px",
    borderStyle: "solid",
    borderColor: theme.brandGray,
    height: "40px",
    display: "block" as ICSSDisplayRule,
    fontSize: theme.fontSizeSmall,
  },
  dropdownOptionText: {
    marginLeft: isLangRTL ? "auto" : "left",
    fontSize: theme.fontSizeSmall,
  },
  dropdownItemSelected: {},
  caretDownWrapper: {
    display: "flex" as ICSSDisplayRule,
    alignItems: "center" as ICSSRule,
    fontSize: theme.fontSizeXSmall,
  },
  caretDown: {
    color: `${theme.primaryColor} !important`,
    fontWeight: theme.fontWeightBold as unknown as ICSSRule,
    float: "left",
  },
  label: {
    fontSize: theme.fontSizeLarge,
    padding: 0,
  },
  dropdownItemsWrapper: {
    background: theme.brandWhite,
  },
  callout: {},
});

const displayNameLabelStyles = styled.div`
  margin-top: 15px;
`;

const DropdownPlaceholderStyle = styled.span<{ isRTL: boolean }>`
  color: ${theme.brandExtraDarkGray};
  font-size: ${theme.fontSizeSmall};
  float: ${(props) => (props.isRTL ? "right" : "left")};
`;

export default {
  GuestImage,
  AvatarButton,
  TextField,
  LongTextField,
  NameContainer,
  ContentStyles,
  AvatarImageStackItemStyle,
  AvatarContainerStackStyle,
  AvatarLinkStyle,
  ChoiceGroupStyles,
  ParkingLabel,
  ButtonStyle,
  CancelButton,
  CountryWidth,
  GuestDetailItemWidth,
  FieldLabel,
  NameStackItemStyle,
  NameStackStyle,
  ChoiceGoupStackStyle,
  ButtonStackStyle,
  PrimaryButtonStackItem,
  CancelButtonStackItem,
  ButtonStackItems,
  SubmitButtonText,
  choiceOptionStyle,
  errorFragment,
  UpdateInfoErrorText,
  SubmitLoaderStyle,
  VehicleInfoBox,
  NoVehicleInfoStack,
  ErrorIconStyle,
  ErrorStack,
  FormContainer,
  FormTitle,
  FormSubTitle,
  MeetingDetailsTitle,
  DismissButtonStackItem,
  LoaderStyle,
  Loader,
  Outermost,
  CancelIcon,
  MeetingDetailsContainer,
  VehFormContainer,
  VehicleMeetingDetailStackStyle,
  FieldLabelName,
  FieldLabelDateLocation,
  CheckBoxStackItem,
  ChangeAvatarButtonStyles,
  UpdateDisplayNameButtonStyles,
  CancelDisplayNameButtonStyles,
  stackItemStyles,
  displayNameStyles,
  TextFieldStyles,
  PrefixItemWidth,
  CountryDropdownContainer,
  CountryDropdown,
  CountryDropdownStyles,
  DropdownPlaceholderStyle,
  CustomStackItem,
  LeftStackItemStyles,
  displayNameLabelStyles,
  CloseButton,
  CloseButtonContainer,
};
