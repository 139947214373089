import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  IDropdownProps,
  ISearchBoxProps,
  SearchBox,
} from 'office-ui-fabric-react';
import React from 'react';

interface SearchableDropdown {
  dropdownProps: IDropdownProps;
  searchBoxProps?: ISearchBoxProps;
}

const SearchableDropdown: React.FC<SearchableDropdown> = (props) => {
  const [searchText, setSearchText] = React.useState<string>('');

  const { dropdownProps, searchBoxProps } = props;
  const { options } = dropdownProps;

  function renderOption(
    option?: IDropdownOption,
    defaultRender?: (option?: IDropdownOption) => JSX.Element | null,
  ): JSX.Element | null {
    if (
      option &&
      option.itemType === DropdownMenuItemType.Header &&
      option.key === 'FilterHeader'
    ) {
      return (
        <SearchBox
          {...searchBoxProps}
          // Bolow is hack to focus search box if its not focussed, happens when a item is selected
          componentRef={(ref) =>
            new Promise((resolve) => setTimeout(resolve, 10)).then(() => {
              if (ref?.hasFocus) ref?.focus();
            })
          }
          onChange={(ev, newValue) => setSearchText(newValue ?? '')}
          underlined
        />
      );
    }
    if (dropdownProps.onRenderOption) {
      return dropdownProps.onRenderOption(option, defaultRender);
    }
    if (defaultRender) {
      return defaultRender(option);
    }
    return null;
  }

  return (
    <Dropdown
      {...dropdownProps}
      ariaLabel="country dropdown"
      options={[
        {
          key: 'FilterHeader',
          text: '-',
          itemType: DropdownMenuItemType.Header,
        },
        {
          key: 'divider_filterHeader',
          text: '-',
          itemType: DropdownMenuItemType.Divider,
        },
        ...options.map((option) =>
          !option.disabled &&
          option.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            ? option
            : { ...option, hidden: true },
        ),
      ]}
      onRenderOption={renderOption}
      onDismiss={() => setSearchText('')}
    />
  );
};

export default SearchableDropdown;
