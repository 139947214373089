import styled from 'styled-components';
import {
  mergeStyleSets, StackItem, Stack, ITooltipHostStyles, IModalStyles, ActionButton
} from 'office-ui-fabric-react';
import theme from '../../../asset/themes/defaultTheme';
import { device, size } from '../../../config/constants';

const IconButtonStyles = mergeStyleSets({
  root: {
    color: theme.brandBlack,
    verticalAlign: 'center',
    width: '30px'
  },
  rootHovered: {
    color: theme.brandWhite,
    backgroundColor: theme.BrandBrightBlue
  },
  rootPressed: {
    color: theme.brandWhite,
    backgroundColor: theme.BrandBrightBlue
  },
  rootActive: {
    color: theme.brandWhite,
    backgroundColor: theme.BrandBrightBlue
  }
});

const IconButtonStackItem = styled(StackItem)`
  padding-right: 2% !important;
`;

const Container = styled(Stack)`
  color: ${theme.brandBlack};
  @media(min-width: ${size.tabletS}){
    margin-left: 10%;
    margin-right:10%;
  }
  @media(min-width: ${size.laptopL}){
    margin-left: 30%;
    margin-right:30%;
    padding: 0% 5% 0% 5%;
  }
  @media(min-width: ${size.mobileS}){
    margin-left: 10%;
    margin-right:10%;
    // overflow-y: scroll;
  }
`;

const ModalHeader = styled(Stack)`
  color: theme.brandBlack;
  font-weight: ${theme.fontWeightExtraBold};
  width: 100%;
  display: flex;
  height: 50px;
`;

const ChooseAvatarStackItem = styled(StackItem)`
  padding: 0% 2% 2% 2%;
  width: 70%;
`;

const ChooseAvatarText = styled.span<{ isRTL: boolean }>`
  float: ${(props) => (props.isRTL ? 'right' : 'left')};
  font-size: ${theme.fontSizeLarge};
  font-weight: ${theme.fontWeightExtraBold};
  @media(max-width: ${size.mobileL}){
    font-size: ${theme.fontSizeSMedium};
  }
`;

const ModalBody = styled.div`
  float: right;
  width: 100%;
`;

const MenuPivotStyles = (isRTL) => ({
  root: {
    marginTop: '1%',
    float: isRTL ? 'bottom' : 'top'
  }
});

const ModalStyles: IModalStyles = {
  main: {
    overflowY: 'hidden'
  },
  root: {},
  scrollableContent: {},
  layer: {},
  keyboardMoveIconContainer: {},
  keyboardMoveIcon: {}
};

const ModalBodyContainer = styled.div`
    width: 100%;
    height: 250px;
    // overflow-y: auto;

    @media(min-height: ${size.heightM}) {
      height: 300px;
    };
    @media(min-height: ${size.heightXL}) {
      height: 400px;
    };

    ::-webkit-scrollbar-track {
      padding: 2px 0;
      background-color: ${theme.brandGrey};
    }

    ::-webkit-scrollbar {
      width: 6px;
    }  

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${theme.BrandBrightBlue};
    }
`;

const AvatarImage = styled.img<{ isPage: boolean }>`
  width: ${(props) => (props.isPage ? '100px' : '90px')}; 
  height: ${(props) => (props.isPage ? '100px' : '90px')}; 
  border-radius: 45px;

  @media(max-width: ${size.mobileL}){
      width: 80px;
      height: 80px;
      border-radius: 40px;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media ${device.laptop} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const ContentStyles = mergeStyleSets({
  scrollable: {
    overflowY: 'auto !important',
    marginTop: '16px',
    marginLeft: '16px',
  }
});

const AvatarButton = styled(ActionButton)`
  height: 100px;
  border: none;
  background-color: ${theme.brandWhite};
  align-items: center;

  // @media(max-width: ${size.mobileXL}){
  //   height: 90px;
  //   width: 90px;
  // }

  // :hover{
  //   background-color: ${theme.brandDarkGray};
  // }
  // :focus{
  //   background-color: ${theme.BrandBrightBlue};
  // }
  // :active{
  //   background-color: ${theme.brandDarkGray};
  // }
`;

const Avatar = styled.input`
  width: 90px;
  height: 90px;
  border-radius: 45px;

  @media(max-width: ${size.mobileL}){
      width: 80px;
      height: 80px;
      border-radius: 40px;
  }
  :hover{
    background-color: ${theme.brandDarkGray};
  }
  :focus{
    background-color: ${theme.BrandBrightBlue};
  }
  :active{
    background-color: ${theme.brandDarkGray};
  }
`;

const TooltipHostStyles: Partial<ITooltipHostStyles> = {
  root: {
    display: 'inline-block'
  }
};

export default {
  Avatar,
  AvatarImage,
  ImageGrid,
  ModalBodyContainer,
  Container,
  IconButtonStyles,
  ContentStyles,
  ModalStyles,
  ModalHeader,
  ModalBody,
  MenuPivotStyles,
  ChooseAvatarStackItem,
  ChooseAvatarText,
  AvatarButton,
  IconButtonStackItem,
  TooltipHostStyles
};
