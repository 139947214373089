/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable complexity */
/* eslint-disable import/extensions */
import React, { useContext } from 'react';
import worldMapImagePath from '../../asset/images/world_map.png';
import worldMapImagePathMobile from '../../asset/images/world_map_mobile.png';
import worldMapImagePathTablet from '../../asset/images/world_map_tab.png';
import { Text, LanguageContext } from '../../providers/LanguageProvider';
import styled from './RootPage.styled';
import guestStyle from '../WelcomeGuest/WelcomeGuest.styled';
import { GuestPortalConstants } from '../../config/constants';
import welcomeImgMobPath from '../../asset/images/welcomeguest-mobile.png';
import welcomeImgTabPath from '../../asset/images/welcomeguest-tablet.png';
import useDeviceType from '../../helpers/hooks/useDeviceType';
import { Stack } from 'office-ui-fabric-react';


const RootPageWorldMap: React.FC = () => {
  const languageContext = useContext(LanguageContext);
  const isLangRTL = (languageContext.language.languageType === GuestPortalConstants.RtlLanguageType);
  const { isMobile, isTablet } = useDeviceType();

  /**
  * Render Element
  */
  return (
    <Stack styles={styled.StackStyle}>
      <guestStyle.Container>
      {isMobile
          && (
            <guestStyle.WelcomeImage
              alt={languageContext.dictionary.WelcomeMessage}
              src={welcomeImgMobPath}
            />
          )}
        {isTablet && (
          <guestStyle.WelcomeImage
            alt={languageContext.dictionary.WelcomeMessage}
            src={welcomeImgTabPath}
          />
        )}
        <guestStyle.WelcomeGuestContainer isRTL={isLangRTL} isDesktop={!isMobile && !isTablet} role="main">
          <guestStyle.Title role="heading" aria-level={1}>
            {Text({ tid: 'HeyThere' })}
          </guestStyle.Title>
          <guestStyle.SubTitle>
            {Text({ tid: 'WelcomeText' })}
          </guestStyle.SubTitle>
          <guestStyle.WelcomeMessage>
            {Text({ tid: 'SelectRegion' })}
          </guestStyle.WelcomeMessage>
        </guestStyle.WelcomeGuestContainer>
      </guestStyle.Container>
      <Stack.Item styles={styled.StackItemStyle}>
      <styled.StackContainer verticalAlign="center" horizontalAlign="center">
        {isMobile
          ? (
            <styled.ChildContainer>
              <img src={worldMapImagePathMobile} alt="world map"/>
              <styled.HorizontalContainer horizontal horizontalAlign="space-between">
                <styled.RegionContainer isUS verticalAlign="center" horizontalAlign="center">
                  <styled.Rect isMobile={isMobile} />
                  <styled.Link href="/us">
                    {Text({ tid: 'AmericasLinkText' })}
                    <styled.StyledIcon iconName="ChromeBackMirrored" />
                  </styled.Link>
                  <styled.Text>
                    {Text({ tid: 'AmericasText' })}
                  </styled.Text>
                </styled.RegionContainer>
                <styled.RegionContainer isUS={false} verticalAlign="center" horizontalAlign="center">
                  <styled.Rect isMobile={isMobile} />
                  <styled.Link href="/eu">
                    {Text({ tid: 'GlobalLinkText' })}
                    <styled.StyledIcon iconName="ChromeBackMirrored" />
                  </styled.Link>
                  <styled.Text>
                    {Text({ tid: 'GlobalText' })}
                  </styled.Text>
                </styled.RegionContainer>
              </styled.HorizontalContainer>
            </styled.ChildContainer>
          ) : (
            <styled.ChildContainer>
              <styled.HorizontalContainer horizontal horizontalAlign="space-between">
                <styled.RegionContainer isUS verticalAlign="center" horizontalAlign="center">
                  <styled.Link href="/us">
                    {Text({ tid: 'AmericasLinkText' })}
                    <styled.StyledIcon iconName="ChromeBackMirrored" />
                  </styled.Link>
                  <styled.Text>
                    {Text({ tid: 'AmericasText' })}
                  </styled.Text>
                  <styled.Rect isMobile={isMobile} />
                </styled.RegionContainer>
                <styled.RegionContainer isUS={false} verticalAlign="center" horizontalAlign="center">
                  <styled.Link href="/eu">
                    {Text({ tid: 'GlobalLinkText' })}
                    <styled.StyledIcon iconName="ChromeBackMirrored" />
                  </styled.Link>
                  <styled.Text>
                    {Text({ tid: 'GlobalText' })}
                  </styled.Text>
                  <styled.Rect isMobile={isMobile} />
                </styled.RegionContainer>
              </styled.HorizontalContainer>
              {isTablet
                ? (
                  <img src={worldMapImagePathTablet} alt="world map" />
                ) : (
                  <img src={worldMapImagePath} alt="world map" />
                )}
            </styled.ChildContainer>
          )}
      </styled.StackContainer>
      </Stack.Item>
    </Stack>
  );
};
export default RootPageWorldMap;
