import React, { useContext } from 'react';
import {
  Pivot, PivotItem, Modal, IconButton, TooltipHost, ContextualMenu, IDragOptions, StackItem
} from 'office-ui-fabric-react';
import styled from './AvatarPicker.styled';
import { Text, LanguageContext } from '../../../providers/LanguageProvider';
import { GuestPortalConstants } from '../../../config/constants';

export interface AvatarPickerProps {
  avatarshow: boolean;
  handleAvatarDialogueClose: any;
  selectAvatar: any;
  config: any;
  LandmarksAvatarNameList: any;
  IndividualsAvatarNameList: any;
  AvatarPickerResources: any;
}

const toolTipCalloutProps = { gapSpace: 0 };

const AvatarPicker: React.FC<AvatarPickerProps> = ({
  avatarshow,
  handleAvatarDialogueClose,
  selectAvatar,
  config,
  LandmarksAvatarNameList,
  IndividualsAvatarNameList,
  AvatarPickerResources
}) => {
  const [selectedKey, setSelectedKey] = React.useState(AvatarPickerResources.individualsTab);

  const onPivotItemSwitched = async (item?: PivotItem): Promise<void> => {
    setSelectedKey(item && item.props ? (item.props.itemKey
      || AvatarPickerResources.individualsTab) : AvatarPickerResources.individualsTab);
  };

  const languageContext = useContext(LanguageContext);
  const isLangRTL = (languageContext.language.languageType
    === GuestPortalConstants.RtlLanguageType);

  const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu
  };

  // const getAvatarImages = (item: any): JSX.Element => (
  //   <TooltipHost
  //     content={item.avatarAriaLabel}
  //     styles={styled.TooltipHostStyles}
  //     calloutProps={toolTipCalloutProps}
  //     id={item.avatarName}
  //   >
  //     <styled.AvatarImage
  //       type="image"
  //       src={`${config.CDN_AVATAR_URL}${item.avatarName}`}
  //       key={item.avatarName}
  //       onClick={() => selectAvatar(item.avatarName)}
  //     />
  //   </TooltipHost>
  // );

  return (
    <styled.Container>
      <Modal 
        styles={styled.ModalStyles}
        isOpen={avatarshow}
        onDismiss={handleAvatarDialogueClose}
        isBlocking={false}
        dragOptions={dragOptions}
        scrollableContentClassName={styled.ContentStyles.scrollable}
        titleAriaId="id"
      >
        <styled.ModalHeader horizontal horizontalAlign="space-between">
          {!isLangRTL && (
            <styled.ChooseAvatarStackItem id="id" tabIndex={0}>
              <styled.ChooseAvatarText isRTL={isLangRTL} role="heading" aria-level={2}>
                <Text tid="ChooseAvatar" />
              </styled.ChooseAvatarText>
            </styled.ChooseAvatarStackItem>
          )}
          {isLangRTL && (
            <styled.IconButtonStackItem>
              <IconButton
                styles={styled.IconButtonStyles}
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close popup"
                onClick={handleAvatarDialogueClose}
              />
            </styled.IconButtonStackItem>
          )}
          {isLangRTL && (
            <styled.ChooseAvatarStackItem id="id" tabIndex={0}>
              <styled.ChooseAvatarText isRTL={isLangRTL}>
                <Text tid="ChooseAvatar" />
              </styled.ChooseAvatarText>
            </styled.ChooseAvatarStackItem>
          )}
          {!isLangRTL && (
            <styled.IconButtonStackItem>
              <IconButton
                styles={styled.IconButtonStyles}
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close popup"
                onClick={handleAvatarDialogueClose}
              />
            </styled.IconButtonStackItem>
          )}
        </styled.ModalHeader>
        <styled.ModalBody>
          <Pivot
            selectedKey={selectedKey}
            onLinkClick={onPivotItemSwitched}
            styles={styled.MenuPivotStyles(isLangRTL)}
          >
            {!isLangRTL && (
              <PivotItem
                headerText={AvatarPickerResources.individualsTab}
                itemKey={AvatarPickerResources.individualsTab}
              >
                <styled.ModalBodyContainer>
                  <styled.ImageGrid>
                    {IndividualsAvatarNameList.map((item) => (
                      <TooltipHost
                        content={item.avatarAriaLabel}
                        styles={styled.TooltipHostStyles}
                        calloutProps={toolTipCalloutProps}
                        id={item.avatarName}
                      >
          
                      <styled.AvatarButton tabIndex={-1} aria-label={item.avatarAriaLabel}>          
                      </styled.AvatarButton>
                      <styled.Avatar 
                                    type='image' 
                                    alt={item.avatarName}
                                    src={`${config.CDN_AVATAR_URL}${item.avatarName}`}
                                    aria-label={item.avatarAriaLabel}
                                    onClick={(src) => selectAvatar(src)}
                                  ></styled.Avatar>
                      </TooltipHost>
                    ))}
                  </styled.ImageGrid>
                </styled.ModalBodyContainer>
              </PivotItem>
            )}
            <PivotItem tabIndex={0}
              headerText={AvatarPickerResources.landmarksTab}
              itemKey={AvatarPickerResources.landmarksTab}
            >
              <styled.ModalBodyContainer>
                <styled.ImageGrid>
                  {LandmarksAvatarNameList.map((item) => (
                    <TooltipHost
                      content={item.avatarAriaLabel}
                      styles={styled.TooltipHostStyles}
                      calloutProps={toolTipCalloutProps}
                      id={item.avatarName}
                    >
                      <styled.AvatarButton tabIndex={-1}>
                        <styled.Avatar 
                        type='image' 
                        alt={item.avatarName}
                        src={`${config.CDN_AVATAR_URL}${item.avatarName}`}
                        aria-label={item.avatarAriaLabel}
                        onClick={(src) => selectAvatar(src)}
                        ></styled.Avatar>
                      </styled.AvatarButton>
                    </TooltipHost>
                  ))}
                </styled.ImageGrid>
              </styled.ModalBodyContainer>
            </PivotItem>
            {isLangRTL && (
              <PivotItem
                headerText={AvatarPickerResources.individualsTab}
                itemKey={AvatarPickerResources.individualsTab}
              >
                <styled.ModalBodyContainer>
                  <styled.ImageGrid>
                    {IndividualsAvatarNameList.map((item) => (
                      <TooltipHost
                        content={item.avatarAriaLabel}
                        styles={styled.TooltipHostStyles}
                        calloutProps={toolTipCalloutProps}
                        id={item.avatarName}
                      >
                        <styled.AvatarButton tabIndex={-1}>
                        <styled.Avatar 
                        type='image' 
                        alt={item.avatarName}
                        src={`${config.CDN_AVATAR_URL}${item.avatarName}`}
                            aria-label={item.avatarAriaLabel}
                            onClick={(src) => selectAvatar(src)}
                            ></styled.Avatar>
                      </styled.AvatarButton>
                      </TooltipHost>
                    ))}
                  </styled.ImageGrid>
                </styled.ModalBodyContainer>
              </PivotItem>
            )}
          </Pivot>
        </styled.ModalBody>
      </Modal>
    </styled.Container>
  );
};

export default AvatarPicker;
