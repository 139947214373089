import { IDropdownOption, IDropdownProps } from 'office-ui-fabric-react';
import React, { useMemo } from 'react';
import { countryData } from '../../../asset/resources/countryResourceSelector';
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown';

/**
 * Searchable country dropdown, listen to onChange for selected dropdown option
 * key = countryName, text = countryName
 * @param props pops
 */
const CountryDropdown: React.FC<Omit<IDropdownProps, 'options'>> = (props) => {
  const countriesOptions: IDropdownOption[] = useMemo(() => {
    return countryData.map<IDropdownOption>(({ countryName }) => ({
      key: countryName,
      text: countryName,
    }));
  }, []);
  return (
    <SearchableDropdown
      dropdownProps={{
        ...props,
        options: countriesOptions,
        styles: {
          dropdownItems: {
            maxHeight: '250px',
          },
        },
      }}
      searchBoxProps={{
        placeholder: 'Search',
      }}
    />
  );
};

export default CountryDropdown;
