/* eslint-disable import/extensions */
import {
  IStackStyles, IStackTokens, StackItem, Stack
} from 'office-ui-fabric-react/lib/Stack';
import { Icon, IButtonStyles } from 'office-ui-fabric-react';
import styled from 'styled-components';
import theme from '../../../asset/themes/defaultTheme';
import { size } from '../../../config/constants';

const QRCodeContainer = styled(Stack)`
  height: 100%;
  padding-Top: 2%;
  padding-Bottom: 1%;
  overflow: auto;
`;
const topStackStyles: IStackStyles = {
  root: {
    alignItems: 'center',
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: theme.boxShadow,
    backgroundColor: theme.brandWhite,
    overflow: 'hidden',
    alignSelf: 'center',
    // height: '100%',
    width: '36.8',
    selectors: {
      [`@media(max-width: ${size.laptopXL})`]: {
        width: '35%'
      },
      [`@media(max-width: ${size.laptop})`]: {
        width: '70%'
      },
      [`@media(max-width: ${size.mobileL})`]: {
        width: '100%'
      }
    }
  }
};


const QRStackStyle: IStackStyles = {
  root: {
    position: 'relative',
    backgroundColor: theme.brandWhite,
    width: '90vh',
    overflow: 'hidden',
    selectors: {
      [`@media(max-width: ${size.laptopXL})`]: {
        width: '75vh',
      },
      [`@media(max-width: ${size.laptop})`]: {
        width: '90vh',
      },
      [`@media(max-width: ${size.mobileL})`]: {
        width: '110vh',
      }
    }
  },
};

const QRstackItemStyles = styled(StackItem)`
  align-items: center;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 0;
  padding: 0;
  justify-content: center;
`;


const QrView = styled.div`
  width: 75vh,
  z-index: -4;
`;

const OnQRstackItemStyles = styled.span`
  font-family: ${theme.baseFontFamily};
  align-items: center;
  display: flex;
  color: ${theme.brandWhite};
  position: absolute;
  top: 83%;
  align-self: center;
  z-index: 3;
  overflow: hidden;
  font-size: ${theme.fontSizeSmall};
`;

const OnQRstackItemStyles2 = styled.span`
  font-family: ${theme.baseFontFamily};
  color: ${theme.brandWhite};
  position: absolute;
  top: 93%;
  align-self: center;
  z-index: 3;
  font-size: ${theme.fontSizeSmall};
`;

const OnQRstackItemStylesFocusBox = styled.svg`
  align-items:center;
  position: absolute;
  top: 16%;
  left: 17.5%;
  z-index: 3;
  align-self: center;
  justify-content: center;
  width: 100%;
`;

const ErrorBar = styled.div`
  position: absolute;
  z-index: 4;
  top: 85%;
  align-self: center;
`;

const closeIconButtonStyles = (isRTL): IButtonStyles => ({
  root: {
    color: theme.brandWhite,
    position: 'absolute',
    right: isRTL ? 'auto' : '1%',
    left: isRTL ? '0%' : 'auto',
    zIndex: 3,
    verticalAlign: 'center',
    width: '30px'
  },
  rootHovered: {
    color: theme.brandWhite,
    backgroundColor: theme.BrandBrightBlue
  },
  rootPressed: {
    color: theme.brandWhite,
    backgroundColor: theme.BrandBrightBlue
  }
  // rootActive: {
  //   color: theme.brandWhite,
  //   backgroundColor: theme.BrandBrightBlue
  // }
});

const OnQRstackItemStylesShaded = styled.svg`
  position: absolute;
  z-index: 2;
  fill-opacity: 0.67;
  margin-top: 0%;
  width: 100%;
  height: 100%;
`;

const QRTextStack: IStackStyles = {
  root: {
    backgroundColor: theme.brandWhite,
    width: '80%',
    textAlign: 'center',
    background: theme.brandWhite
  }
};

const QRSubTextStack: IStackStyles = {
  root: {
    backgroundColor: theme.brandWhite,
    width: '80%',
    textAlign: 'center',
    background: theme.brandWhite,
    fontSize: theme.fontSizeLSmall,
    paddingBottom: '5%',
    selectors: {
      [`@media(max-width: ${size.mobileL})`]: {
        paddingBottom: '15%'
      }
    }
  }
};

const QRTextStyle = styled.div`
  margin-top: 4%;
  font-weight: ${theme.fontWeightMediumBold};
  font-size: ${theme.fontSizeMedium};
`;


const sectionStackTokens: IStackTokens = {
  childrenGap: 5,
};


const customSpacingStackTokensClose: IStackTokens = {
  childrenGap: 0,
  padding: 10,
};

const FailureMessageStack = styled(Stack)`
  background-color: ${theme.brandErrorBar};
  margin-left: auto;
  margin-right: auto;

`;

const WarningIcon = styled(Icon)`
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 6px;
  padding-left: 6px;
  color: #D73B02;  
  
`;

const FailureMessage = styled(StackItem)`
  font-size: ${theme.fontSizeSmall};
  margin-top: auto;
  margin-bottom: auto;
  font-size: fontSizeSmall;
  @media (max-width: ${size.mobileL}) {
    font-size: fontSizeXSSmall;
  },

`;

const DismissButtonStackItem = styled(StackItem)<{isRTL: boolean}>`
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: ${(props) => (props.isRTL ? 'left' : 'auto')};
  margin-right: ${(props) => (props.isRTL ? 'auto' : 'right')};
`;

const DismissIconStyle = {
  root: {
    border: 'none',
    minWidth: 'auto',
    paddingLeft: '2px',
    paddingRight: '2px',
    backgroundColor: `${theme.brandErrorBar}`,

  },
  icon: {
    fontSize: '12px',
  },

};

const LoaderStyle = {
  root: {
    paddingRight: '4px',
    paddingLeft: '4px'
  }
};

export default {
  FailureMessageStack,
  WarningIcon,
  FailureMessage,
  DismissButtonStackItem,
  DismissIconStyle,
  customSpacingStackTokensClose,
  OnQRstackItemStylesShaded,
  QRTextStyle,
  QRStackStyle,
  QRstackItemStyles,
  OnQRstackItemStyles,
  OnQRstackItemStyles2,
  OnQRstackItemStylesFocusBox,
  sectionStackTokens,
  topStackStyles,
  closeIconButtonStyles,
  QrView,
  ErrorBar,
  QRSubTextStack,
  QRTextStack,
  QRCodeContainer,
  LoaderStyle
};
