/* eslint-disable import/extensions */
import styled from 'styled-components';
import {
  Stack, StackItem, IStackStyles, Icon
} from 'office-ui-fabric-react';
import theme from '../../asset/themes/defaultTheme';
import { size } from '../../config/constants';

const Container = styled(Stack)`
    width: 100%;
`;

const InfoContainerStack: IStackStyles = {
  root:
  {
    background: 'white',
    width: '100%',
    zIndex: 1
  }
};

const InfoExpandButton = styled.button <{ isRTL: boolean }>`
  // margin-top: -1%;
  position: absolute;
  bottom: 0;
  ${(props) => (props.isRTL ? 'left: 0;' : 'right: 0;')}
  border: none;
  background: none;
  color: ${theme.brandWhite};
  padding: 0.2rem 0.5rem;
  z-index: 100;
  cursor: pointer;
`;

const InfoStack = styled(Stack)`
  background: ${theme.brandDeepSkyBlue};
  color: ${theme.brandWhite};
  position: relative;
`;

const InfoContainer = styled(StackItem)`
    padding: 0.3125rem 0.625rem;
    padding-right: 
    color: ${theme.brandWhite};
`;

const InfoButtonTextStyle = styled.span<{ isRTL: boolean }>`
    margin-left: ${(props) => (props.isRTL ? '0.3125rem' : '0rem')};
    margin-right: 0.3125rem;
    font-weight: ${theme.fontWeightExtraBold}
`;

const InfoHeaderContainer = styled.div`
    margin-left: 0.625rem;
    display: flex;
    flex-dire n: row;
`;

const PhotoIdImage = styled.img`
  height: 1rem;
  width: 1rem;
  margin-top: 0.3125rem;
  border-radius: 50%;
  background-color: ${theme.brandWhite}
`;

const InfoHeader = styled.span<{ isRTL: boolean; showInfobox: boolean }>`
  text-align: ${(props) => (props.isRTL ? 'right' : 'left')};
  padding-right: ${(props) => (props.isRTL ? '0.625rem' : '5rem')};
  padding-left: ${(props) => (props.isRTL ? '5rem' : '0.625rem')};
  display: inline-block;
  ${(props) => (!props.showInfobox ? 'white-space: nowrap;overflow: hidden;text-overflow: ellipsis;' : null)}
  max-width: 70vw;
  align-self: center;
  @media(min-width: ${size.tabletS}){
    max-width: 55vw;
  };
  @media(max-width: ${size.mobileXL}){
  };
  @media(max-width: ${size.mobileL}) {
  }
`;

const InfoPoint = styled.div`
`;

const ExpandedInfoHeader = styled.div`
`;

// information  div size changes
const Infodiv = styled.div<{ isRTL: boolean }>`
  text-align: ${(props) => (props.isRTL ? 'right' : 'left')};
  padding: 1rem 0.8rem;
  font-size: 0.9rem;
  z-index: 12;
  box-shadow: ${theme.boxShadow};

`;

const SizedIcon = styled(Icon)`
  font-size: 1rem;
`;

export default {
  Container,
  InfoButtonTextStyle,
  PhotoIdImage,
  InfoContainerStack,
  InfoHeader,
  InfoContainer,
  Infodiv,
  InfoHeaderContainer,
  ExpandedInfoHeader,
  InfoPoint,
  InfoExpandButton,
  InfoStack,
  SizedIcon
};
