/* eslint-disable import/extensions */
import SHA from 'jssha';
import Config from '../../env/index';


const obfuscateProperty = (property: string): string => {
  if (!Config.TELEMETRY_CONFIG.enablePropertyObfuscation) return property;
  const shaObj = new SHA('SHA-256', 'TEXT');
  shaObj.update(property);
  return shaObj.getHash('HEX');
};

export default obfuscateProperty;
