/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions */
import React, { useContext } from 'react';

import { IDropdown, IDropdownOption } from 'office-ui-fabric-react';
import { languageOptions } from '../../asset/resources/resourceSelector';
import styled from './LanguageSelector.styled';
import { LanguageContext } from '../../providers/LanguageProvider';
import { GuestPortalConstants } from '../../config/constants';
import WorldLogoImgPath from '../../asset/images/world-logo.svg';

const LanguageSelector: React.FC = () => {
  const dropdownRef = React.createRef<IDropdown>();

  const languageContext = useContext(LanguageContext);

  const isLangRTL = (languageContext.language.languageType
        === GuestPortalConstants.RtlLanguageType);

  /**
   * Handles language change event. Sets the selected language using context.
   * @param event
   */
  const handleLanguageChange = (option: IDropdownOption | undefined) => {
    const selectedLanguage = option;
    if (selectedLanguage) {
      languageContext.setLanguage({
        id: selectedLanguage.key.toString(),
        text: selectedLanguage.text,
        languageType: (selectedLanguage.id) ? selectedLanguage.id
          : GuestPortalConstants.LtrLanguageType
      });
    }
  };

  const getDropDownOptions = (): IDropdownOption[] => {
    const langOptions: IDropdownOption[] = [];
    languageOptions.forEach((item) => {
      langOptions.push({ key: item.id, id: item.languageType, text: item.text });
    });
    return langOptions;
  };

  /**
   * Applies caretdown styles on caretdown render
   * @param event
   */
  const onRenderCaretDown = (event): JSX.Element => (
    <span>
      { !isLangRTL && (
        <styled.CaretDownIcon
          isRTL={isLangRTL}
          iconName="ChevronDown"
          aria-hidden="true"
        />
      )}
    </span>
  );

  /**
   * Applies title styles on title render
   * @param event
   */
  const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => (
    <div>
      { isLangRTL && (
        <styled.CaretDownIcon
          isRTL={isLangRTL}
          style={{ float: 'left' }}
          iconName="ChevronDown"
          aria-hidden="true"
        />
      )}
      {
        options && options.length ? options[0].text : ''
      }
    </div>
  );

  return (

    <styled.LanguageContainer horizontal>
      {!isLangRTL && <styled.WorldLogoImage src={WorldLogoImgPath} alt={languageContext.dictionary.WorldLogoImgPathAltText} />}
      <styled.LanguageDropdownContainer>
        <styled.LanguageDropdown
          componentRef={dropdownRef}
          options={getDropDownOptions()}
          onChange={(event, option) => handleLanguageChange(option)}
          placeholder={languageContext.language.text}
          onRenderTitle={onRenderTitle}
          onRenderCaretDown={onRenderCaretDown}
          defaultSelectedKey={languageContext.language.id}
          styles={styled.LanguageDropdownStyles(isLangRTL)}
          ariaLabel={languageContext.dictionary.LangSelector}
        />
      </styled.LanguageDropdownContainer>
      {isLangRTL && <styled.WorldLogoImage src={WorldLogoImgPath} alt={languageContext.dictionary.WorldLogoImgPathAltText} />}
    </styled.LanguageContainer>
  );
};

export default LanguageSelector;
