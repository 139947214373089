/* eslint-disable max-len */
// ENGLISH
// Maintain this list in sorted order.
const en = {
  AppErrorTitle: "There seems to be some issue. Kindly try again later.",
  Back: "Back",
  BackToHome: "Home",
  CampusParkingQuestion: "Are you driving for this meeting?",
  SetDefaultVeh: "Apply this change to my upcoming and future visits",
  Cancel: "Cancel",
  ChangeAvatar: "Change avatar",
  Chat: "Chat",
  CheckedIn: "You are Checked-in",
  CheckIn: "Check-In",
  CheckInCompleted: "Checked-in",
  Country: "Country",
  Processing: "Processing...",
  ChooseAvatar: "Choose your avatar",
  CollectGuestBadge: "Please see the lobby host to collect your guest badge",
  Company: "Company (Optional)",
  CompanyPlaceholder: "Enter company name",
  DataProtection: "Data Protection",
  Directions: "Directions",
  WebCheckin: "Complete web check-in from here",
  QRCodeCheckin: "Check-in with QR code at the lobby",
  OrText: "or",
  Continue: "Yes, continue",
  DrivingMeeting: "Driving for the Meeting?",
  CheckingInMeeting: "Checking-in for the meeting?",
  ApplyDefaultVehToFutureVisits:
    "Apply this change to my upcoming and future visits",
  ErrorSavingData:
    "Could not save the data due to some issue. Please try again.",
  ExpandedInfoHeader:
    "Examples of valid photo ID cards include driving license, passport, military ID and permanent resident card. However, this list may vary by country.",
  FieldInvalid: "This field is invalid",
  FieldRequired: "This field is required",
  FirstName: "First Name",
  FirstNamePlaceholder: "Enter first name",
  GuestFormHeader: "Personal Details",
  VehFormHeader: "Vehicle registration",
  GuestFormSubHeader:
    "We will use some of this data to print your guest badge upon your arrival.",
  GuestImageAriaLabel: "Guest Profile Icon",
  DisplayNameText: "Preferred Name",
  UpdateDisplayNameText: "Update preferred name",
  VehFormSubHeader: "Provide details about your vehicle for this meeting.",
  MettingDetails: "Meeting Details",
  HasOnlyUpcomingVisitsTitle: "We're excited for your visit at Microsoft.",
  HasUpcomingAndPastVisitsTitle:
    "Welcome back. We're excited for your visit at Microsoft.",
  InfoHeader:
    "Please carry a photo ID card issued by government on the day of this trip.",
  KnowLess: "know less",
  KnowMore: "know more",
  LastName: "Last Name",
  LastNamePlaceholder: "Enter last name",
  LicensePlate: "License Plate",
  LicensePlatePlaceholder: "Enter license number",
  LobbyHost: "lobby host.",
  LobbyQRErrorMessage: "QR code does not match meeting details. Please retry.",
  LobbyQRScanningMessage: "Scan the lobby QR code",
  LocatingLobbyQRcode: "Where to find the lobby QR code?",
  LocatingLobbyQRcodeMessage2:
    "For any assistance, please reach out to the lobby host",
  LocatingLobbyQRcodeMessage:
    "The QR code for check-in can be found at the reception lobby.",
  Make: "Make",
  MakePlaceholder: "Enter make",
  meetingDetails: "Meeting Details", //
  MeetingPurposeBusiness: "Business",
  MeetingPurposeEvent: "Event",
  MeetingPurposeInterview: "Interview",
  MeetingPurposePersonal: "Personal",
  MeetingPurposeUserResearch: "User Research",
  MiddleName: "Middle Name (Optional)",
  MiddleNamePlaceholder: "Enter middle name",
  // MilitaryId: ' Military ID',
  Model: "Model",
  ModelPlaceholder: "Enter model",
  Next: "Next",
  No: "No",
  NoPastVisitsErrorMessage: "No past visits",
  NoUpcomingVisitsTitle:
    "Welcome back. You have no upcoming visit planned at Microsoft.",
  NoUpcomingVisitsWelcomeMessage:
    "You can keep your contact information up-to-date for your future visits. Else, you can wait to complete everything when you arrive at our lobby.",
  NoVehicleRequiredInfo:
    "None of the meeting locations needs you to register your vehicle details.",
  NoVisitsTitle: "You have no upcoming visit planned at Microsoft.",
  NoVisitsWelcomeMessage:
    "Once you have an invitation from us, it will show-up here.",
  Okay: "Okay", //
  ParkingInformation: "Parking info",
  // ParmanentResidentCard: ' Permanent resident card',
  // Passport: ' Passport',
  Phone: "Phone Number (Optional)",
  PhonePlaceholder: "Enter phone number",
  Prefix: "Prefix",
  PreRegisterButtonText: "Pre-register",
  PreRegisterQRHeader: "Hey",
  PreRegisterQRSubject: "there!",
  PreviousVisits: "Past Visits",
  PrivacyLink: "https://privacy.microsoft.com/en-ca/privacystatement",
  PrivacyPolicy: "Privacy Statement",
  PrivacyStatement:
    "The data Microsoft collects when guests provide their information for registration, enables us to create a visitor entry badge for the purpose of securing our facilities. Read Microsoft Privacy Statement for additional information around how the privacy of guest's personal data is handled.",
  ProfileUpdateMessage: "We have updated your profile.",
  ProfileUpdateSubMessage: "You are all set!",
  QRCodeInfo: "Use this QR code to check in at the front desk",
  GuestVehicleReg: "Guest vehicle registration not required for this location",
  UpdateVehicle: "Update vehicle",
  NoVehicle: "No vehicle saved",
  AddVehicle: "Add a vehicle",
  RedirectMessage: "Redirecting to homepage in ",
  Save: "Save",
  Searching: "Searching...",
  Seconds: "Sec",
  ShowPhotoID:
    "Please show a government issued photo ID to the lobby host to collect your guest badge",
  ContactLobbyHostToCollectGuestBadge:
    "Please contact the lobby host to collect your guest badge",
  StateLabel: "State",
  TypeLabel: "Vehicle Type",
  StatePlaceholder: "Select state",
  TypePlaceholder: "Select vehicle type",
  CountryPlaceholder: "Select country",
  Submit: "Submit",
  UpcomingVisits: "Upcoming Visits",
  UpdateFailureMessage:
    "We ran into issue while updating information. Please try again later.",
  UpdateRegisterButtonText: "Update Info",
  ValidateInfoSubText:
    "Review/update your personal details prior to check-in. You can also preview your guest badge and make changes based on your preference",
  ValidateYourInfo: "Validate your Information",
  VehicleInfoHeader:
    "At least one of the meeting location needs you to register your vehicle details",
  VehicleInfoMessage: "Vehicle details needed for meetings at",
  VehicleInfoTitle: "Below meeting locations need your vehicle details:",
  WelcomeMessage:
    "You can speed-up the check-in process for your upcoming visits by pre-registering prior to your arrival. Else, you can wait to complete everything when you arrive at our lobby.",
  Yes: "Yes",
  COVIDBannerText:
    "Daily COVID-19 check is mandatory before entering a Microsoft building. ",
  COVIDBannerLinkText: "Take it here",
  PrivacyStatement1:
    "The data Microsoft collects when guests provide their information for registration, enables us to create a visitor entry badge for the purpose of securing our facilities. Read",
  PrivacyStatementLinkText: "Microsoft Privacy Statement",
  PrivacyStatement2:
    "for additional information around how the privacy of guest's personal data is handled.",
  WorldLogoImgPathAltText: "World logo image path",
  InfoImgPathAltText: "Information image path",
  WarningImgPathAltText: "Warning image path",
  Close: "Close",
  ShowAvatar: "Show guest avatar/image",
  MSLogo: "Microsoft Logo",
  EditPen: "Edit pen icon",
  LangSelector: "Language selector",
  Edit: "Edit",
  NoVehicleSaved: "No Vehicle saved",
  Dismiss: "Dismiss",
  BadgeImage: "Badge Image",
  ConfirmVehUpdate: "Confirm vehicle update",
  VehUpdateDescription:
    "This change will be applied to your 0 upcoming meeting/s and will be used as a default for your future visits",
  AmericasLinkText: "Americas",
  AmericasText: "For visits in North & South America",
  GlobalLinkText: "Other global regions",
  GlobalText: "For visits in Europe & Asia-pacific",
  HeyThere: "Hey, there",
  WelcomeText: "Welcome to Microsoft Guest Portal",
  SelectRegion: "Please select a region to start managing your visits",
  individualsTab: "Individuals",
  landmarksTab: "Landmarks",
  closeAvatarModalAriaLabel: "Close popup modal",
  selectAvatar: "Select avatar",
  logOutText: "Log out",
};
export default en;
