/* eslint-disable import/extensions */
import styled from 'styled-components';
import {
  Stack, PrimaryButton, StackItem, Pivot
} from 'office-ui-fabric-react';
import theme from '../../../asset/themes/defaultTheme';
import { size, device } from '../../../config/constants';

const Container = styled(Stack)`
  margin-left: auto;
  margin-right: auto;
  width: auto;
  padding-top: 40px;
  padding-bottom: 30px;
  height: 90vh;
  width: 34.8%;
  @media(max-width: ${size.tabletS}) {
    width: 80%;
  };
  @media(max-width: ${size.mobileL}) {
    width: 100%;
    padding-top: 0px;
  };
`;

const Card = styled(Stack)`
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.brandWhite};
  width: 100%;
  height:100%
  overflow: auto;
  
`;

const BlueButton = styled(PrimaryButton)`
  width: 22.2%;
  font-size: ${theme.fontSizeXLSmall};
  font-weight: ${theme.fontWeightExtraBold};
`;

const Text = styled.span`
  font-weight: ${theme.fontWeightMediumBold};
  font-size: ${theme.fontSizeMedium};


  @media(max-width: ${size.mobileM}) {
    font-size: ${theme.fontSizeXLSmall};
  };
`;

const RTLResponsivePivot = styled(Pivot) <{ isRTL: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isRTL ? 'row-reverse' : 'row')};
  margin-bottom: 10px;
`;

const CardFooter = styled(Stack)`
  border-top: 2px solid ${theme.brandBackgroundGray};
  height: 10%;
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
`;

const CardContent = styled(Stack)`
  height: 80%;
  text-align: left;
  padding-left: 11%;
  padding-right: 11%;
`;

const TextContainer = styled(Stack)`
  padding-top: ${theme.fontSizeMXLarge};
`;

const CheckInText = styled.div`
  font-size: ${theme.fontSizeMLarge};
  font-weight: ${theme.fontWeightExtraBold};
  font-family: ${theme.baseFontFamily};
  line-height: ${theme.fontSizeXLarge};
`;

const CheckInSubText = styled.div`
  padding-top: ${theme.fontSizeXSmall};
  font-size: ${theme.fontSizeMedium};
  line-height: ${theme.fontSizeLarge};
`;

const LoaderStyle = {
  root: {
  },
  circle: {
    marginTop: '40vh'
  }
};

export default {
  Container,
  Card,
  BlueButton,
  Text,
  RTLResponsivePivot,
  LoaderStyle,
  CardFooter,
  CardContent,
  CheckInText,
  CheckInSubText,
  TextContainer
};
