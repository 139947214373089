/* eslint-disable import/extensions */
import axios from 'axios';
import config from '../env/index';
import msalAuthenticator from '../auth/msalAuthenticator';
import { getCurrentRegion } from '../routes/routerConfig';
import { RegionHeader, RequestSourceHeader, Source } from './constants';

const axiosInstance = axios.create({
  baseURL: config.API_BASE_URL,
  timeout: config.API_TIMEOUT,
});

axiosInstance.interceptors.request.use((config) => {
  // fetch region name
  const regionName = getCurrentRegion();
  // Set region header for all requests
  config.headers[RegionHeader] = regionName;
  config.headers.Authorization = `Bearer ${msalAuthenticator.getWebApiAccessToken()}`;
  if (!config.headers[RequestSourceHeader]) {
    config.headers[RequestSourceHeader] = Source;
  }
  return config;
});

const httpService = {

  post(path: any, payload?: any): Promise<any> {
    return axiosInstance.post(path, payload);
  },

  get(path: any, params?: any): Promise<any> {
    return axiosInstance.get(path, params);
  }

};

// Function to create a new axios instance with a specific token passed from email as query param for virtual host scenario

export const httpServiceWithToken = (token: string) => {
  const axiosInstanceWithToken = axios.create({
    baseURL: config.API_BASE_URL,
    timeout: config.API_TIMEOUT,
  });

  axiosInstanceWithToken.interceptors.request.use((config) => {
    // Fetch region name
    const regionName = getCurrentRegion();
    // Set region header for all requests
    config.headers[RegionHeader] = regionName;
    config.headers.Authorization = `Bearer ${token}`;
    if (!config.headers[RequestSourceHeader]) {
      config.headers[RequestSourceHeader] = Source;
    }
    return config;
  });

  return {
    get(path: any): Promise<any> {
      return axiosInstanceWithToken.get(path);
    },
  };
};

export default httpService;
